import React from 'react';

interface MeterPrognoseProps {
  value: number;
}

export const MeterPrognose: React.FC<MeterPrognoseProps> = ({
  value,
}) => {
  const activeStyle = (v: number, c: number) => (v === c ? 1 : 0.5);

  return (
    <svg
      width="160"
      height="124"
      viewBox="0 0 160 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <mask id="path-1-inside-1_411:4912" fill="white">
          <path d="M160 80C160 69.4942 157.931 59.0914 153.91 49.3853C149.89 39.6793 143.997 30.8601 136.569 23.4315C129.14 16.0028 120.321 10.11 110.615 6.08964C100.909 2.06926 90.5057 -4.59221e-07 80 0C69.4942 4.59221e-07 59.0914 2.06926 49.3853 6.08964C39.6793 10.11 30.8601 16.0028 23.4315 23.4315C16.0028 30.8601 10.11 39.6793 6.08963 49.3853C2.06926 59.0914 -9.18442e-07 69.4943 0 80L80 80H160Z" />
        </mask>
        <path
          d="M160 80C160 69.4942 157.931 59.0914 153.91 49.3853C149.89 39.6793 143.997 30.8601 136.569 23.4315C129.14 16.0028 120.321 10.11 110.615 6.08964C100.909 2.06926 90.5057 -4.59221e-07 80 0C69.4942 4.59221e-07 59.0914 2.06926 49.3853 6.08964C39.6793 10.11 30.8601 16.0028 23.4315 23.4315C16.0028 30.8601 10.11 39.6793 6.08963 49.3853C2.06926 59.0914 -9.18442e-07 69.4943 0 80L80 80H160Z"
          fill="#4DBA7A"
          stroke="white"
          strokeWidth="4"
          mask="url(#path-1-inside-1_411:4912)"
          opacity={activeStyle(value, 1)}
        />
      </g>
      <mask id="path-2-inside-2_411:4912" fill="white">
        <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548C88.6539 -1.30516 71.3461 -1.30516 55.2786 3.91548C39.2112 9.13612 25.2089 19.3094 15.2786 32.9772L80 80H160Z" />
      </mask>
      <path
        d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548C88.6539 -1.30516 71.3461 -1.30516 55.2786 3.91548C39.2112 9.13612 25.2089 19.3094 15.2786 32.9772L80 80H160Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-2-inside-2_411:4912)"
      />
      <g>
        <mask id="path-3-inside-3_411:4912" fill="white">
          <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548C88.6539 -1.30516 71.3461 -1.30516 55.2786 3.91548C39.2112 9.13612 25.2089 19.3094 15.2786 32.9772L80 80H160Z" />
        </mask>
        <path
          d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548C88.6539 -1.30516 71.3461 -1.30516 55.2786 3.91548C39.2112 9.13612 25.2089 19.3094 15.2786 32.9772L80 80H160Z"
          fill="#ADC139"
          stroke="white"
          strokeWidth="4"
          mask="url(#path-3-inside-3_411:4912)"
          opacity={activeStyle(value, 2)}
        />
      </g>
      <mask id="path-4-inside-4_411:4912" fill="white">
        <path d="M160 80C160 67.3752 157.012 54.9296 151.281 43.6808C145.549 32.4319 137.237 22.6993 127.023 15.2786C116.809 7.85795 104.984 2.95989 92.5148 0.984932C80.0454 -0.990026 67.2856 0.0141916 55.2786 3.91548L80 80H160Z" />
      </mask>
      <path
        d="M160 80C160 67.3752 157.012 54.9296 151.281 43.6808C145.549 32.4319 137.237 22.6993 127.023 15.2786C116.809 7.85795 104.984 2.95989 92.5148 0.984932C80.0454 -0.990026 67.2856 0.0141916 55.2786 3.91548L80 80H160Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-4-inside-4_411:4912)"
      />
      <g>
        <mask id="path-5-inside-5_411:4912" fill="white">
          <path d="M160 80C160 67.3752 157.012 54.9296 151.281 43.6808C145.549 32.4319 137.237 22.6993 127.023 15.2786C116.809 7.85795 104.984 2.95989 92.5148 0.984932C80.0454 -0.990026 67.2856 0.0141916 55.2786 3.91548L80 80H160Z" />
        </mask>
        <path
          d="M160 80C160 67.3752 157.012 54.9296 151.281 43.6808C145.549 32.4319 137.237 22.6993 127.023 15.2786C116.809 7.85795 104.984 2.95989 92.5148 0.984932C80.0454 -0.990026 67.2856 0.0141916 55.2786 3.91548L80 80H160Z"
          fill="#FFC600"
          stroke="white"
          strokeWidth="4"
          mask="url(#path-5-inside-5_411:4912)"
          opacity={activeStyle(value, 3)}
        />
      </g>
      <mask id="path-6-inside-6_411:4912" fill="white">
        <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548L80 80H160Z" />
      </mask>
      <path
        d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548L80 80H160Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-6-inside-6_411:4912)"
      />
      <g>
        <mask id="path-7-inside-7_411:4912" fill="white">
          <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548L80 80H160Z" />
        </mask>
        <path
          d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772C134.791 19.3094 120.789 9.13612 104.721 3.91548L80 80H160Z"
          fill="#FF8D26"
          stroke="white"
          strokeWidth="4"
          mask="url(#path-7-inside-7_411:4912)"
          opacity={activeStyle(value, 4)}
        />
      </g>
      <mask id="path-8-inside-8_411:4912" fill="white">
        <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772L80 80H160Z" />
      </mask>
      <path
        d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772L80 80H160Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-8-inside-8_411:4912)"
      />
      <mask id="path-9-inside-9_411:4912" fill="white">
        <path d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772L80 80H160Z" />
      </mask>
      <path
        d="M160 80C160 63.1057 154.652 46.645 144.721 32.9772L80 80H160Z"
        fill="#FF0000"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-9-inside-9_411:4912)"
        opacity={activeStyle(value, 5)}
      />
      <path
        d="M120 80C120 80 102.091 80 80 80C57.9086 80 40 80 40 80C40 57.9086 57.9086 40 80 40C102.091 40 120 57.9086 120 80Z"
        fill="white"
      />
      {value > 0 && (
        <path
          d="M81.854 83.7062C78.7024 84.7302 75.3175 83.0055 74.2935 79.854C73.2695 76.7025 74.9942 73.3175 78.1458 72.2936C81.2973 71.2696 114.238 66.8753 114.238 66.8753C114.238 66.8753 85.0055 82.6822 81.854 83.7062Z"
          fill="black"
          transform={`rotate(${
            -36 * (5 - (value <= 0 ? 1 : value))
          } 79.9999 78.0001)`}
        />
      )}
      {/* arrow dot */}
      <circle
        cx="79.9999"
        cy="78.0001"
        r="1"
        transform="rotate(32.5 79.9999 78.0001)"
        fill="white"
      />
      {/* 1 */}
      <path
        d="M24.126 68H21.999V57.7256L18.8613 58.7979V56.9961L23.8535 55.1592H24.126V68Z"
        fill="black"
        opacity={activeStyle(value, 1)}
      />
      {/* 2 */}
      <path
        d="M49.9473 38H41.3428V36.5322L45.6055 31.8828C46.2207 31.1973 46.6572 30.626 46.915 30.1689C47.1787 29.7061 47.3105 29.2432 47.3105 28.7803C47.3105 28.1709 47.1377 27.6787 46.792 27.3037C46.4521 26.9287 45.9922 26.7412 45.4121 26.7412C44.7207 26.7412 44.1846 26.9521 43.8037 27.374C43.4229 27.7959 43.2324 28.373 43.2324 29.1055H41.0967C41.0967 28.3262 41.2725 27.626 41.624 27.0049C41.9814 26.3779 42.4883 25.8916 43.1445 25.5459C43.8066 25.2002 44.5684 25.0273 45.4297 25.0273C46.6719 25.0273 47.6504 25.3408 48.3652 25.9678C49.0859 26.5889 49.4463 27.4502 49.4463 28.5518C49.4463 29.1904 49.2646 29.8613 48.9014 30.5645C48.5439 31.2617 47.9551 32.0557 47.1348 32.9463L44.0059 36.2949H49.9473V38Z"
        fill="black"
        opacity={activeStyle(value, 2)}
      />
      {/* 3 */}
      <path
        d="M78.8125 20.626H80.1133C80.7695 20.6201 81.2881 20.4502 81.6689 20.1162C82.0557 19.7822 82.249 19.2988 82.249 18.666C82.249 18.0566 82.0879 17.585 81.7656 17.251C81.4492 16.9111 80.9629 16.7412 80.3066 16.7412C79.7324 16.7412 79.2607 16.9082 78.8916 17.2422C78.5225 17.5703 78.3379 18.001 78.3379 18.5342H76.2021C76.2021 17.8779 76.375 17.2803 76.7207 16.7412C77.0723 16.2021 77.5586 15.7832 78.1797 15.4844C78.8066 15.1797 79.5068 15.0273 80.2803 15.0273C81.5576 15.0273 82.5596 15.3496 83.2861 15.9941C84.0186 16.6328 84.3848 17.5234 84.3848 18.666C84.3848 19.2402 84.2002 19.7822 83.8311 20.292C83.4678 20.7959 82.9961 21.1768 82.416 21.4346C83.1191 21.6748 83.6523 22.0527 84.0156 22.5684C84.3848 23.084 84.5693 23.6992 84.5693 24.4141C84.5693 25.5625 84.1738 26.4766 83.3828 27.1562C82.5977 27.8359 81.5635 28.1758 80.2803 28.1758C79.0498 28.1758 78.042 27.8477 77.2568 27.1914C76.4717 26.5352 76.0791 25.6621 76.0791 24.5723H78.2148C78.2148 25.1348 78.4023 25.5918 78.7773 25.9434C79.1582 26.2949 79.668 26.4707 80.3066 26.4707C80.9688 26.4707 81.4902 26.2949 81.8711 25.9434C82.252 25.5918 82.4424 25.082 82.4424 24.4141C82.4424 23.7402 82.2432 23.2217 81.8447 22.8584C81.4463 22.4951 80.8545 22.3135 80.0693 22.3135H78.8125V20.626Z"
        fill="black"
        opacity={activeStyle(value, 3)}
      />
      {/* 4 */}
      <path
        d="M118.567 33.4385H120.158V35.1523H118.567V38H116.432V35.1523H110.903L110.842 33.8516L116.361 25.2031H118.567V33.4385ZM113.083 33.4385H116.432V28.0947L116.273 28.376L113.083 33.4385Z"
        fill="black"
        opacity={activeStyle(value, 4)}
      />
      {/* 5 */}
      <path
        d="M133.914 61.6543L134.608 55.2031H141.481V57.0488H136.384L136.032 60.1074C136.624 59.7676 137.292 59.5977 138.036 59.5977C139.255 59.5977 140.201 59.9873 140.875 60.7666C141.555 61.5459 141.895 62.5947 141.895 63.9131C141.895 65.2139 141.52 66.251 140.77 67.0244C140.02 67.792 138.991 68.1758 137.685 68.1758C136.513 68.1758 135.54 67.8447 134.767 67.1826C133.999 66.5146 133.583 65.6357 133.519 64.5459H135.584C135.666 65.167 135.889 65.6445 136.252 65.9785C136.615 66.3066 137.09 66.4707 137.676 66.4707C138.332 66.4707 138.842 66.2363 139.205 65.7676C139.574 65.2988 139.759 64.6602 139.759 63.8516C139.759 63.0723 139.557 62.457 139.152 62.0059C138.748 61.5488 138.191 61.3203 137.482 61.3203C137.096 61.3203 136.768 61.373 136.498 61.4785C136.229 61.5781 135.936 61.7773 135.619 62.0762L133.914 61.6543Z"
        fill="black"
        opacity={activeStyle(value, 5)}
      />
    </svg>
  );
};

interface MeterProps {
  value: number;
}

export const Meter: React.FC<MeterProps> = ({ value }) => {
  return (
    <div className="flex justify-center h-20">
      <MeterPrognose value={value} />
    </div>
  );
};
