export const eq5dDeprText = {
  label: {
    en: 'Anxiety and depression',
    no: 'Engstelig eller deprimert',
  },
  hover: {
    en: '',
    no: 'EQ5D Angst/ depresjon. Fem-delt skala fra ikke til ekstremt engstelig eller deprimert. Pasientsvar: "actual score"',
  },
  values: [
    {
      value: 'none',
      text: 'Jeg er verken engstelig eller deprimert',
      score: 1,
    },
    {
      value: 'sligh',
      text: 'Jeg er litt engstelig eller deprimert',
      score: 2,
    },
    {
      value: 'mod',
      text: 'Jeg er middels engstelig eller deprimert',
      score: 3,
    },
    {
      value: 'sever',
      text: 'Jeg er svært engstelig eller deprimert',
      score: 4,
    },
    {
      value: 'extre',
      text: 'Jeg er ekstremt engstelig eller deprimert',
      score: 5,
    },
  ],
};
