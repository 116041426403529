export const expectationConsultationText = {
  values: [
    {
      value: 'sleav',
      text: 'Få sykmelding',
      score: 0,
    },
    {
      value: 'medic',
      text: 'Få resept på smertestillende medisiner',
      score: 0,
    },
    {
      value: 'advic',
      text: 'Få råd og veiledning',
      score: 0,
    },
    {
      value: 'imag',
      text: 'Få henvisning til MR/røntgen',
      score: 0,
    },
    {
      value: 'noexp',
      text: 'Har ingen forventninger',
      score: 0,
    },
    { value: 'other', text: 'Andre forventninger', score: 0 },
  ],
};
