export const workAbilityText = {
  label: {
    en: 'Work ability',
    no: 'Arbeidsevne',
  },
  hover: {
    en: '',
    no: 'Beskriv din nåværende arbeidsevne sammenlignet med når den var som best i ditt liv. 0 = Best arbeidsevne, 10 = Ingen arbeidsevne. Pasientsvar: "actual score".',
  },
};
