import clsx from 'clsx';
import React from 'react';

interface TreatmentFactorFieldProps {
  title: string;
  disabled?: boolean;
}

export const TreatmentFactorField: React.FC<
  TreatmentFactorFieldProps
> = ({ title, disabled }) => {
  return (
    <div className="flex items-center mb-1">
      <div className="mr-2">
        {!disabled ? (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                strokeLinejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        ) : (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 opacity-50"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                strokeLinejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        )}
      </div>
      <span
        className={clsx({ 'text-gray-500 opacity-50': disabled })}
      >
        {title}
      </span>
    </div>
  );
};
