import { Card } from 'components/Card';
import { CopyText } from 'components/CopyText';
import React from 'react';

interface JournalProps {}

const text = `
Bakgrunn:
Røyker/Røyker ikke. Pasienten har en BMI på 29. Pasientens utdanningsnivå er: Inntil 4 år høyere utdanning. Pasienten opplever sjelden vansker med å forstå helseinformasjon.
Tidligere sykdommer: hjertesykdom, astma, diabetes.

Gender tilhører fenotype 3 som kjennetegnes av moderat symptomtrykk og middels risiko for nedsatt funksjon og redusert arbeidsevne.  Fastlege kan med fordel vurdere henvisning til fysioterapi og tettere oppfølging, med vekt på aktiv tilnærming og egenmestring.

Problem:
Nakkesmerter.

Subjektivt
Smertene oppstod gradvis over tid , varighet 6 - 8 uker. Smerteintensitet den siste uken NRS 7. Gender har ikke å ha benyttet smertestillende medikamenter / har benyttet: Betennelsesdempende medisiner (Ibuprofen, Ibux, Ibumetin, nurofen, naproksen, proxan, voltarol) x antall ganger daglig den siste uken.

Pasienten har ikke mottatt behandling fra andre behandlere for nåværende plager i løpet av de 12 siste månedene. / Pasienten har mottatt behandling fra navn på behandlere for nåværende plager i løpet av de 12 siste månedene.

Forventninger til dagens time: Jeg forventer å bli sykmeldt

Gender har «moderate søvnproblemer, f.eks. forstyrret søvn eller føler jeg ikke har sovet nok». Gender føler seg middels sliten, trett eller svak.

Det generelle aktivitetsnivå er ganske redusert på grunn av det aktuelle. Pasienten er litt forhindret i å være sosialt aktiv og å holde på med hobbyer de siste to ukene grunnet sine ledd-/muskelsymptomer. Gender har ikke vært fysisk aktiv i 30 min eller mer i 3 dager den siste uken/ Pasienten har vært fysisk aktiv i 30 min eller mer i 3 dager den siste uken.

Pasienten svarer 6 (0 til 10 - 0= ikke enig og 10= Helt enig) på spørsmål om Gender bør utføre sine normale aktiviteter eller arbeid med den aktuelle smerten. Videre angir pasienten smertemestring til 8 (0 til 12 - 0=god smertemestring 12=lav smertemestring). Pasienten angir risiko for at plagene kan bli langvarige til 7 (0 til 10, 0=ingen risiko 10=svært stor risiko).

Skår psykisk belastning: Grad av angst og depresjon målt ved Hopkins Symptom Check-list 1,95 (1.0 - 4.0, over 1,85 er tegn på symptomer på psykisk stress). Pasienten angir å være middels engstelig eller deprimert (EQ5D-skala).

Psykososiale risikofaktorer for arbeidsnedsettelse eller uførhet på grunn av smerter målt med Örebro screeningskjema - pasientscore: 50 (0 = Lav risiko, 100= Høy risiko, økt risiko over 50 poeng)

Gender har vært sykmeldt/ikke sykmeldt for samme plager tidligere. Pasienten angir at nåværende arbeidsevne er redusert til 7 (0 = Best arbeidsevne, 10 = Ingen arbeidsevne) sammenlignet med når arbeidsevnen er på det beste. Pasienten angir sjanse for å være i arbeid om tre måneder til 6 (0= ingen sjanse, 10 = svært stor sjanse).
Gender opplever ingen konflikt på arbeidsplassen / Pasienten opplever en konflikt på arbeidsplassen.

Røde flagg: Nattesvette, tidligere kreftsykdom Ingen røde flagg beskrevet.

Objektivt:
Nakkeundersøkelse: Pasienten har/har ikke reduserte og/eller smertefulle aktive bevegelsesutslag i vektbærende stilling - reduserte ekstensjon - smerter ved ekstensjon. Spurling test er negativ. Ingen nevrologiske funn/Redusert kraft og unormale reflekser. Pasienten viser tegn på nerverotsaffeksjon. Fritekst.
Skulderundersøkelse: Pasienten har/har ikke reduserte og/eller smertefulle aktive bevegelsesutslag. Reduserte aktiv abduksjon. Smerter ved aktiv abduksjon og utadrotasjon. Redusert passiv utadrotasjon. Pasienten har/har ikke smertebue. Hawkins test gir/gir ikke smerter. Smerter ved isometrisk abduksjon, utadrotasjon. Redusert kraft ved isometrisk utadrotasjon, abduksjon. Fritekst.

Ryggundersøkelse: Pasienten har/har ikke reduserte og/eller smertefulle aktive bevegelsesutslag i stående stilling. Redusert aktiv fleksjon. Smerter ved aktiv fleksjon og rotasjon. Lasegue/straight leg raise er positiv/negativ. Ingen nevrologiske funn/Redusert kraft, sensibilitet og reflekser. Fritekst.

Hofteundersøkelse: Pasienten har/har ikke reduserte og/eller smertefulle aktive bevegelsesutslag. Redusert bevegelsesutslag for aktiv fleksjon. Smerter ved aktiv ekstensjon. Pasienten har/har ikke reduserte og/eller smerter ved passive bevegelsesutslag. Redusert passiv fleksjon, ekstensjon og adduksjon. Smerter ved passiv fleksjon, ekstensjon og abduksjon. Pasienten angir at smertene sitter lateralt. Fritekst.

Kneundersøkelse: Pasienten har/har ikke reduserte og/eller smertefulle aktive bevegelsesutslag. Reduserte aktiv ekstensjon og fleksjon. Smerter ved aktiv ekstensjon. Pasienten har/har ikke reduserte og/eller smertefulle passive bevegelsesutslag.  Redusert passiv ekstensjon. Smerter ved passiv ekstensjon. Hydrops/Ikke hydrops. Positiv/negativ pivot shift test. Positiv/negativ lachman test. Positiv/negativ sag test. Gjenkjennbare smerter/ikke gjenkjennbare smerter smerter ved McMurrays test. Ikke Gjenkjennbar/Gjenkjennbar smerte ved palpasjon i leddspalten. Ikke forøket/Forøket valgus stress på ekstendert kne. Ikke forøket/Forøket valgus ved stress test på 20 graderst flektert kne. Ikke forøket/forøket varus ved stress test på ekstendert kne. Fritekst.
Fritekst.

Analyse og behandlingsplan:
Pasienten får diagnose ICPC2. Pasienten har fått råd og veiledning om psykososiale faktorer, generelt aktivitetsnivå og fysisk aktivitet, røykestopp og forventning om langvarige plager. Pasienten har fått råd om å bruke følgende medikamenter: Paracet/NSAID; NSAID gel. Pasienten ble henvist til Allmennfysioterapeut, Røntgen. Pasienten har blitt sykmeldt.  Det er utarbeidet en plan til tilbakeføring til arbeid
`;

export const Journal: React.FC<JournalProps> = () => {
  return (
    <Card>
      <CopyText content={text}>
        <button className="inline-flex  items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center">
          Copy
        </button>
      </CopyText>
      <div className="whitespace-pre-wrap">{text}</div>
    </Card>
  );
};
