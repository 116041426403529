import React from 'react';
import { SidebarItem, SidebarItemType } from './SidebarItem';
import { Logo } from 'components/Logo';
import { PatientInfo } from 'components/PatientInfo';
import { useHistory, useLocation } from 'react-router-dom';
import { PatientInfoResponse } from 'interfaces';

interface SidebarProps {
  data: PatientInfoResponse;
}

export const Sidebar: React.FC<SidebarProps> = ({ data }) => {
  const { patientDetails } = data;
  const { Icpc_Number, Icpc_Letter, BodyMain, clinicianGroup } =
    patientDetails;
  const history = useHistory();
  const location = useLocation();

  if (location.pathname === '/app/eksport') return null;
  if (!data) return null;

  // disabled patient matching and we assume it has 3 similar patients
  const baseline =
    [Icpc_Number, Icpc_Letter, BodyMain].filter((v) => v).length < 3;

  const isInterventionGroup = clinicianGroup === 'intervention';

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    history.push('/');
  }

  return (
    <div className="bg-white max-w-sidebar w-full flex flex-col">
      <div className="mt-2">
        <Logo />
        <PatientInfo patient={data} />
      </div>
      <div className="my-auto">
        {isInterventionGroup && (
          <SidebarItem
            to="/app"
            title="Pasientprofil"
            type={SidebarItemType.dashboard}
          />
        )}
        <SidebarItem
          to="/app/baseline-common"
          title="Undersøkelse"
          type={SidebarItemType.checklist}
        />
        {isInterventionGroup && (
          <SidebarItem
            to="/app/treatment"
            title="Behandling"
            type={SidebarItemType.chart}
            disabled={baseline}
            disableText="Registrer hovedproblem og ICPC kode i fanen Undersøkelse"
          />
        )}
        {/* <SidebarItem
          to="/app/comparison"
          title="Pasientmatching"
          type={SidebarItemType.chart}
          disabled={baseline}
          disableText="Pasientspesifikk funkssjonsskala og diagnose må fylles ut og lagres før du kan utføre matching"
        /> */}
        {/* <SidebarItem
          to="/app/eksport"
          title="Eksport"
          target="_blank"
          type={SidebarItemType.document}
          disableText="Pasientmatching - velg pasientmatching"
        /> */}
        {/* <SidebarItem
          to="/app/summary"
          title="Eksport"
          type={SidebarItemType.document}
          disableText="Pasientmatching - velg pasientmatching"
        /> */}
      </div>
      <div className="mt-auto">
        <SidebarItem
          to="/app/change-patient"
          title="Velg pasient"
          type={SidebarItemType.user}
        />
        <SidebarItem
          onClick={logout}
          to="/"
          title="Logg av"
          type={SidebarItemType.logout}
        />
      </div>
    </div>
  );
};
