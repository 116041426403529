export const neckNevroText = {
  values: [
    { value: 'NeckNevro_nota', text: 'Ikke aktuelt', score: 0 },
    { value: 'NeckNevro_pow', text: 'Redusert kraft', score: 0 },
    {
      value: 'NeckNevro_sens',
      text: 'Redusert sensibilitet',
      score: 0,
    },
    {
      value: 'NeckNevro_reflex',
      text: 'Unormale reflekser',
      score: 0,
    },
  ],
};
