import { HoverContent } from 'components/HoverContent';
import React from 'react';
import { TreatmentCardTitle } from './TreatmentCardTitle';

interface TreatmentLegendProps {}

export const TreatmentLegend: React.FC<TreatmentLegendProps> = () => {
  return (
    <div className="flex ml-auto text-sm space-x-4">
      <div className="space-y-1">
        <div className="flex items-center">
          <TreatmentCardTitle color="green" />
          <div>Anbefales</div>
        </div>
        <div className="flex items-center">
          <TreatmentCardTitle color="yellow" />
          <div>Kan vurderes</div>
        </div>
      </div>
      <div className="space-y-1">
        <HoverContent content="Kliniske funn, psientkarakteristikk, historikk etc.">
          <div className="flex items-center">
            <TreatmentCardTitle color="red" />
            <div>Vurder kun hvis spesifikk indikasjon</div>
            {/* <div className="italic">
              Kliniske funn, psientkarakteristikk, historikk etc.
            </div> */}
          </div>
        </HoverContent>
        <div className="flex items-center">
          <TreatmentCardTitle color="gray" />
          <div>Ikke grunnlag for anbefaling</div>
        </div>
      </div>
    </div>
  );
};
