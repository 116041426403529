import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
  CardAnswers,
} from 'components/Card';
import { GROUP } from 'data';
import { replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import { getMappedValue } from 'helpers';
import { qol15dSleepText } from 'helpers/qol15dSleep';
import { qol15dVitalText } from 'helpers/qol15dVital';
import { eq5dDeprText } from 'helpers/eq5dDepr';
import { hscleScoreText } from 'helpers/hsclScore';
import { comorbidityText } from 'helpers/comorbidity';
import { comorbidityotherspText } from 'helpers/comorbidityothersp';
import { healthLitInfoText } from 'helpers/healthLitInfo';

interface GeneralHealthProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const GeneralHealth: React.FC<GeneralHealthProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    Qol15dSleep,
    Qol15dVital,
    EQ5DDepr,
    HSCLScore,
    Comorbidity_asthm,
    Comorbidity_cance,
    Comorbidity_demen,
    Comorbidity_diab,
    Comorbidity_fatig,
    Comorbidity_heada,
    Comorbidity_heart,
    Comorbidity_neuro,
    Comorbidity_not,
    Comorbidity_oa,
    Comorbidity_osteop,
    Comorbidity_other,
    Comorbidity_psych,
    Comorbidity_ra,
    Comorbidity_stopa,
    Comorbidityothersp,
    HealthLitInfo,
  } = patientDetails;

  const comorbidities = {
    Comorbidity_asthm,
    Comorbidity_cance,
    Comorbidity_demen,
    Comorbidity_diab,
    Comorbidity_fatig,
    Comorbidity_heada,
    Comorbidity_heart,
    Comorbidity_neuro,
    Comorbidity_not,
    Comorbidity_oa,
    Comorbidity_osteop,
    Comorbidity_other,
    Comorbidity_psych,
    Comorbidity_ra,
    Comorbidity_stopa,
    Comorbidityothersp,
  };

  const mapped_qol15d_q5_sleep_1 = getMappedValue(
    qol15dSleepText.values,
    Qol15dSleep,
  );
  const mapped_qol15d_q14_vital_1 = getMappedValue(
    qol15dVitalText.values,
    Qol15dVital,
  );
  const mapped_eq5d_depr_1 = getMappedValue(
    eq5dDeprText.values,
    EQ5DDepr,
  );

  const mapped_healthLitInfo = getMappedValue(
    healthLitInfoText.values,
    HealthLitInfo,
  );

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text={GROUP.general.title.no}
          size={CardLabelType.xl}
        />

        <HoverContent
          content={replaceScore(
            qol15dSleepText.hover.no,
            mapped_qol15d_q5_sleep_1.text,
          )}
        >
          <CopyText
            content={replaceScore(
              qol15dSleepText.hover.no,
              mapped_qol15d_q5_sleep_1.text,
            )}
          >
            <CardProgressBar
              text={qol15dSleepText.label.no}
              progress={mapped_qol15d_q5_sleep_1.score}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(
                mapped_qol15d_q5_sleep_1.value,
              )}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            qol15dVitalText.hover.no,
            mapped_qol15d_q14_vital_1.text,
          )}
        >
          <CopyText
            content={replaceScore(
              qol15dVitalText.hover.no,
              mapped_qol15d_q14_vital_1.text,
            )}
          >
            <CardProgressBar
              text={qol15dVitalText.label.no}
              progress={mapped_qol15d_q14_vital_1.score}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(
                mapped_qol15d_q14_vital_1.value,
              )}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={`${replaceScore(
            hscleScoreText.hover.no,
            HSCLScore,
          )}${'<div class="mt-4">Spørreskjemaet har svaralternativene <br/>"Ikke i det hele tatt" <br/>"Litt"<br/> "En god del"<br/> "Svært mye" <br/>for følgende spørsmål: <br/><br/> 1. Plutselig skremt uten grunn.<br/> 2. Føler du deg svimmel eller kraftesløs.<br/> 3. Føler deg anspent eller opphisset.<br/> 4. Anfall av redsel eller panikk.<br/> 5. Anklager deg selv for ting.<br/> 6. Vanskelig for å sove.<br/> 7. Følelse av håpløshet mhp. framtiden.<br/> 8. Føler deg nedfor.<br/> 9. Føler at alt krever stor anstrengelse.<br/> 10. Føler at du ikke er noe verd.</div>'}`}
        >
          <CopyText
            content={replaceScore(hscleScoreText.hover.no, HSCLScore)}
          >
            <CardProgressBar
              text={hscleScoreText.label.no}
              progress={Number(HSCLScore)}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              max={4}
              indicators={[1.7, 2]}
              disabled={variableChecker(HSCLScore)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            eq5dDeprText.hover.no,
            mapped_eq5d_depr_1.text,
          )}
        >
          <CopyText
            content={replaceScore(
              eq5dDeprText.hover.no,
              mapped_eq5d_depr_1.text,
            )}
          >
            <CardProgressBar
              text={eq5dDeprText.label.no}
              progress={Number(mapped_eq5d_depr_1.score)}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(mapped_eq5d_depr_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            healthLitInfoText.hover.no,
            mapped_healthLitInfo.text,
          )}
        >
          <CopyText
            content={replaceScore(
              healthLitInfoText.hover.no,
              mapped_healthLitInfo.text,
            )}
          >
            <CardProgressBar
              text="Helseforståelse"
              progress={mapped_healthLitInfo.score}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(mapped_healthLitInfo)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={comorbidityText.hover.no}
          className="inline-block"
        >
          <CopyText
            content={`${comorbidityText.hover.no}: ${Object.entries(
              comorbidities,
            )
              .map(([key, value]) => {
                return (
                  (value &&
                    comorbidityText.values.find(
                      (v) => v.value === key.split('_')[1],
                    )?.text) ||
                  key ===
                    comorbidityotherspText.values.find(
                      (v) => v.value === value,
                    )?.text
                );
              })
              .filter((p) => p)}`}
          >
            <CardAnswers text={comorbidityText.label.no}>
              {Object.entries(comorbidities).map(([key, value]) => (
                <div className="mb-1" key={key}>
                  {(value &&
                    comorbidityText.values.find(
                      (v) => v.value === key.split('_')[1],
                    )?.text) ||
                    (key === 'Comorbidityothersp' &&
                      comorbidityotherspText.values.find(
                        (v) => v.value === value,
                      )?.text)}
                </div>
              ))}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
