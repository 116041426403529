export const kneeMoveText = {
  label: {
    en: '',
    no: 'Reduserte og/eller smertefulle aktive bevegelsesutslag',
  },
  values: [
    { value: 'KneeMove', text: 'Ja', score: 1 },
    { value: 'KneeMove', text: 'Nei', score: 0 },
  ],
};
