import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  RouteProps as _RouteProps,
} from 'react-router-dom';

import { Overview, OverviewExport } from 'components/Overview';
import { Treatment } from 'components/Treatment';
import { Baseline } from 'components/Baseline';
import { Comparison } from 'components/Comparison';
import { Summary } from 'components/Summary';
import { Login } from 'components/Login';
import { Patients } from 'components/Patients';
import { ChangePatient } from 'components/ChangePatient';
import { Journal } from 'components/Journal';
import {
  TestingRemoveSimilarPatient,
  TestingSaveSimilarPatient,
} from 'components/Testing';

const username = localStorage.getItem('username');
export interface RouteProps
  extends Omit<_RouteProps, 'component' | 'path'> {
  key?: string;
  path?: string;
  name?: string;
  component?: React.ElementType;
  routes?: RouteProps[];
}

// Allow these users to access the test routes
const testRoutes = ['ui', 'support200', 'support300'].includes(
  username || '',
)
  ? [
      {
        name: 'Save Similar Patient',
        path: '/app/test/baseline-similar-patients-save',
        key: 'APP_TEST_SAVE_SIMILAR_PATIENTS',
        exact: true,
        component: TestingSaveSimilarPatient,
      },
      {
        name: 'Remove Similar Patient',
        path: '/app/test/baseline-similar-patients-remove',
        key: 'APP_TEST_REMOVE_SIMILAR_PATIENTS',
        exact: true,
        component: TestingRemoveSimilarPatient,
      },
    ]
  : [];

const ROUTES = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: Login,
  },
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    component: Login,
  },
  {
    path: '/patients',
    key: 'PATIENTS',
    exact: true,
    component: Patients,
  },
  {
    path: '/app',
    key: 'APP',
    component: (props: RouteProps) => {
      if (!localStorage.getItem('token')) {
        return <Redirect to={'/'} />;
      }
      return <RenderRoutes {...props} />;
    },
    routes: [
      {
        name: 'Overview',
        path: '/app',
        key: 'APP_OVERVIEW',
        exact: true,
        component: Overview,
      },
      {
        name: 'Treatment',
        path: '/app/treatment',
        key: 'APP_TREATMENT',
        exact: true,
        component: Treatment,
      },
      {
        name: 'Baseline Common',
        path: '/app/baseline-common',
        key: 'APP_BASELINE_COMMON',
        exact: true,
        component: Baseline,
      },
      {
        name: 'Comparison',
        path: '/app/comparison',
        key: 'APP_COMPARISON',
        exact: true,
        component: Comparison,
      },
      {
        name: 'Summary',
        path: '/app/summary',
        key: 'APP_SUMMARY',
        exact: true,
        component: Summary,
      },
      {
        name: 'Change Patient',
        path: '/app/change-patient',
        key: 'CHANGE_PATIENT',
        exact: true,
        component: ChangePatient,
      },
      {
        name: 'Overview eksport',
        path: '/app/eksport',
        key: 'APP_OVERVIEW_EXPORT',
        exact: true,
        component: OverviewExport,
      },
      {
        name: 'Journal',
        path: '/app/Journal',
        key: 'JOURNAL',
        exact: true,
        component: Journal,
      },
      // Testing
      ...testRoutes,
    ],
  },
];

export default ROUTES;

interface RenderRoutesProps extends RouteProps {
  data?: any;
}

function RouteWithSubRoutes(route: RenderRoutesProps) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>
        route.component && (
          <route.component
            {...props}
            routes={route.routes}
            data={route.data}
          />
        )
      }
    />
  );
}

export function RenderRoutes({ routes, data }: RenderRoutesProps) {
  if (!routes) return null;
  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} data={data} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}
