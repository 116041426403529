import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { FormInput } from 'components/Form';

interface WidespreadProps {}

export const Widespread: React.FC<WidespreadProps> = () => {
  return (
    <Card>
      <div className="mb-6">
        <CardLabel
          text="Klinisk undersøkelse fritekst:"
          size={CardLabelType.lg}
        />
      </div>
      <div className="mb-6">
        <FormInput
          id="WidespreadComment"
          name="WidespreadComment"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
