export const neckMoveRomText = {
  values: [
    { value: 'NeckMoveRom_flex', text: 'Fleksjon', score: 0 },
    { value: 'NeckMoveRom_ext', text: 'Ekstensjon', score: 0 },
    { value: 'NeckMoveRom_rot', text: 'Rotasjon', score: 0 },
    { value: 'NeckMoveRom_lat', text: 'Lateralfleksjon', score: 0 },
    {
      value: 'NeckMoveRom_not',
      text: 'Ikke reduserte bevegelsesutslag',
      score: 0,
    },
  ],
};
