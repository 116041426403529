import React from 'react';

import { BoxField } from 'components/BoxField';
import { Baseline, TextValueType } from 'interfaces';

interface BoxFieldsProps {
  variables: any;
  keys: TextValueType[];
  square?: boolean;
  values: Baseline;
}

export const BoxFields: React.FC<BoxFieldsProps> = ({
  variables,
  keys,
  square,
  values,
}) => {
  const MC = () => {
    return (
      <>
        {keys.map((k, i) => {
          const currentValue = Object.entries(values).find(
            ([vk]) => vk === k.value,
          )?.[1];
          const value = 'Y';
          const checked = currentValue?.[0] === value;
          return (
            <BoxField
              key={`${k.value}+${i}`}
              type="checkbox"
              name={k.value}
              value={value}
              checked={checked}
              text={k.text}
              square={square}
            />
          );
        })}
      </>
    );
  };

  const R = () => {
    return (
      <>
        {keys.map((k, i) => {
          const isYesNo = ['JaNeiIkke aktuelt', 'JaNei'].includes(
            keys.map((v) => v.text).join(''),
          );
          let currentValue = Object.entries(values).find(
            ([vk]) => vk === Object.keys(variables)[0],
          );
          let value = k.value;
          let name = Object.keys(variables)[0];
          let checked = currentValue?.[1] === value;

          // Y/N question
          if (isYesNo) {
            value =
              [
                { value: 'Y', text: 'Ja' },
                { value: 'N', text: 'Nei' },
                { value: 'nota', text: 'Ikke aktuelt' },
              ].find((v) => v.text === k?.text)?.value || '';
            name = k.value;
            currentValue = Object.entries(values).find(
              ([vk]) => vk === k.value,
            );
            checked = currentValue?.[1] === value;
          }

          return (
            <BoxField
              key={`${k.value}+${i}`}
              type="radio"
              name={name}
              value={value}
              checked={checked}
              text={k.text}
              square={square}
            />
          );
        })}
      </>
    );
  };
  return square ? <MC /> : <R />;
};
