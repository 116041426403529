import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useHistory } from 'react-router';

import { Logo } from 'components/Logo';

import { FormInput, FormButton, FormError } from 'components/Form';
import { LoginSchema } from './LoginSchema';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useRouter } from 'hooks';
const URL = process.env.REACT_APP_URL;

interface LoginProps {
  username: string;
  password: string;
}

export const Login: React.FC<LoginProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Login`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      history.replace('/app');
    }
  }, []);

  const handleSubmit = async (
    values: LoginProps,
    actions: FormikHelpers<LoginProps>,
  ) => {
    try {
      setLoading(true);
      const { setSubmitting } = actions;
      const { username, password } = values;
      let token = null;

      const body: any = {
        client_id: 'cbr-interface',
        username,
        password,
        grant_type: 'password',
        client_secret: '84945848-e6de-44f5-867b-ca6858449d88',
      };

      const formBody = Object.keys(body)
        .map(
          (key) =>
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(body[key]),
        )
        .join('&');

      let LOGIN_URL = `${URL}/auth/realms/supportprim/protocol/openid-connect/token`;
      if (process.env.NODE_ENV === 'development') {
        LOGIN_URL = process.env.REACT_APP_LOGIN || '';
      }

      const response = await fetch(LOGIN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });
      const data = await response.json();
      token = data.access_token;

      await setLoading(false);
      await setSubmitting(false);

      if (token) {
        localStorage.setItem('token', `Bearer ${token}`);
        localStorage.setItem('username', `${username}`);
        history.replace('/patients');
      }

      if (data.error) {
        setError(data.error_description);
      }
    } catch (err: any) {
      setLoading(false);
      setError(err);
      console.log('SignInError:', err.message);
    }
  };

  // if (localStorage.getItem('token')) {
  //   return <Redirect to={'/patients'} />;
  // }

  return (
    <div className="flex items-center h-screen">
      {/* <div className="w-2/5"> */}
      <div className="space-y-8 p-4 w-full max-w-lg mx-auto">
        <Logo />
        <div className="">
          <Formik
            initialValues={
              process.env.NODE_ENV === 'development'
                ? {
                    username: 'ui',
                    password: 'beinmarg41',
                  }
                : { username: '', password: '' }
            }
            onSubmit={(
              values: LoginProps,
              actions: FormikHelpers<LoginProps>,
            ) => {
              handleSubmit(values, actions);
            }}
            validationSchema={LoginSchema}
          >
            <Form className="space-y-6">
              <div>
                <FormInput
                  id="username"
                  name="username"
                  placeholder="Username"
                  type="text"
                />
              </div>
              <div>
                <FormInput
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                />
              </div>
              <FormButton
                className="w-full"
                loading={loading}
                title="Logg inn"
              />
              <FormError error={error} />
            </Form>
          </Formik>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="flex items-center justify-center w-3/5">
        <img src={loginImage} alt="login screen" />
      </div> */}
    </div>
  );
};
