import React from 'react';

import { Card, CardLabel, CardLabelType } from 'components/Card';
import { Meter } from 'components/Charts/Meter';
import { PatientInfoResponse } from 'interfaces';
import { CopyText } from 'components/CopyText';
import { HoverContent } from 'components/HoverContent';
import { phenotypeText } from 'helpers/phenotype';
import { replaceScore } from 'utils';

interface PrognoseProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const Prognose: React.FC<PrognoseProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { Phenotype } = data.patientDetails;
  return (
    <div className={`${className ? className : ''}`}>
      <Card
        className={`${cardClassName ? cardClassName : ''}`}
        noSpacing={true}
      >
        <div className="flex flex-col flex-1 items-center justify-center">
          <HoverContent
            content={replaceScore(phenotypeText.hover.no, Phenotype)}
          >
            <CopyText
              content={replaceScore(
                phenotypeText.hover.no,
                Phenotype,
              )}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <div className="inline-block mb-4">
                  <Meter value={Number(Phenotype)} />
                </div>
                <CardLabel text="Prognose" size={CardLabelType.lg} />
              </div>
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
