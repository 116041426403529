import React from 'react';
import { useField, FieldConfig, Field } from 'formik';

interface FormInputProps extends FieldConfig {
  id: string;
  label?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  as?: 'textarea' | 'select' | 'input';
  rows?: number;
}

export const FormInput: React.FC<FormInputProps> = ({
  id,
  label,
  className,
  disabled,
  readOnly,
  as = 'input',
  rows,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label className="leading-none" htmlFor={id}>
        <Field
          className={`${
            className
              ? className
              : 'block border border-gray-300 rounded px-4 h-12 text-base w-full bg-white'
          } ${disabled ? 'cursor-not-allowed' : ''}`}
          {...field}
          {...props}
          disabled={disabled}
          readOnly={readOnly}
          as={as}
          rows={rows}
        />
      </label>
      {meta.touched && meta.error ? (
        <div className="text-sm text-red-600">{meta.error}</div>
      ) : null}
    </>
  );
};
