import React from 'react';
import { PainSitesType } from 'interfaces';

interface PainBodyProps {
  data: PainSitesType;
}

export const PainBody: React.FC<PainBodyProps> = ({ data }) => {
  const activeColor = (c: string) => (c === 'Y' ? '#ff0000' : '#fff');

  const sites = {
    head: activeColor(data.PainSites_head),
    neck: activeColor(data.PainSites_neck),
    upperBack: activeColor(data.PainSites_uback),
    lowerBack: activeColor(data.PainSites_lback),
    rightShoulder: activeColor(data.PainSites_shoul),
    leftShoulder: activeColor(data.PainSites_shoul),
    rightElbow: activeColor(data.PainSites_elbo),
    leftElbow: activeColor(data.PainSites_elbo),
    rightHand: activeColor(data.PainSites_wrist),
    leftHand: activeColor(data.PainSites_wrist),
    rightHip: activeColor(data.PainSites_hip),
    leftHip: activeColor(data.PainSites_hip),
    rightKnee: activeColor(data.PainSites_knee),
    leftKnee: activeColor(data.PainSites_knee),
    rightFoot: activeColor(data.PainSites_ancle),
    leftFoot: activeColor(data.PainSites_ancle),
  };

  return (
    <div
      className="w-1/2"
      style={{ minWidth: '140px', maxWidth: '180px' }}
    >
      <svg
        viewBox="0 0 83 224"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_421:4935)">
          {/* left elbow */}
          <path
            d="M11.5524 70.8838C8.78965 69.8203 6.23489 69.3442 4.36815 69.1326C4.21348 71.4604 4.66683 74.0264 2.9761 77.7404C1.15203 81.7401 0.0959961 87.3588 0.288003 93.6441C0.325338 94.8397 0.405341 96.21 0.517345 97.6597C2.06407 97.7284 4.09081 98.0247 6.72024 98.7866C8.58164 99.3262 10.0217 99.924 11.1311 100.527C12.6138 93.8134 14.2512 86.3324 14.3045 84.5971C14.4005 81.4544 15.3606 77.0738 16.3206 74.7883C16.4059 74.5872 16.5019 74.365 16.5979 74.1322C15.4619 72.9894 13.8298 71.762 11.5524 70.8838Z"
            fill={sites.leftElbow}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* left forearm/wrist/hand */}
          <path
            d="M2.30407 124.594C2.49607 125.832 3.07209 129.642 3.74412 131.165C4.41614 132.689 7.48826 136.498 8.16028 136.498C8.83231 136.498 9.88835 134.975 9.88835 134.213C9.88835 133.451 8.92831 129.166 8.16028 128.308C7.39225 127.451 7.29625 122.499 7.58426 121.452C7.87227 120.404 8.92831 119.071 9.60033 119.547C10.2724 120.023 13.6325 123.737 14.5925 123.166C15.5526 122.595 15.9366 121.261 14.7845 120.499C13.6325 119.738 11.6164 115.357 10.4644 114.5C9.31232 113.643 8.54429 113.976 8.7363 111.855C8.82164 110.908 9.93101 105.966 11.1311 100.527C10.0164 99.9242 8.58163 99.3263 6.72023 98.7867C4.0908 98.0248 2.05872 97.7339 0.517334 97.6598C0.986685 103.834 1.95739 111.5 2.11206 113.738C2.30407 116.5 2.11206 123.356 2.30407 124.594Z"
            fill={sites.leftHand}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          <path
            d="M15.2644 58.5673C15.2644 58.5673 18.8379 66.5085 19.2966 68.1221"
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* left hip/thigh */}
          <path
            d="M32.2092 126.499C27.4837 125.912 23.1368 118.357 20.6621 113.119C20.2727 121.33 20.3581 134.419 20.9661 139.546C21.3021 142.387 21.7661 146.688 22.1768 150.862C24.9182 150.434 28.6517 149.973 31.3931 150.116C34.0652 150.254 37.0094 150.973 39.1214 151.587C40.0441 146.72 40.7802 141.128 41.0895 137.165C41.2388 135.26 41.3508 128.488 41.4308 127.261C41.4308 127.261 39.5534 127.414 32.2092 126.499Z"
            fill={sites.leftHip}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* left knee */}
          <path
            d="M23.1369 162.496C23.2329 166.115 21.0035 171.258 20.9662 178.019C20.9555 179.982 21.1795 182.199 21.5209 184.505C23.2329 184.156 25.537 183.834 28.0651 183.892C30.7745 183.955 33.58 184.701 35.6707 185.4C36.1454 180.432 35.8094 174.898 35.5214 172.21C35.1374 168.591 35.8094 163.83 37.4415 158.687C38.0281 156.835 38.6042 154.328 39.1215 151.587C37.0094 150.973 34.0707 150.254 31.3932 150.116C28.6518 149.974 24.9183 150.434 22.1769 150.862C22.4009 153.127 22.6089 155.354 22.7743 157.29C22.9769 159.565 23.1103 161.438 23.1369 162.496Z"
            fill={sites.leftKnee}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* Lower leg/ankle/foot */}
          <path
            d="M21.5207 184.505C22.3527 190.129 23.8994 196.293 24.5768 200.684C25.5368 206.874 24.8648 210.017 24.8648 210.017C24.8648 210.017 22.3474 209.826 20.966 210.588C19.5846 211.35 18.5286 212.302 18.5286 213.54C18.5286 214.778 18.5286 216.683 19.2966 216.969C20.0646 217.254 24.3848 219.254 24.7688 219.826C25.1528 220.397 25.8248 221.921 26.7849 223.159C26.7849 223.159 28.0329 224.016 30.433 223.635C32.8331 223.254 33.6011 220.461 32.9931 217.921C32.2624 214.863 32.3904 212.937 32.8651 212.144C33.0358 211.858 33.1691 210.557 32.9771 209.033C32.7851 207.509 32.1611 206.398 32.3531 204.684C32.5451 202.97 33.3131 195.827 34.8492 190.209C35.2438 188.764 35.5052 187.119 35.6705 185.4C33.5851 184.696 30.7744 183.955 28.0649 183.892C25.5315 183.834 23.2327 184.156 21.5207 184.505Z"
            fill={sites.leftFoot}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* left shoulder/upper arm */}
          <path
            d="M32.5452 59.0751C35.4253 49.9329 35.0413 43.6476 35.0413 43.6476L34.9026 43.3831C33.3239 43.2508 31.4038 43.071 29.0891 42.8223C24.0169 42.2774 21.4035 40.6796 20.0701 39.3093C17.9953 39.5686 14.5819 40.5473 11.4244 44.219C8.0643 48.1235 7.48828 52.4089 7.10426 55.742C6.72025 59.0751 4.70418 66.1222 4.41617 68.5983C4.39483 68.7728 4.37883 68.9527 4.36816 69.1326C6.2349 69.3442 8.78966 69.8204 11.5524 70.8838C13.8298 71.7621 15.4619 72.9895 16.5979 74.1323C17.622 71.7568 19.2967 68.1221 19.2967 68.1221C19.2967 68.1221 29.6651 68.2173 32.5452 59.0751Z"
            fill={sites.leftShoulder}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          <path
            d="M24.3208 213.223C24.3208 213.223 24.6728 211.832 24.8648 210.017"
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right elbow */}
          <path
            d="M71.3146 70.8838C74.0773 69.8203 76.6321 69.3442 78.4988 69.1326C78.6535 71.4604 78.2001 74.0264 79.8909 77.7404C81.7149 81.7401 82.771 87.3588 82.579 93.6441C82.5416 94.8397 82.4616 96.21 82.3496 97.6597C80.8029 97.7284 78.7762 98.0247 76.1467 98.7866C74.2853 99.3262 72.8453 99.924 71.7359 100.527C70.2532 93.8134 68.6158 86.3324 68.5625 84.5971C68.4665 81.4544 67.5064 77.0738 66.5464 74.7883C66.461 74.5872 66.365 74.365 66.269 74.1322C67.4051 72.9894 69.0371 71.762 71.3146 70.8838Z"
            fill={sites.rightElbow}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right forearm/wrist/hand */}
          <path
            d="M80.563 124.594C80.371 125.832 79.795 129.642 79.1229 131.165C78.4509 132.689 75.3788 136.498 74.7068 136.498C74.0348 136.498 72.9787 134.975 72.9787 134.213C72.9787 133.451 73.9388 129.166 74.7068 128.308C75.4748 127.451 75.5708 122.499 75.2828 121.452C74.9948 120.404 73.9387 119.071 73.2667 119.547C72.5947 120.023 69.2346 123.737 68.2745 123.166C67.3145 122.595 66.9305 121.261 68.0825 120.499C69.2346 119.738 71.2507 115.357 72.4027 114.5C73.5547 113.643 74.3228 113.976 74.1308 111.855C74.0454 110.908 72.936 105.966 71.736 100.527C72.8507 99.9242 74.2854 99.3263 76.1468 98.7867C78.7763 98.0248 80.8083 97.7339 82.3497 97.6598C81.8804 103.834 80.9097 111.5 80.755 113.738C80.563 116.5 80.755 123.356 80.563 124.594Z"
            fill={sites.rightHand}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          <path
            d="M67.6025 58.5673C67.6025 58.5673 64.029 66.5085 63.5703 68.1221"
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right hip/thigh */}
          <path
            d="M50.658 126.499C55.3835 125.912 59.7303 118.357 62.2051 113.119C62.5944 121.33 62.5091 134.419 61.901 139.546C61.565 142.387 61.101 146.688 60.6903 150.862C57.9489 150.434 54.2154 149.973 51.474 150.116C48.8019 150.254 45.8578 150.973 43.7457 151.587C42.823 146.72 42.087 141.128 41.7776 137.165C41.6283 135.26 41.5163 128.488 41.4363 127.261C41.431 127.261 43.3137 127.414 50.658 126.499Z"
            fill={sites.rightHip}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right knee */}
          <path
            d="M59.7302 162.496C59.6342 166.115 61.8636 171.258 61.9009 178.019C61.9116 179.982 61.6876 182.199 61.3462 184.505C59.6342 184.156 57.3301 183.834 54.802 183.892C52.0926 183.955 49.2871 184.701 47.1964 185.4C46.7217 180.432 47.0577 174.898 47.3457 172.21C47.7298 168.591 47.0577 163.83 45.4257 158.687C44.839 156.835 44.263 154.328 43.7456 151.587C45.8577 150.973 48.7965 150.254 51.4739 150.116C54.2153 149.974 57.9488 150.434 60.6902 150.862C60.4662 153.127 60.2582 155.354 60.0929 157.29C59.8902 159.565 59.7569 161.438 59.7302 162.496Z"
            fill={sites.rightKnee}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right leg/ankle/foot */}
          <path
            d="M61.3461 184.505C60.5141 190.129 58.9674 196.293 58.29 200.684C57.33 206.874 58.002 210.017 58.002 210.017C58.002 210.017 60.5194 209.826 61.9008 210.588C63.2822 211.35 64.3382 212.302 64.3382 213.54C64.3382 214.778 64.3383 216.683 63.5702 216.969C62.8022 217.254 58.482 219.254 58.098 219.826C57.714 220.397 57.042 221.921 56.0819 223.159C56.0819 223.159 54.8339 224.016 52.4338 223.635C50.0337 223.254 49.2657 220.461 49.8737 217.921C50.6044 214.863 50.4764 212.937 50.0017 212.144C49.8311 211.858 49.6977 210.557 49.8897 209.033C50.0817 207.509 50.7058 206.398 50.5137 204.684C50.3217 202.97 49.5537 195.827 48.0177 190.209C47.623 188.764 47.3616 187.119 47.1963 185.4C49.2817 184.696 52.0925 183.955 54.8019 183.892C57.33 183.834 59.6341 184.156 61.3461 184.505Z"
            fill={sites.rightFoot}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* right shoulder/upper arm */}
          <path
            d="M50.3217 59.0751C47.4416 49.9329 47.8256 43.6476 47.8256 43.6476L47.9643 43.3831C49.543 43.2508 51.4631 43.071 53.7778 42.8223C58.85 42.2774 61.4635 40.6796 62.7968 39.3093C64.8716 39.5686 68.285 40.5473 71.4425 44.219C74.8026 48.1235 75.3786 52.4089 75.7627 55.742C76.1467 59.0751 78.1627 66.1222 78.4507 68.5983C78.4721 68.7728 78.4881 68.9527 78.4987 69.1326C76.632 69.3442 74.0773 69.8204 71.3145 70.8838C69.0371 71.762 67.405 72.9895 66.269 74.1323C65.2449 71.7568 63.5702 68.1221 63.5702 68.1221C63.5702 68.1221 53.2018 68.2173 50.3217 59.0751Z"
            fill={sites.rightShoulder}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* neck */}
          <path
            d="M62.4183 39.267C61.0423 39.1348 55.7034 36.8386 52.226 35.1192C51.7993 34.9075 51.4046 34.7065 51.0419 34.516C50.0339 33.987 49.3245 33.5637 49.1698 33.3627C48.4978 32.5056 48.6898 29.0773 48.6418 26.0299C48.6098 24.0247 49.0152 22.7655 49.2978 22.136C49.2978 22.136 48.6258 20.6652 46.2257 19.205C44.551 18.1839 41.4362 17.8082 41.4362 17.8082C41.4362 17.8082 38.3214 18.1839 36.6467 19.205C34.2466 20.6652 33.5746 22.136 33.5746 22.136C33.8573 22.7655 34.2626 24.0247 34.2306 26.0299C34.1826 29.0773 34.3746 32.5056 33.7026 33.3627C33.5426 33.5637 32.8386 33.987 31.8305 34.516C31.4732 34.7065 31.0732 34.9075 30.6465 35.1192C27.169 36.8386 21.8302 39.1348 20.4541 39.267C20.3368 39.2776 20.2088 39.2935 20.0754 39.3094C21.4088 40.6796 24.0223 42.2774 29.0944 42.8223C31.4092 43.071 33.3293 43.2509 34.908 43.3831C39.7935 43.7852 41.4362 43.7112 41.4362 43.7112C41.4362 43.7112 43.079 43.7852 47.9645 43.3831C49.5432 43.2509 51.4633 43.071 53.778 42.8223C58.8502 42.2774 61.4636 40.6796 62.797 39.3094C62.6637 39.2935 62.5357 39.2776 62.4183 39.267Z"
            fill={sites.neck}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* head */}
          <path
            d="M53.4901 15.6497C53.2981 14.4117 51.378 14.5069 51.378 14.5069C51.378 14.5069 52.6261 8.4121 49.746 4.69807C47.5166 1.83055 43.9378 0.264526 41.5537 0.264526C41.5163 0.264526 41.4737 0.269817 41.4363 0.269817C41.399 0.269817 41.3563 0.264526 41.319 0.264526C38.9349 0.264526 35.3508 1.83055 33.1267 4.69807C30.2466 8.4121 31.4946 14.5069 31.4946 14.5069C31.4946 14.5069 29.5746 14.4117 29.3826 15.6497C29.1906 16.8877 30.5346 19.4589 31.3026 20.6017C32.0707 21.7445 33.3187 21.6493 33.3187 21.6493C33.3187 21.6493 33.4307 21.808 33.5747 22.136C33.5747 22.136 34.2467 20.6652 36.6468 19.205C38.3216 18.1839 41.4363 17.8083 41.4363 17.8083C41.4363 17.8083 44.5511 18.1839 46.2259 19.205C48.6259 20.6652 49.298 22.136 49.298 22.136C49.442 21.808 49.554 21.6493 49.554 21.6493C49.554 21.6493 50.802 21.7445 51.5701 20.6017C52.3381 19.4589 53.6821 16.8877 53.4901 15.6497Z"
            fill={sites.head}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* upper back */}
          <path
            d="M58.7703 86.4066C59.7304 81.5498 63.5705 68.1222 63.5705 68.1222C63.5705 68.1222 53.2021 68.2174 50.322 59.0752C47.4419 49.933 47.8259 43.6477 47.8259 43.6477L47.9646 43.3832C43.0791 43.7853 41.4364 43.7112 41.4364 43.7112C41.4364 43.7112 39.7936 43.7853 34.9028 43.3832L35.0415 43.6477C35.0415 43.6477 35.4255 49.933 32.5454 59.0752C29.6653 68.2174 19.2969 68.1222 19.2969 68.1222C19.2969 68.1222 23.137 81.5498 24.0971 86.4066C24.7211 89.5492 23.521 95.1361 22.4063 100.527C22.4063 100.527 25.9211 95.7392 32.8334 90.5015C37.2175 87.179 41.431 86.1209 41.431 86.1209C41.431 86.1209 45.6445 87.179 50.0287 90.5015C56.9409 95.7392 60.4557 100.527 60.4557 100.527C59.3463 95.1361 58.1463 89.5492 58.7703 86.4066Z"
            fill={sites.upperBack}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          {/* lower back */}
          <path
            d="M61.901 108.744C61.6503 106.347 61.069 103.464 60.4609 100.527C60.4609 100.527 56.9461 95.7392 50.0339 90.5015C45.6497 87.179 41.4362 86.1208 41.4362 86.1208C41.4362 86.1208 37.2228 87.179 32.8386 90.5015C25.9263 95.7392 22.4115 100.527 22.4115 100.527C21.8035 103.464 21.2222 106.347 20.9715 108.744C20.8488 109.913 20.7475 111.415 20.6675 113.119C23.1422 118.357 27.4891 125.912 32.2146 126.499C39.5588 127.414 41.4362 127.261 41.4362 127.261C41.4362 127.261 43.3136 127.414 50.6579 126.499C55.3834 125.912 59.7303 118.357 62.205 113.119C62.125 111.415 62.0237 109.913 61.901 108.744Z"
            fill={sites.lowerBack}
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
          <path
            d="M58.5462 213.223C58.5462 213.223 58.1942 211.832 58.0022 210.017"
            stroke="#1D1D1C"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_421:4935">
            <rect width="82.8671" height="224" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
