export const orebq1qText = {
  label: {
    en: 'Pain duration',
    no: 'Smertevarighet',
  },
  hover: {
    en: '',
    no: 'Hvor lenge har du hatt dine nåværende plager? Fra under en måned til over ett år. Pasientsvar: "actual score"',
  },
  values: [
    { value: '0t1w', text: '0 - 1 uker', score: 1 },
    { value: '1t2w', text: '1 - 2 uker', score: 2 },
    { value: '3t4w', text: '3 - 4 uker', score: 3 },
    { value: '4t5w', text: '4 - 5 uker', score: 4 },
    { value: '6t8w', text: '6 - 8 uker', score: 5 },
    { value: '9t11w', text: '9 - 11 uker', score: 6 },
    { value: '3t6m', text: '3 - 6 måneder', score: 7 },
    { value: '6t9m', text: '6 - 9 måneder', score: 8 },
    { value: '9t12m', text: '9 - 12 måneder', score: 9 },
    { value: 'm12m', text: 'Over ett år', score: 10 },
  ],
};
