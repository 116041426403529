export const shoulderIsoExtrotPowText = {
  values: [
    { value: 'ShoulderIsoExtrotPow', text: 'Ja', score: 1 },
    { value: 'ShoulderIsoExtrotPow', text: 'Nei', score: 0 },
    {
      value: 'ShoulderIsoExtrotPow',
      text: 'Ikke aktuelt',
      score: -1,
    },
  ],
};
