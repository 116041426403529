import {
  PatientInfoResponse,
  TreatmentJournalText,
} from 'interfaces';

import {
  activityText,
  educationText,
  getMappedValue,
  redFlagsText,
} from 'helpers';
import { healthLitInfoText } from 'helpers/healthLitInfo';
import { comorbidityotherspText } from 'helpers/comorbidityothersp';
import { comorbidityText } from 'helpers/comorbidity';
import { bodyMainText } from 'helpers/bodyMain';
import { bodyMainTraumaText } from 'helpers/bodyMainTrauma';
import { orebq1qText } from 'helpers/orebq1q';
import { medicTypeText } from 'helpers/medicType';
import { medicNumberText } from 'helpers/medicNumber';
import { expectationConsultationText } from 'helpers/expectationConsultation';
import { qol15dVitalText } from 'helpers/qol15dVital';
import { eq5dDeprText } from 'helpers/eq5dDepr';
import { treat12Text } from 'helpers/treat12';
import { neckMoveRomText } from 'helpers/neckMoveRom';
import { neckMovePainText } from 'helpers/neckMovePain';
import { neckConclusionText } from 'helpers/neckConclusion';
import {
  journalComment,
  journalDescriptionText,
  journalVariablesMapped,
  journalYesNoText,
} from 'helpers/journal';
import { lowercaseFirstLetter } from 'helpers/lowercastFirstLetter';
import { shoulderMoveRomText } from 'helpers/ShoulderMoveRom';
import { shoulderMovePainText } from 'helpers/ShoulderMovePain';
import { backMoveRomText } from 'helpers/BackMoveRom';
import { backMovePainText } from 'helpers/BackMovePain';
import { hipMoveRomText } from 'helpers/HipMoveRom';
import { hipMovePainText } from 'helpers/HipMovePain';
import { hipMovePassiveRomText } from 'helpers/HipMovePassiveRom';
import { hipMovePassivePainText } from 'helpers/HipMovePassivePain';
import { kneeMovePainText } from 'helpers/kneeMovePain';
import { kneeMoveRomText } from 'helpers/kneeMoveRom';
import { kneeMovePassivePainText } from 'helpers/kneeMovePassivePain';
import { kneeMovePassiveRomText } from 'helpers/kneeMovePassiveRom';
import { diagnosisText } from 'helpers/diagnosis';
import { hipPainText } from 'helpers/HipPain';

export const treatmentJournalLong = (
  data: PatientInfoResponse,
): TreatmentJournalText[] => {
  const {
    HipPain_nota,
    HipPain_lat,
    HipPain_med,
    HipPain_post,
    HipPain_ant,
    Icpc_Letter,
    Icpc_Number,
    ShoulderIsoExtrotPow,
    TreatFreeText,
    TreatConsFollowup,
    TreatDialEmp,
    TreatFunctAssess,
    TreatFysGfMt,
    TreatFysPm,
    TreatMedicCoAng,
    TreatMedicGel,
    TreatMedicNeuri,
    TreatMedicNsaid,
    TreatMedicOpiod,
    TreatMedicPara,
    TreatRefImagMrCt,
    TreatRefImagOth,
    TreatRefImagXray,
    TreatRefOthPrimCar,
    TreatRefPsych,
    TreatRefSpeciIntdis,
    TreatRefSpeciRehab,
    TreatRefSpeciSpeci,
    TreatRefWellCenter,
    TreatRevWork,
    TreatSickleaveFull,
    TreatSickleaveGrad,
    TreatSickleavePen,
    TreatPsycSociFact,
    TreatPhysActivity,
    TreatSociActivity,
    TreatSleep,
    TreatFatig,
    TreatDepr,
    TreatMentDis,
    TreatFearMov,
    TreatPain,
    TreatPainMan,
    TreatExtLongLast,
    TreatWork,
    TreatEmploymentConflict,
    TreatWeightRed,
    TreatOa,
    TreatSmokQuit,
    KneeMovePassive,
    KneeMovePassiveRom_flex,
    KneeMovePassiveRom_ext,
    KneeMovePassiveRom_not,
    KneeMovePassivePain_flex,
    KneeMovePassivePain_ext,
    KneeMovePassivePain_not,
    KneeMove,
    KneeMovePain_flex,
    KneeMovePain_ext,
    KneeMovePain_not,
    KneeTraumatic,
    KneeMoveRom_flex,
    KneeMoveRom_ext,
    KneeMoveRom_not,
    KneeHydrops,
    HipMove,
    HipMovePassive,
    HipMovePassiveRom_flex,
    HipMovePassiveRom_ext,
    HipMovePassiveRom_abd,
    HipMovePassiveRom_add,
    HipMovePassiveRom_extrot,
    HipMovePassiveRom_introt,
    HipMovePassiveRom_not,
    HipMovePassivePain_flex,
    HipMovePassivePain_ext,
    HipMovePassivePain_abd,
    HipMovePassivePain_add,
    HipMovePassivePain_extrot,
    HipMovePassivePain_introt,
    HipMovePassivePain_not,
    HipMoveRom_flex,
    HipMoveRom_ext,
    HipMoveRom_abd,
    HipMoveRom_add,
    HipMoveRom_extrot,
    HipMoveRom_introt,
    HipMoveRom_not,
    HipMovePain_flex,
    HipMovePain_ext,
    HipMovePain_abd,
    HipMovePain_add,
    HipMovePain_extrot,
    HipMovePain_introt,
    HipMovePain_not,
    BackMove,
    BackMoveRom_flex,
    BackMoveRom_ext,
    BackMoveRom_rot,
    BackMoveRom_lat,
    BackMoveRom_not,
    BackMovePain_flex,
    BackMovePain_ext,
    BackMovePain_rot,
    BackMovePain_lat,
    BackMovePain_not,
    BackSlr,
    BackNeuro_nota,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
    KneeComment,
    HipComment,
    WidespreadComment,
    BackComment,
    ShoulderIsoAbdPain,
    ShoulderIsoExtrotPain,
    ShoulderIsoAbdPow,
    ShoulderHawkins,
    ShoulderPainfularc,
    ShoulderPassiveExtrot,
    ShoulderMoveRom_flex,
    ShoulderMoveRom_ext,
    ShoulderMoveRom_abd,
    ShoulderMoveRom_add,
    ShoulderMoveRom_extrot,
    ShoulderMoveRom_introt,
    ShoulderMoveRom_not,
    ShoulderMovePain_flex,
    ShoulderMovePain_ext,
    ShoulderMovePain_abd,
    ShoulderMovePain_add,
    ShoulderMovePain_extrot,
    ShoulderMovePain_introt,
    ShoulderMovePain_not,
    ShoulderMove,
    ShoulderComment,
    NeckConclusion,
    NeckComment,
    NeckMovePain_flex,
    NeckMovePain_ext,
    NeckMovePain_rot,
    NeckMovePain_lat,
    NeckMovePain_not,
    NeckMoveRom_flex,
    NeckMoveRom_ext,
    NeckMoveRom_rot,
    NeckMoveRom_lat,
    NeckMoveRom_not,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckNevro_nota,
    NeckSpurling,
    NeckMove,
    Diagnosis,
    Treat12m_Treat12mAlternative,
    Treat12m_Treat12mChiro,
    Treat12m_Treat12mGp,
    Treat12m_Treat12mMt,
    Treat12m_Treat12mOther,
    Treat12m_Treat12mPhysio,
    Treat12m_Treat12mPsychoPt,
    Treat12m_Treat12mPsychologist,
    Treat12m_Treat12mSpecialist,
    Treat12m1,
    RedFlag_cance,
    RedFlag_nigsw,
    RedFlag_wloss,
    RedFlag_urin,
    BodyMainSick,
    EmploymentConflict,
    WorkAbility_work,
    OREBQ8_Q,
    OREBScore,
    EQ5DDepr,
    HSCLScore,
    OREBQ10_Q,
    PSEQScore,
    OREBQ7_Q,
    MSKHQQ15,
    Activity,
    Qol15dSleep,
    Qol15dVital,
    ExpectationConsultat_advic,
    ExpectationConsultat_imag,
    ExpectationConsultat_medic,
    ExpectationConsultat_noexp,
    ExpectationConsultat_other,
    ExpectationConsultat_sleav,
    BodyMain,
    BodyMainTrauma,
    OREBQ1,
    OREBQ2_Q,
    Medic,
    Medictype_paink,
    Medictype_nsaid,
    Medictype_gel,
    Medictype_forte,
    Medictype_coana,
    Medictype_other,
    MedicNumber,
    Smoking,
    DemBMI,
    HealthLitInfo,
    Education,
    Gender,
    Phenotype,
    Comorbidity_asthm,
    Comorbidity_cance,
    Comorbidity_demen,
    Comorbidity_diab,
    Comorbidity_fatig,
    Comorbidity_heada,
    Comorbidity_heart,
    Comorbidity_neuro,
    Comorbidity_not,
    Comorbidity_oa,
    Comorbidity_osteop,
    Comorbidity_other,
    Comorbidity_psych,
    Comorbidity_ra,
    Comorbidity_stopa,
    Comorbidityothersp,
  } = data.patientDetails;

  const treats = [
    Treat12m_Treat12mAlternative,
    Treat12m_Treat12mChiro,
    Treat12m_Treat12mGp,
    Treat12m_Treat12mMt,
    Treat12m_Treat12mOther,
    Treat12m_Treat12mPhysio,
    Treat12m_Treat12mPsychoPt,
    Treat12m_Treat12mPsychologist,
    Treat12m_Treat12mSpecialist,
  ];

  const flags: {
    [key: string]: string;
  } = {
    RedFlag_nigsw,
    RedFlag_cance,
    RedFlag_wloss,
    RedFlag_urin,
  };

  const comorbidities = {
    Comorbidity_asthm,
    Comorbidity_cance,
    Comorbidity_demen,
    Comorbidity_diab,
    Comorbidity_fatig,
    Comorbidity_heada,
    Comorbidity_heart,
    Comorbidity_neuro,
    Comorbidity_not,
    Comorbidity_oa,
    Comorbidity_osteop,
    Comorbidity_other,
    Comorbidity_psych,
    Comorbidity_ra,
    Comorbidity_stopa,
    Comorbidityothersp,
  };

  const medicTypes = [
    Medictype_paink,
    Medictype_nsaid,
    Medictype_gel,
    Medictype_forte,
    Medictype_coana,
    Medictype_other,
  ];

  const expectationsConsultats = {
    ExpectationConsultat_advic,
    ExpectationConsultat_imag,
    ExpectationConsultat_medic,
    ExpectationConsultat_noexp,
    ExpectationConsultat_other,
    ExpectationConsultat_sleav,
  };

  return [
    {
      id: 'background-01',
      title: 'Bakgrunn',
      mappings: [
        {
          id: '*Smoking*',
          value: () => {
            return Smoking === 'Y' ? 'Røyker' : 'Røyker ikke';
          },
        },
        { id: '*DemBMI*', value: () => Number(DemBMI).toFixed(1) },
        {
          id: '*Education*',
          value: () =>
            getMappedValue(educationText.values, Education).text,
        },
        {
          id: '*HealthLitInfo*',
          value: () =>
            getMappedValue(
              healthLitInfoText.values,
              HealthLitInfo,
            ).text.toLowerCase(),
        },
      ],
      text: '*Smoking*. Pasienten har en BMI på *DemBMI*. Pasientens utdanningsnivå er: *Education*. Pasienten opplever *HealthLitInfo* vansker med å forstå helseinformasjon.',
    },
    {
      id: 'background-02',
      mappings: [
        {
          id: '*Comorbidity*',
          value: () => {
            return Object.entries(comorbidities)
              .map(([key, value]) => {
                return (
                  (value &&
                    comorbidityText.values.find(
                      (v) => v.value === key.split('_')[1],
                    )?.text) ||
                  key ===
                    comorbidityotherspText.values.find(
                      (v) => v.value === value,
                    )?.text
                );
              })
              .filter((p) => p)
              .join(', ')
              .replace('Tidligere sykdommer: ingen', 'Ingen');
          },
        },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*Phenotype*',
          value: () => `fenotype ${Phenotype}`,
        },
        {
          id: '*PhenotypeText*',
          value: () => {
            const t =
              [
                'En fenotype som er kjennetegnet med lavt symptomtrykk og god prognose.',
                'En fenotype som har noe forhøyet symptomtrykk, men som har god prognose.',
                'En fenotype som har moderat forhøyet symptomtrykk og middels økt risiko for nedsatt funksjon og redusert arbeidsevne.',
                'En fenotype som har moderat forhøyet symptomtrykk, sammensatte plager og en middels/høy risiko for nedsatt funksjon og langvarige plager.',
                'En fenotype som har høyt symptomtrykk og betydelig nedsatt funksjon og arbeidsevne. Stor risiko for langvarige plager og funksjonsnedsettelse.',
              ][Number(Phenotype) - 1]?.trim() || '*Missing value*';
            return lowercaseFirstLetter(t);
          },
        },
      ],
      text: 'Tidligere sykdommer: *Comorbidity*. <br /> <br /> *Gender* tilhører *Phenotype* som kjennetegnes av *PhenotypeText*',
    },
    {
      id: 'problem-01',
      title: 'Problem',
      mappings: [
        {
          id: '*BodyMain*',
          value: () => {
            const body = bodyMainText.values
              .filter((v) => v.value === BodyMain)
              .filter((f) => f)[0];

            return (
              `${
                body.score >= 6 ? body.text : body.text + 'smerte'
              }` || '*Missing value*'
            );
          },
        },
      ],
      text: '*BodyMain*',
    },
    {
      id: 'subject-01',
      title: 'Subjektivt',
      mappings: [
        {
          id: '*BodyMainTrauma*',
          value: () =>
            lowercaseFirstLetter(
              getMappedValue(
                bodyMainTraumaText.values,
                BodyMainTrauma,
              ).text,
            ),
        },
        {
          id: '*OREBQ1*',
          value: () =>
            getMappedValue(orebq1qText.values, OREBQ1).text,
        },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*Medic*',
          value: () => {
            if (Medic === 'N') {
              return 'har ikke benyttet smertestillende medikamenter ';
            }

            if (Medic === 'Y') {
              const medication = medicTypeText.values
                .map((v, i) => {
                  return medicTypes[i] === 'Y' ? v.text : '';
                })
                .filter((a) => a)
                .join(', ');
              return `har benyttet disse smertestillende medikamentene ${medication}. ${
                getMappedValue(medicNumberText.values, MedicNumber)
                  .text
              }`;
            }
            return '--*Missing value*--';
          },
        },
        {
          id: '*OREBQ2_Q*',
          value: () => `NRS ${OREBQ2_Q}`,
        },
      ],
      text: 'Smertene oppstod *BodyMainTrauma* , varighet *OREBQ1*. Smerteintensitet den siste uken *OREBQ2_Q*. *Gender* *Medic* den siste uken.',
    },
    {
      id: 'subject-02',
      mappings: [
        {
          id: '*Treat12m1*',
          value: () => {
            if (Treat12m1 === 'Y') {
              const people = treat12Text.values
                .filter((t, i) =>
                  ['bette', 'notbe'].includes(treats[i]),
                )
                .map((p) => p.text.toLocaleLowerCase())
                .join(', ');
              return `Pasienten har mottatt behandling fra: ${people} for nåværende plager i løpet av de 12 siste månedene.`;
            }

            if (Treat12m1 === 'N') {
              return 'Pasienten har ikke mottatt behandling fra andre behandlere for nåværende plager i løpet av de siste 12 månedene';
            }

            return '';
          },
        },
      ],
      text: '*Treat12m1*',
    },
    {
      id: 'subject-03',
      mappings: [
        {
          id: '*ExpectationConsultation*',
          value: () =>
            Object.entries(expectationsConsultats)
              .map(([key, value]) => {
                return (
                  value &&
                  `<li>${
                    expectationConsultationText.values.find(
                      (v) => v.value === key.split('_')[1],
                    )?.text
                  }</li>`
                );
              })
              .filter((p) => p)
              .join(''),
        },
      ],
      text: 'Forventninger til dagens time:<br> *ExpectationConsultation*',
    },
    {
      id: 'subject-04',
      mappings: [
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*Qol15dSleep*',
          value: () => {
            const values = [
              {
                value: 'norm',
                text: 'normal søvn, dvs. ingen problemer med å sove',
              },
              { value: 'sligh', text: 'lette søvnproblemer' },
              { value: 'mod', text: 'moderate søvnproblemer' },
              { value: 'great', text: 'store søvnvansker' },
              { value: 'server', text: 'alvorlige søvnplager' },
            ];
            return (
              values.find((v) => v.value === Qol15dSleep)?.text ||
              '--*Missing value*--'
            );
          },
        },
        {
          id: '*Qol15dVital*',
          value: () =>
            getMappedValue(
              qol15dVitalText.values,
              Qol15dVital,
            ).text.replace('Jeg føler meg', '') ||
            '--*Missing value*--',
        },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
      ],
      text: '*Gender* har *Qol15dSleep*. *Gender* føler seg *Qol15dVital*',
    },
    {
      id: 'subject-05',
      mappings: [
        {
          id: '*Activity*',
          value: () =>
            lowercaseFirstLetter(
              getMappedValue(activityText.values, Activity).text,
            ),
        },
        { id: '*MSKHQQ7_Q*', value: () => '' },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*MSKHQQ15*',
          value: () => {
            if (MSKHQQ15 === 'none') {
              return 'har ikke vært fysisk aktiv i 30 min eller mer i 3 dager den siste uken';
            }
            return `har vært fysisk aktiv i 30 min eller mer i ${MSKHQQ15.replace(
              'd',
              '',
            )} dager den siste uken.`;
          },
        },
      ],
      text: 'Det generelle aktivitetsnivå er *Activity*  på grunn av det aktuelle. Pasienten er *MSKHQQ7_Q* forhindret i å være sosialt aktiv og å holde på med hobbyer de siste to ukene grunnet sine ledd-/muskelsymptomer. *Gender* *MSKHQQ15* ',
    },
    {
      id: 'subject-06',
      mappings: [
        { id: '*OREBQ10_Q*', value: () => OREBQ10_Q },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'hun' : 'han'),
        },
        { id: '*PSEQScore*', value: () => PSEQScore },
        { id: '*OREBQ7_Q*', value: () => OREBQ7_Q },
      ],
      text: 'Pasienten svarer *OREBQ10_Q* på spørsmål om *Gender* bør utføre sine normale aktiviteter eller arbeid med den aktuelle smerten (0 til 10 - 0= ikke enig og 10= Helt enig). Videre angir pasienten smertemestring til *PSEQScore* (0 til 12 - 0=god smertemestring 12=lav smertemestring). Pasienten angir risiko for at plagene kan bli langvarige til *OREBQ7_Q* (0 til 10, 0=ingen risiko 10=svært stor risiko).',
    },
    {
      id: 'subject-07',
      mappings: [
        { id: '*HSCLScore*', value: () => HSCLScore },
        {
          id: '*EQ5DDepr*',
          value: () =>
            getMappedValue(
              eq5dDeprText.values,
              EQ5DDepr,
            ).text.replace('Jeg er ', ''),
        },
      ],
      text: 'Skår psykisk belastning: Grad av psykisk stress målt ved Hopkins Symptom Check-list: *HSCLScore* (1.0 - 4.0, over 1,85 er tegn på symptomer på psykisk stress). Pasienten angir å være *EQ5DDepr*.',
    },
    {
      id: 'subject-08',
      mappings: [{ id: '*OREBScore*', value: () => OREBScore }],
      text: 'Psykososiale risikofaktorer for arbeidsnedsettelse eller uførhet på grunn av smerter målt med Örebro screeningskjema - pasientscore: *OREBScore* (0 = Lav risiko, 100= Høy risiko, økt risiko over 50 poeng)',
    },
    {
      id: 'subject-09',
      mappings: [
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*BodyMainSick*',
          value: () => {
            if (BodyMainSick === 'Y') {
              return 'vært sykmeldt';
            }

            if (BodyMainSick === 'N') {
              return 'ikke vært sykmeldt';
            }

            return '--*Missing value*--';
          },
        },
        { id: '*WorkAbility_work*', value: () => WorkAbility_work },
        { id: '*OREBQ8_Q*', value: () => OREBQ8_Q },
        {
          id: '*Gender*',
          value: () => (Gender === 'F' ? 'Hun' : 'Han'),
        },
        {
          id: '*EmploymentConflict*',
          value: () => {
            if (EmploymentConflict === 'Y') {
              return 'konflikt på arbeidsplassen';
            }

            if (EmploymentConflict === 'N') {
              return 'ingen konflikt på arbeidsplassen';
            }
            return '--*Missing value*--';
          },
        },
      ],
      text: journalDescriptionText([
        '*Gender* har *BodyMainSick* for samme plager tidligere',
        'Pasienten angir nåværende arbeidsevne til *WorkAbility_work* sammenlignet med når arbeidsevnen er på det beste (0 = Best arbeidsevne, 10 = Ingen arbeidsevne)',
        'Pasienten angir sjanse for å være i arbeid om tre måneder til *OREBQ8_Q*  (0= ingen sjanse, 10 = svært stor sjanse)',
        '*Gender* opplever *EmploymentConflict*',
      ]),
    },
    {
      id: 'subject-09',
      mappings: [
        {
          id: '*redFlags*',
          value: () => {
            return (
              redFlagsText.values
                .filter((r) => flags[r.value] === 'Y')
                .map((v) => v.text)
                .join(', ')
                .trim() || 'ingen røde flagg'
            );
          },
        },
      ],
      text: 'Røde flagg: *redFlags*.',
    },
    {
      id: 'objectiv-01',
      title: 'Objektivt',
      mappings: [
        {
          id: '*Neck*',
          value: () => {
            if (Diagnosis === 'npain') {
              const neckText = {
                neckMove: () => {
                  if (NeckMove === 'Y') {
                    const neckMoveRomMapped = journalVariablesMapped(
                      neckMoveRomText.values,
                      [
                        NeckMoveRom_flex,
                        NeckMoveRom_ext,
                        NeckMoveRom_rot,
                        NeckMoveRom_lat,
                        NeckMoveRom_not,
                      ],
                      'Nedsatt beveglighet i: ',
                    );
                    const neckMovePainMapped = journalVariablesMapped(
                      neckMovePainText.values,
                      [
                        NeckMovePain_flex,
                        NeckMovePain_ext,
                        NeckMovePain_rot,
                        NeckMovePain_lat,
                        NeckMovePain_not,
                      ],
                      'Smertefull bevegelse i: ',
                    );

                    return `redusert og/eller smertefull aktive bevegelser i stående stilling: ${neckMoveRomMapped}. ${neckMovePainMapped}`;
                  }

                  if (NeckMove === 'N') {
                    return 'ikke reduserte og/eller smertefulle aktive bevegelsesutslag i stående stilling';
                  }
                  return '';
                },
                neckSpurling: journalYesNoText(
                  [NeckSpurling],
                  ['positiv', 'negativ'],
                ),
                neckNevro: () => {
                  if (NeckNevro_nota === 'Y') {
                    return 'Ingen nevrologiske funn';
                  }

                  if (
                    [
                      NeckNevro_pow,
                      NeckNevro_sens,
                      NeckNevro_reflex,
                    ].filter((b) => b === 'Y').length >= 1
                  ) {
                    return 'Nevrologiske funn';
                  }

                  return '';
                },
                conclusion: () => {
                  return lowercaseFirstLetter(
                    getMappedValue(
                      neckConclusionText.values,
                      NeckConclusion,
                    ).text,
                  );
                },
                comment: journalComment(NeckComment),
              };

              return journalDescriptionText([
                `Nakkeundersøkelse: Pasienten har ${neckText.neckMove()}`,
                `Spurling test er ${neckText.neckSpurling}`,
                neckText.neckNevro(),
                `Pasienten viser ${neckText.conclusion()}`,
                neckText.comment,
              ]);
            }

            return '';
          },
        },
        {
          id: '*Shoulder*',
          value: () => {
            if (Diagnosis === 'spain') {
              const shoulderText = {
                shoulderMove: () => {
                  if (ShoulderMove === 'Y') {
                    const neckMoveRomMapped = journalVariablesMapped(
                      shoulderMoveRomText.values,
                      [
                        ShoulderMoveRom_flex,
                        ShoulderMoveRom_ext,
                        ShoulderMoveRom_abd,
                        ShoulderMoveRom_add,
                        ShoulderMoveRom_extrot,
                        ShoulderMoveRom_introt,
                        ShoulderMoveRom_not,
                      ],
                      'Nedsatt beveglighet i: ',
                    );
                    const neckMovePainMapped = journalVariablesMapped(
                      shoulderMovePainText.values,
                      [
                        ShoulderMovePain_flex,
                        ShoulderMovePain_ext,
                        ShoulderMovePain_abd,
                        ShoulderMovePain_add,
                        ShoulderMovePain_extrot,
                        ShoulderMovePain_introt,
                        ShoulderMovePain_not,
                      ],
                      'Smertefull bevegelse i: ',
                    );

                    return `redusert og/eller smertefull aktive bevegelsesutslag: ${neckMoveRomMapped}. ${neckMovePainMapped}`;
                  }

                  if (ShoulderMove === 'N') {
                    return 'ikke reduserte og/eller smertefulle aktive bevegelsesutslag';
                  }
                  return '';
                },
                shoulderMovePaisn: () => '',
                shoulderPassiveExtrot: journalYesNoText(
                  [ShoulderPassiveExtrot],
                  [
                    'redusert passiv utadrotasjon',
                    'ikke redusert passiv utadrotasjon',
                  ],
                ),
                shoulderPainfularc: journalYesNoText(
                  [ShoulderPainfularc],
                  ['smertebue', 'ikke smertebue'],
                ),
                shoulderHawkins: journalYesNoText(
                  [ShoulderHawkins],
                  ['positiv Hawkins test', 'negativ Hawkins test'],
                ),
                shoulderIsoAbdPain: journalYesNoText([
                  ShoulderIsoAbdPain,
                ]),
                shoulderIsoExtrotPain: journalYesNoText([
                  ShoulderIsoExtrotPain,
                ]),
                shoulderIsoAbdPow: journalYesNoText([
                  ShoulderIsoAbdPow,
                ]),
                shoulderIsoextrotPow: journalYesNoText([
                  ShoulderIsoExtrotPow,
                ]),
                shoulderComment: journalComment(ShoulderComment),
              };
              return journalDescriptionText([
                `Skulderundersøkelse: Pasienten har ${shoulderText.shoulderMove()}`,
                `Pasienten har ${shoulderText.shoulderPassiveExtrot}`,
                `Pasienten har ${shoulderText.shoulderPainfularc}, ${shoulderText.shoulderHawkins}`,
                `${shoulderText.shoulderHawkins}`,
                `Smerter ved isomertisk abduksjon = ${shoulderText.shoulderIsoAbdPain}, utadrotasjon = ${shoulderText.shoulderIsoExtrotPain}`,
                `Redusert kraft ved isometrisk abduksjon = ${shoulderText.shoulderIsoAbdPow}`,
                `Redusert kraft ved isometrisk utadrotasjon = ${shoulderText.shoulderIsoextrotPow}`,
                shoulderText.shoulderComment,
              ]);
            }
            return '';
          },
        },
        {
          id: '*Back*',
          value: () => {
            if (Diagnosis === 'bpain') {
              const backText = {
                backMove: () => {
                  if (BackMove === 'Y') {
                    const backMoveRomMapped = journalVariablesMapped(
                      backMoveRomText.values,
                      [
                        BackMoveRom_flex,
                        BackMoveRom_ext,
                        BackMoveRom_rot,
                        BackMoveRom_lat,
                        BackMoveRom_not,
                      ],
                      'Nedsatt beveglighet i: ',
                    );
                    const backMovePainMapped = journalVariablesMapped(
                      backMovePainText.values,
                      [
                        BackMovePain_flex,
                        BackMovePain_ext,
                        BackMovePain_rot,
                        BackMovePain_lat,
                        BackMovePain_not,
                      ],
                      'Smertefull bevegelse i: ',
                    );

                    return `redusert og/eller smertefull aktive bevegelser i stående stilling: ${backMoveRomMapped}. ${backMovePainMapped}`;
                  }

                  if (NeckMove === 'N') {
                    return 'ikke reduserte og/eller smertefulle aktive bevegelsesutslag i stående stilling';
                  }
                  return '';
                },
                backSlr: journalYesNoText(
                  [BackSlr],
                  ['positiv', 'negativ'],
                ),
                backNeuro: () => {
                  if (BackNeuro_nota === 'Y') {
                    return 'Ingen nevrologiske funn';
                  }

                  if (
                    [
                      BackNeuro_pow,
                      BackNeuro_sens,
                      BackNeuro_reflex,
                    ].filter((b) => b === 'Y').length >= 1
                  ) {
                    return 'Nevrologiske funn';
                  }

                  return '';
                },
                comment: journalComment(BackComment),
              };
              return journalDescriptionText([
                `Ryggundersøkelse: Pasienten har ${backText.backMove()}`,
                `Laseque/straight leg raise er ${backText.backSlr}`,
                backText.backNeuro(),
                backText.comment,
              ]);
            }
            return '';
          },
        },
        {
          id: '*Hip*',
          value: () => {
            if (Diagnosis === 'hpain') {
              const hipText = {
                hipMove: () => {
                  if (HipMove === 'Y') {
                    const hipMoveRomMapped = journalVariablesMapped(
                      hipMoveRomText.values,
                      [
                        HipMoveRom_flex,
                        HipMoveRom_ext,
                        HipMoveRom_abd,
                        HipMoveRom_add,
                        HipMoveRom_extrot,
                        HipMoveRom_introt,
                        HipMoveRom_not,
                      ],
                      'Nedsatt beveglighet i: ',
                    );
                    const hipMovePainMapped = journalVariablesMapped(
                      hipMovePainText.values,
                      [
                        HipMovePain_flex,
                        HipMovePain_ext,
                        HipMovePain_abd,
                        HipMovePain_add,
                        HipMovePain_extrot,
                        HipMovePain_introt,
                        HipMovePain_not,
                      ],
                      'Smertefull bevegelse i: ',
                    );

                    return `redusert og/eller smertefulle aktive bevegelsesutslag: ${hipMoveRomMapped}. ${hipMovePainMapped}`;
                  }

                  if (HipMove === 'N') {
                    return 'ikke redusert og eller smertefulle aktive bevegelsesutslag';
                  }
                  return '';
                },
                hipMovePassive: () => {
                  if (HipMovePassive === 'Y') {
                    const hipMovePassiveRomMapped =
                      journalVariablesMapped(
                        hipMovePassiveRomText.values,
                        [
                          HipMovePassiveRom_flex,
                          HipMovePassiveRom_ext,
                          HipMovePassiveRom_abd,
                          HipMovePassiveRom_add,
                          HipMovePassiveRom_extrot,
                          HipMovePassiveRom_introt,
                          HipMovePassiveRom_not,
                        ],
                        'Nedsatt beveglighet i:',
                      );
                    const hipMovePassivePainMapped =
                      journalVariablesMapped(
                        hipMovePassivePainText.values,
                        [
                          HipMovePassivePain_flex,
                          HipMovePassivePain_ext,
                          HipMovePassivePain_abd,
                          HipMovePassivePain_add,
                          HipMovePassivePain_extrot,
                          HipMovePassivePain_introt,
                          HipMovePassivePain_not,
                        ],
                        'Smertefull bevegelse i: ',
                      );

                    return `redusert og/eller smertefulle passive bevegelsesutslag: ${hipMovePassiveRomMapped} ${hipMovePassivePainMapped}`;
                  }

                  if (HipMovePassive === 'N') {
                    return 'ikke redusert og eller smertefulle aktive bevegelsesutslag';
                  }
                  return '';
                },
                hipPain: journalVariablesMapped(
                  hipPainText.values,
                  [
                    HipPain_lat,
                    HipPain_med,
                    HipPain_post,
                    HipPain_ant,
                  ],
                  'Pasienten angir at smertene sitter: ',
                ),
                comment: journalComment(HipComment),
              };
              return journalDescriptionText([
                `Hofteundersøkelse: Pasienten har ${hipText.hipMove()}`,
                `Pasienten har ${hipText.hipMovePassive()}`,
                hipText.hipPain,
                hipText.comment,
              ]);
            }
            return '';
          },
        },
        {
          id: '*Knee*',
          value: () => {
            if (Diagnosis === 'kpain') {
              const kneeText = {
                kneeMove: () => {
                  if (KneeMove === 'Y') {
                    const kneeMoveRomMapped = journalVariablesMapped(
                      kneeMoveRomText.values,
                      [
                        KneeMoveRom_flex,
                        KneeMoveRom_ext,
                        KneeMoveRom_not,
                      ],
                      'Nedsatt beveglighet i: ',
                    );
                    const kneeMovePainMapped = journalVariablesMapped(
                      kneeMovePainText.values,
                      [
                        KneeMovePain_flex,
                        KneeMovePain_ext,
                        KneeMovePain_not,
                      ],
                      'Smertefull bevegelse i: ',
                    );

                    return `redusert og/eller smertefulle aktive bevegelsesutslag: ${kneeMoveRomMapped}. ${kneeMovePainMapped}`;
                  }

                  if (KneeMove === 'N') {
                    return 'ikke redusert og eller smertefulle aktive bevegelsesutslag';
                  }
                  return '';
                },
                kneeMovePassive: () => {
                  if (KneeMovePassive === 'Y') {
                    const kneeMovePassiveRomMapped =
                      journalVariablesMapped(
                        kneeMovePassiveRomText.values,
                        [
                          KneeMovePassiveRom_flex,
                          KneeMovePassiveRom_ext,
                          KneeMovePassiveRom_not,
                        ],
                        'Nedsatt beveglighet i: ',
                      );
                    const kneeMovePassivePainMapped =
                      journalVariablesMapped(
                        kneeMovePassivePainText.values,
                        [
                          KneeMovePassivePain_flex,
                          KneeMovePassivePain_ext,
                          KneeMovePassivePain_not,
                        ],
                        'Smertefull bevegelse i: ',
                      );

                    return `redusert og/eller smertefulle passive bevegelsesutslag: ${kneeMovePassiveRomMapped}. ${kneeMovePassivePainMapped}`;
                  }

                  if (KneeMovePassive === 'N') {
                    return 'ikke redusert og eller smertefulle aktive bevegelsesutslag';
                  }
                  return '';
                },
                kneeHydrops: journalYesNoText(
                  [KneeHydrops],
                  ['har', 'har ikke'],
                ),
                kneeTraumatic: journalYesNoText(
                  [KneeTraumatic],
                  [
                    'vært utsatt for et traume',
                    'ikke vært utsatt for traume',
                  ],
                ),
                comment: journalComment(KneeComment),
              };
              return journalDescriptionText([
                `Kneundersøkelse: Pasienten har ${kneeText.kneeMove()}`,
                `Pasienten har ${kneeText.kneeMovePassive()}`,
                `Pasiente ${kneeText.kneeHydrops} hydrops`,
                `Pasienten har ${kneeText.kneeTraumatic}`,
                kneeText.comment,
              ]);
            }
            return '';
          },
        },
        {
          id: '*Widespread*',
          value: () => {
            if (Diagnosis === 'mpain') {
              const widespreadText = {
                comment: journalComment(WidespreadComment),
              };
              return journalDescriptionText([
                `Undersøkelse utbredte/ sammensatte plager: ${widespreadText.comment}`,
              ]);
            }
            return '';
          },
        },
      ],
      text: '*Neck* *Shoulder* *Back* *Hip* *Knee* *Widespread*'.trim(),
    },
    {
      id: 'analyze-01',
      title: 'Analyse og behandlingsplan',
      mappings: [
        {
          id: '*ICPC*',
          value: () => {
            return Diagnosis
              ? `${[
                  `Pasienten får ICPC diagnose: ${[
                    Icpc_Letter,
                    Icpc_Number,
                  ].join('')}`,
                  getMappedValue(diagnosisText.values, Diagnosis)
                    .text,
                ].join('. ')}.`
              : '';
          },
        },
        {
          id: '*TreatFys*',
          value: () => {
            const gp = [
              { text: 'Fysioterapeut', value: TreatFysGfMt },
              {
                text: 'Psykomotorisk Fysioterapeut',
                value: TreatFysPm,
              },
            ]
              .filter((r) => r.value)
              .map((r) => r.text);
            return gp.length >= 1
              ? `Pasienten har blitt anbefalt å oppsøke ${gp.join(
                  ', ',
                )}.`
              : '';
          },
        },
        {
          id: '*TreatAdvice*',
          value: () => {
            const advices = [
              {
                text: 'Psykososiale faktorer',
                value: TreatPsycSociFact,
              },
              {
                text: 'Generelt aktivitetsnivå og fysisk aktivitet',
                value: TreatPhysActivity,
              },
              { text: 'Sosial aktivitet', value: TreatSociActivity },
              { text: 'Søvn', value: TreatSleep },
              { text: 'Utmattet', value: TreatFatig },
              { text: 'Engstelig eller deprimert', value: TreatDepr },
              { text: 'Psykisk belastning', value: TreatMentDis },
              { text: 'Bevegelsesfrykt', value: TreatFearMov },
              { text: 'Smerter (prognose)', value: TreatPain },
              { text: 'Smertemestring', value: TreatPainMan },
              {
                text: 'Forventning om langvarige plager',
                value: TreatExtLongLast,
              },
              { text: 'Arbeidsdeltakelse', value: TreatWork },
              {
                text: 'Arbeidskonflikt',
                value: TreatEmploymentConflict,
              },
              { text: 'Vektreduksjon', value: TreatWeightRed },
              { text: 'Artrose', value: TreatOa },
              { text: 'Røykestopp', value: TreatSmokQuit },
            ]
              .filter((r) => r.value)
              .map((r) => lowercaseFirstLetter(r.text));

            return advices.length >= 1
              ? `${
                  Gender === 'F' ? 'Hun' : 'Han'
                } har fått råd og veiledning om ${advices.join(
                  ', ',
                )}.`
              : '';
          },
        },
        {
          id: '*TreatMedic*',
          value: () => {
            const medications = [
              { text: 'Paracet', value: TreatMedicPara },
              { text: 'NSAIDS tabletter', value: TreatMedicNsaid },
              { text: 'NSAIDS gel', value: TreatMedicGel },
              {
                text: 'Antiepileptika (gabapentin/pregabalin)',
                value: TreatMedicNeuri,
              },
              {
                text: 'Tricycliske antidepressiva (TCA) / SNRI',
                value: TreatMedicCoAng,
              },
              { text: 'Svake opioder', value: TreatMedicOpiod },
            ]
              .filter((r) => r.value)
              .map((r) => r.text);
            return medications.length >= 1
              ? `Pasienten har fått råd om å bruke følgende medikamenter: ${medications.join(
                  ', ',
                )}.`
              : '';
          },
        },
        {
          id: '*TreatRef*',
          value: () => {
            const ref = [
              { text: 'Røntgen', value: TreatRefImagXray },
              { text: 'MR/CT', value: TreatRefImagMrCt },
              { text: 'Andre   ', value: TreatRefImagOth },
              { text: 'Spesialist', value: TreatRefSpeciSpeci },
              {
                text: 'Tverrfaglig poliklinikk/smerteklinikk',
                value: TreatRefSpeciIntdis,
              },
              { text: 'Rehabilitering', value: TreatRefSpeciRehab },
              { text: 'Psykolog', value: TreatRefPsych },
              { text: 'Frisklivssentral', value: TreatRefWellCenter },
              {
                text: 'Andre i primærhelsetjenesten',
                value: TreatRefOthPrimCar,
              },
            ]
              .filter((r) => r.value)
              .map((r) => r.text);

            return ref.length >= 1
              ? `Pasienten ble henvist til: ${ref.join(', ')}.`
              : '';
          },
        },
        {
          id: '*TreatSickleave*',
          value: () => {
            let sickLeaveType = '';
            if (TreatSickleavePen) {
              sickLeaveType = 'gradert';
            }

            if (TreatSickleaveGrad) {
              sickLeaveType = 'avventende';
            }

            if (TreatSickleaveFull) {
              sickLeaveType = 'full';
            }

            return sickLeaveType.length > 1
              ? `Pasienten har fått ${sickLeaveType} sykemelding.`
              : '';
          },
        },
        {
          id: '*TreatDialEmp*',
          value: () => {
            return TreatDialEmp
              ? 'Der er anbefalt dialog med arbeidsgiver.'
              : '';
          },
        },
        {
          id: '*TreatRevWork*',
          value: () => {
            return TreatRevWork
              ? 'Det er utarbeidet en plan til tilbakeføring til arbeid.'
              : '';
          },
        },
        {
          id: '*TreatFunctAssess*',
          value: () => {
            return TreatFunctAssess
              ? 'Der er foretatt en funksjonsvurdering.'
              : '';
          },
        },
        {
          id: '*TreatConsFollowup*',
          value: () => {
            return TreatConsFollowup
              ? 'Det er vurdert å sette opp oppfølgingstime.'
              : '';
          },
        },
      ],
      text: [
        '*ICPC*',
        '*TreatFys*',
        '*TreatAdvice*',
        '*TreatMedic*',
        '*TreatRef*',
        '*TreatSickleave*',
        '*TreatDialEmp*',
        '*TreatRevWork*',
        '*TreatFunctAssess*',
        '*TreatConsFollowup*',
      ].join(' '),
    },
    {
      id: 'freetext-01',
      title: 'Annen anbefalt behandling',
      mappings: [
        {
          id: '*TreatFreeText*',
          value: () => {
            return TreatFreeText || '';
          },
        },
      ],
      text: ['*TreatFreeText*'].join(' '),
    },
  ];
};
