import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useRouter } from 'hooks';

import { Helmet } from 'react-helmet';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';

import { TreatmentPrognose } from './TreatmentPrognose';
import { TreatmentFactors } from './TreatmentFactors';
import { TreatmentCard } from './TreatmentCard';
import { CardLabel, CardLabelType } from 'components/Card';
import { FormButton } from 'components/Form';
import { PatientInfoResponse, TreatmentInfo } from 'interfaces';
import {
  TREATMENT_VARIABLES,
  TREATMENT_ADVICE,
  TREATMENT_MEDICATION,
  TREATMENT_WORK,
  TREATMENT_REFERRAL,
} from 'data/treatments';
import { Modal } from 'components/Modal';
import { TreatmentJournal } from './TreatmentJournal';
import { TreatmentPlan } from './TreatmentPlan';
import { TreatmentLegend } from './TreatmentLegend';
import { TreatmentFreeText } from './TreatmentFreeText';
import { useHistory } from 'react-router-dom';

interface TreatmentProps {
  data: PatientInfoResponse;
}

export const Treatment: React.FC<TreatmentProps> = ({ data }) => {
  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const { patientDetails } = data;
  const { Icpc_Number, Icpc_Letter, BodyMain, clinicianGroup } =
    patientDetails;
  const baseline =
    [Icpc_Number, Icpc_Letter, BodyMain].filter((v) => v).length < 3;

  const isInterventionGroup = clinicianGroup === 'intervention';

  if (baseline) {
    history.push('/app/baseline-common');
  }

  if (!isInterventionGroup) {
    history.push('/app/baseline-common');
  }

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Treatment`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  const handleSubmit = async (
    values: TreatmentInfo,
    actions: FormikHelpers<TreatmentInfo>,
  ) => {
    try {
      setLoading(true);
      const { setSubmitting } = actions;
      const token = localStorage.getItem('token') || '';
      const updatedValues = {
        ...data,
        status: 'TREATMENT',
        clinicianId: data.patientDetails.clinicianId,
        patientDetails: Object.assign(
          {},
          ...Object.entries(values).map(([key, value]) => {
            if (key === 'Phenotype') {
              return {
                [key]: String(value),
              };
            }

            const isNotArray = !Array.isArray(value);
            let v = value;
            if (isNotArray) {
              v = v ? String(v) : undefined;
            } else {
              const shifted = [...v].shift();
              v = shifted ? String(shifted) : undefined;
            }

            // Convert back
            if (TREATMENT_VARIABLES.includes(key)) {
              return {
                [key]: value === true ? 'Y' : undefined,
              };
            }

            return {
              [key]: v,
            };
          }),
        ),
      };
      // return console.log(updatedValues);
      // Get patient
      const body = JSON.stringify(updatedValues);
      // Save new info to patient
      await fetch(
        `${process.env.REACT_APP_URL_CBR}/patient/savePatient`,
        {
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      );
      console.log(values);
      await window.location.reload();
      setLoading(false);
      setSubmitting(false);
      // setIsOpen(true);
    } catch (error) {
      console.log('TreatmentError:', error);
    }
  };

  return (
    <div className="flex flex-col -mx-3">
      <Helmet>
        <title>{`SupportPrim - Treatment`}</title>
      </Helmet>
      <div className="flex">
        <div className="flex justify-between w-full">
          <TreatmentPrognose
            data={data}
            className="w-1/2 mx-3"
            cardClassName="h-full flex flex-col"
          />
          <TreatmentFactors
            data={data}
            className="w-1/2 mx-3"
            cardClassName="h-full flex flex-col"
          />
        </div>
      </div>
      <div className="flex m-6">
        <div className="mt-auto">
          <CardLabel
            text="Behandlingsanbefaling"
            size={CardLabelType.xl}
          />
          <CardLabel
            text="I henhold til fenotype og spesifikke faktorer"
            size={CardLabelType.sm}
          />
        </div>
        <TreatmentLegend />
      </div>
      <Formik
        initialValues={Object.assign(
          {},
          ...Object.entries(data.patientDetails).map(
            ([key, value]) => {
              // TreatDiscuPheno
              if (TREATMENT_VARIABLES.includes(key)) {
                return {
                  [key]: value === 'Y' ? true : false,
                };
              }

              return {
                [key]: value,
              };
            },
          ),
        )}
        onSubmit={(
          values: TreatmentInfo,
          actions: FormikHelpers<TreatmentInfo>,
        ) => {
          handleSubmit(values, actions);
        }}
        // validationSchema={BaselineSchema}
      >
        {(props: FormikProps<TreatmentInfo>) => {
          return (
            <Form>
              {/* <pre className="bg-white text-xs whitespace-pre-wrap m-3 p-4">
                {JSON.stringify(
                  Object.entries(props.values)
                    .filter(([key, value]) =>
                      TREATMENT_VARIABLES.includes(key),
                    )
                    .map((v) => {
                      return {
                        [v[0]]: v[1] === true ? 'Y' : undefined,
                      };
                    })
                    .flat()
                    .filter((v) => {
                      return (
                        Object.values(v).filter((v) => v).length >= 1
                      );
                    }),
                  null,
                  2,
                )}
              </pre> */}
              <div className="flex">
                <TreatmentCard
                  data={data}
                  treatment={TREATMENT_ADVICE}
                  className="w-1/4 mx-3"
                  cardClassName="h-full w-full"
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                />
                <TreatmentCard
                  data={data}
                  className="w-1/4 mx-3"
                  treatment={TREATMENT_WORK}
                  cardClassName="h-full w-full"
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                  toogleVisibility
                />
                <TreatmentCard
                  data={data}
                  className="w-1/4 mx-3"
                  treatment={TREATMENT_MEDICATION}
                  cardClassName="h-full w-full"
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                  toogleVisibility
                />

                <TreatmentCard
                  data={data}
                  className="w-1/4 mx-3"
                  treatment={TREATMENT_REFERRAL}
                  cardClassName="h-full w-full"
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                  toogleVisibility
                />
              </div>
              <div className="flex mt-6">
                <TreatmentFreeText />
                <div className="w-1/2 mx-3">
                  <div className="flex space-x-6">
                    <FormButton
                      className="w-32"
                      loading={loading}
                      title="Lagre"
                    />
                    <TreatmentJournal
                      data={{
                        ...data,
                        patientDetails: {
                          ...data.patientDetails,
                          ...props.values,
                        },
                      }}
                    />
                    <TreatmentPlan
                      data={{
                        ...data,
                        patientDetails: {
                          ...data.patientDetails,
                          ...props.values,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <Modal open={isOpen} onClose={(s) => setIsOpen(s)}>
                <pre className="text-xs whitespace-pre-wrap">
                  {JSON.stringify(props.values, null, 2)}
                </pre>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
