import { medicNumberText } from 'helpers/medicNumber';
import { medicTypeText } from 'helpers/medicType';
import { treat12Text } from 'helpers/treat12';
import {
  PatientDetails,
  PatientInfoResponse,
  TreatmentCard,
} from 'interfaces';

// * Treatment
export const TREATMENT_ADVICE: TreatmentCard = {
  title: 'Råd og veiledning',
  prognoses: [
    {
      id: '',
      rule: (data: PatientDetails) => {
        const prognose = Object.entries(data).find(([key, _]) => {
          return key === 'HealthLitInfo';
        });
        const isValid =
          ['occas', 'often', 'alwa'].includes(prognose?.[1] || '') ||
          false;
        return isValid ? ['Ta hensyn til helseforståelse'] : [];
      },
      hoverText: () => [
        'Forklar rolig og bruk enkelt og tydelig språk',
        'Still åpne spørsmål for å evaluere pasientens forståelse',
        'Be pasienten stille spørsmål',
        'Be pasienten gjenfortelle med sine egne ord det som er forklart',
        'Vurder behov for oppfølgingstime for å hjelpe pasient med helseforståelsen',
      ],
    },
  ],
  variables: [
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatDiscuPheno',
          title: 'Diskuter råd for fenotyper',
          phenotype: () => [
            { value: 1, color: 'green' },
            { value: 2, color: 'green' },
            { value: 3, color: 'green' },
            { value: 4, color: 'green' },
            { value: 5, color: 'green' },
          ],
          hoverText: [],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatConsFollowup',
          title: 'Avtal oppfølgingstime',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'green' },
          ],
          hoverText: [],
        },
      ],
    },
    {
      title: 'Fysioterapi',
      data: [],
      values: [],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatFysGfMt',
          title: 'Fysioterapeut',
          hoverText: [
            'Anbefaler tett samarbeid og dialog med fysioterapeut omkring pasienter med fenotype 3-5. Snakke frem fysioterapi og konservativ tilnærming som det beste for pasienten, i tillegg til råd og veiledning og Fysioterapi med fokus på aktiv tilnærming.',
          ],
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'green' },
            { value: 3, color: 'green' },
            { value: 4, color: 'green' },
            { value: 5, color: 'yellow' },
          ],
          variables: {
            TreafRef: {
              rule: (answer: string) => ['FysGfMt'].includes(answer),
            },
          },
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatFysPm',
          title: 'Psykomotorisk Fysioterapeut',
          hoverText: [
            'Anbefales for fenotype 4 og 5. Spesielt hvis vanlig fysioterapi har vært prøvd uten effekt. Hvis lang ventetid, bruk fysioterapeut med fokus på psykologisk informert tilnærming (f.eks kognitiv terapi).',
          ],
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'green' },
            { value: 5, color: 'green' },
          ],
          variables: {
            TreafRef: {
              rule: (answer: string) => ['FysGfMt'].includes(answer),
            },
          },
        },
      ],
    },
    {
      title: 'Persontilpassede råd',
      values: [],
      data: [
        {
          id: 'TreatPsycSociFact',
          title: 'Psykososiale faktorer',
          variables: {
            OREBScore: {
              rule: (score: string) => Number(score) >= 50,
            },
          },
          hoverText: [
            'Kartlegg psykososiale faktorer som stress, anspenthet, nedstemthet, søvn og forventninger om langvarige plager og arbeidsdeltakelse',
            'Kan totalbelastningen endres? Komorbiditeter?',
            'Behov for kontakt med verneombud på arbeidsplassen, tverrfaglig samarbeid eller videre henvisning hvis fare for varig arbeidsnedsettelse eller uførhet',
          ],
        },
        {
          id: 'TreatPhysActivity',
          title: 'Generelt aktivitetsnivå og fysisk aktivitet',
          variables: {
            Activity: {
              rule: (answer: string) =>
                ['vred', 'qred'].includes(answer),
            },
            MSKHQQ15: {
              rule: (answer: string) =>
                ['none', '1d'].includes(answer),
            },
          },
          hoverText: [
            'All form for aktivitet er bedre enn ingenting,  fysisk aktivitet er bra for helsa.',
            'Diskuter med pasienten muligheter for å øke aktivitetsnivået i hverdagen. Legg en plan',
          ],
        },
        {
          id: 'TreatSociActivity',
          title: 'Sosial aktivitet',
          variables: {
            MSKHQQ7_Q: {
              rule: (answer: string) =>
                ['very', 'extre'].includes(answer),
            },
          },
          hoverText: [
            'Viktig å kartlegge barrierer og muligheter for sosiale aktiviteter, diskutere med pasient, og legge en konkret plan for å øke sosial aktivitet',
            'Viktig å knytte bånd og delta i arbeid ',
          ],
        },
        {
          id: 'TreatSleep',
          title: 'Søvn',
          variables: {
            Qol15dSleep: {
              rule: (answer: string) =>
                ['mod', 'great', 'sever'].includes(answer),
            },
          },
          hoverText: [
            'Har pasienten noen formening om årsak til søvnplagene',
            'Hvilke tiltak har vedkommende forsøkt?',
            'Diskuter ulike søvnråd med pasienten (skjermbruk, avslapping, fysisk arbeid og trening, tilstrekkelig søvn (7-8 timer), rutiner for innsovningstid, søvn og våkentid alle dager, søvndeprivering etc.) ',
          ],
        },
        {
          id: 'TreatFatig',
          title: 'Utmattet',
          variables: {
            Qol15dVital: {
              rule: (answer: string) =>
                ['modw', 'veryw', 'extrw'].includes(answer),
            },
          },
          hoverText: [
            'Kartlegge totalbelastning i hverdag og jobb',
            'Hva er viktig å prioritere i hverdagen og hvordan balansere aktivitet og hvile.',
          ],
        },
        {
          id: 'TreatDepr',
          title: 'Engstelig eller deprimert',
          variables: {
            EQ5DDepr: {
              rule: (answer: string) =>
                ['mod', 'sever', 'extre'].includes(answer),
            },
          },
          hoverText: [
            'Er hovedproblemet angst eller depresjon?',
            'Har pasienten noen formening om årsak til angsten/depresjonen?',
            'Vurder videre henvisning eller samhandling med andre helsepersonell ved høy grad av angst og/eller depresjon',
          ],
        },
        {
          id: 'TreatMentDis',
          title: 'Psykisk belastning',
          variables: {
            HSCLScore: {
              rule: (score: string) => Number(score) >= 1.85,
            },
          },
          hoverText: [
            'Hva er pasientens formening om årsaker knyttet til den psykiske belastningen?',
            'Vurder videre henvisning eller samhandling med andre helsepersonell ved høy grad av psykisk belastning',
          ],
        },
        {
          id: 'TreatFearMov',
          title: 'Bevegelsesfrykt',
          variables: {
            OREBQ10_Q: {
              rule: (score: string) =>
                Number(score) >= 5 && Number(score) <= 10,
            },
          },
          hoverText: [
            'Kartlegg frykten, om den er rasjonell eller irrasjonell',
            'Gradvis eksponering for frykten, viktig å adressere spesifikke bevegelser man er redd for og ikke generell bevegelse.',
            'Hvis ikke spesifikk frykt, kan tilpasset generell trening og aktivitet for å minske frykten være fornuftig',
          ],
        },
        {
          id: 'TreatPain',
          title: 'Smerter (prognose)',
          variables: {
            OREBQ1: {
              rule: (answer: string) =>
                ['3t6m', '6t9m', '9t12m', 'm12m'].includes(answer),
            },
            OREBQ2_Q: {
              rule: (score: number) =>
                Number(score) >= 6 && Number(score) <= 10,
            },
          },
          hoverText: [
            'Forklar enkel smertefysiologi (viktig sans/varslingssystem som vi alle er utstyrt med)',
            'Akutt vs langvarig smerte (når smertesansen er hensiktsmessig og når den taper sin verdi)',
          ],
        },
        {
          id: 'TreatPainMan',
          title: 'Smertemestring',
          variables: {
            PSEQScore: {
              rule: (score: string) =>
                Number(score) >= 0 && Number(score) <= 5,
            },
          },
          hoverText: [
            'Fokuser på styrkene til pasienten, det pasienten får til',
            'Gjør øvelser så enkle at pasienten opplever å mestre oppgaven',
          ],
        },
        {
          id: 'TreatExtLongLast',
          title: 'Forventning om langvarige plager',
          variables: {
            OREBQ7_Q: {
              rule: (score: string) =>
                Number(score) >= 5 && Number(score) <= 10,
            },
          },
          hoverText: [
            'Diskuter forventninger til behandling, prognose og forløp. ',
            'Hvilken informasjon har pasienten med seg fra annet helsepersonell eller andre, diskuter eventuell motstridende informasjon?',
          ],
        },
        {
          id: 'TreatWork',
          title: 'Arbeidsdeltakelse',
          variables: {
            WorkAbility_work: {
              rule: (score: string) =>
                Number(score) >= 0 && Number(score) <= 5,
            },
            ExpectationConsultat_sleav: {
              rule: (answer: string) => answer === 'Y',
            },
            Employment_sleav: {
              rule: (answer: string) => answer === 'Y',
            },
            OREBQ8_Q: {
              rule: (score: string) =>
                Number(score) >= 0 && Number(score) <= 5,
            },
          },
          hoverText: [
            'Diskuter totalbelastning, hvile og trening. Ufarliggjøre symptomer og plager, trygging',
            'Vurder behov for samhandling med arbeidsgiver, andre behandlere, bedriftshelsetjeneset, Nav, andre.',
          ],
        },
        {
          id: 'TreatEmploymentConflict',
          title: 'Arbeidskonflikt',
          variables: {
            EmploymentConflict: {
              rule: (answer: string) => answer === 'Y',
            },
          },
          hoverText: [
            'Konflikt på arbeidsplassen er ikke sykmeldingsgrunn, men en sak mellom arbeidstaker og arbeidsgiver',
            'Dialog med pasient om årsak og hva pasienten ser som mulig løsning.',
            'Oppfordre pasient til dialog med arbeidsgiver gjennom avventende sykmelding. ',
          ],
        },
        {
          id: 'TreatWeightRed',
          title: 'Vektreduksjon',
          variables: {
            Comorbidity_oa: {
              rule: (p: PatientDetails) => {
                const answer = p['Comorbidity_oa'];
                const score = p['DemBMI'];
                return answer === 'Y' && Number(score) > 25;
              },
            },
            DemBMI: {
              rule: (score: string) => Number(score) > 30,
            },
          },
          hoverText: [
            'Er pasienten motivert for livsstilsendring og vektreduksjon?',
            'Hva er prøvd tidligere?',
            'Artrose kne og eller hofte',
          ],
        },
        {
          id: 'TreatOa',
          title: 'Artrose',
          variables: {
            Comorbidity_oa: {
              rule: (p: PatientDetails) =>
                p['Comorbidity_oa'] === 'Y',
            },
          },
          hoverText: [
            'I følge nasjonale og internasjonale retningslinjer bør alle personer med symptomer og funn på artrose i hofte og/eller kne få tilbud om en grunnbehandling bestående av informasjon, trening og råd om vektreduksjon (hvis nødvendig).',
            'Henvisning til atroseskole',
          ],
        },
        {
          id: 'TreatSmokQuit',
          title: 'Røykestopp',
          variables: {
            Smoking: {
              rule: (answer: string) => answer === 'Y',
            },
          },
          hoverText: ['Er pasienten motivert?'],
        },
      ],
    },
  ],
};

// * Medication
export const TREATMENT_MEDICATION: TreatmentCard = {
  title: 'Medisinering',
  prognoses: [
    {
      rule: (data: PatientDetails) => {
        const prognose = Object.entries(data).find(([key, _]) => {
          return key === 'Medic';
        });

        const isValid = ['Y'].includes(prognose?.[1] || '') || false;
        return isValid
          ? // ? [medicNumber, ...mapped_medicTypes]
            ['Nåværende bruk av smertestillende medisin']
          : ['Bruker ikke smertestillende medisiner'];
      },
      hoverText: (data: PatientDetails) => {
        const prognose = Object.entries(data).find(([key, _]) => {
          return key === 'Medic';
        });

        const medicNumber =
          medicNumberText.values.find(
            (v) => v.value === data.MedicNumber,
          )?.text || '';

        const medicTypes = [
          data.Medictype_paink,
          data.Medictype_nsaid,
          data.Medictype_gel,
          data.Medictype_forte,
          data.Medictype_coana,
          data.Medictype_other,
        ];

        let mapped_medicTypes =
          medicTypeText.values
            .map((v, i) => {
              return medicTypes[i] === 'Y' ? v.text : '';
            })
            .filter((a) => a) || [];
        const isValid = ['Y'].includes(prognose?.[1] || '') || false;
        return isValid ? [medicNumber, ...mapped_medicTypes] : [];
      },
    },
  ],
  variables: [
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicPara',
          title: 'Paracet',
          phenotype: () => [
            { value: 1, color: 'yellow' },
            { value: 2, color: 'yellow' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Paracetamol er førstevalget for smertelindring ved muskel- og skjelettsmerter og kan doseres 1 gram inntil 4 ganger daglig.',
          ],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicNsaid',
          title: 'NSAIDS Tabletter',
          data: [],
          phenotype: () => [
            { value: 1, color: 'yellow' },
            { value: 2, color: 'yellow' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Peroral NSAIDS brukes ved behov for antiflogistika, Naproksen  førstevalg grunnet gunstigere hjerte-kar bivirkningsprofil, vurder å legge til PPI ved økt risiko for GI-bivirkninger.',
          ],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicGel',
          title: 'NSAIDS gel',
          data: [],
          phenotype: () => [
            { value: 1, color: 'yellow' },
            { value: 2, color: 'yellow' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Topikale NSAIDS kan ha god effekt på leddsmerter og myalgier og har en gunstigere bivirkningsprofil en perorale midler grunnet lav systemisk absorpsjon (~10-15%).',
          ],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicNeuri',
          title: 'Antiepileptika (gabapentin/pregabalin)',
          data: [],
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Antiepileptika (gabapentin/pregabalin) – best dokumentert for nevropatiske smerter. Noe, men inkonklusiv dokumentasjon for langvarige ikke-maligne smerter.',
          ],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicCoAng',
          title: 'Tricycliske antidepressiva (TCA) / SNRI',
          data: [],
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Tricycliske antidepressive (TCA - Amitryptilin/Noritryptilin) kan ha effekt ved langvarige smertetilstander (>12 uker). Det er førstevalget ved fibromyalgi i dose 10mg opptrappende til 50mg, tatt 2 timer før leggetid. Kan brukes daglig over tid og gir ikke toleranseutvikling. Bivirkninger kan oppstå ved opptrapping, men disse avtar ofte etter 3 ukers bruk. Alternativt kan Serotonin- og noradrenalinreopptakshemmere (SNRI) f.eks. duloxetin forsøkes ved manglende effekt av TCA i dose 60mg daglig.',
          ],
        },
      ],
    },
    {
      title: '',
      data: [],
      values: [
        {
          id: 'TreatMedicOpiod',
          title: 'Svake opioider',
          data: [],
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'red' },
            { value: 5, color: 'red' },
          ],
          hoverText: [
            'Svake opioider – bør brukes med forsiktighet, helst ved behov eller i korte perioder (<14 dager) for å unngå toleranseutvikling. Best dokumentasjon ved akutte smertetilstander for å sikre søvn/mobilitet og smertelindre. Dårlig dokumentasjon ved langvarige ikke-maligne smertetilstander.',
          ],
        },
      ],
    },
  ],
};

// * Work
export const TREATMENT_WORK: TreatmentCard = {
  title: 'Arbeid',
  prognoses: [
    {
      rule: (data: PatientDetails) => {
        let text = [''];
        const value = Number(data['WorkAbility_work']);

        if (value >= 0 && value <= 5) {
          text = ['Redusert arbeidsevne'];
        }
        if (value >= 6 && value <= 7) {
          text = ['Moderat arbeidsevne'];
        }
        if (value >= 8 && value <= 10) {
          text = ['God arbeidsevne'];
        }

        return text;
      },
      hoverText: (data: PatientDetails) => {
        return [];
      },
    },
    {
      rule: (data: PatientDetails) => {
        let text = [''];
        const value = Number(data['OREBQ8_Q']);

        if (value <= 2) {
          text = ['Lave forventninger til fremtidig arbeidsevne'];
        } else if (value >= 3 && value <= 4) {
          text = ['Moderate forventninger til fremtidig arbeidsevne'];
        } else if (value >= 5 && value <= 10) {
          text = ['Høye forventninger til fremtidig arbeidsevne'];
        }

        return text;
      },
      hoverText: (data: PatientDetails) => {
        return [];
      },
    },
    {
      rule: (data: PatientDetails) => {
        const isValid =
          ['Y'].includes(data['ExpectationConsultat_sleav'] || '') ||
          false;
        return isValid ? ['Forventninger om sykmelding'] : [];
      },
      hoverText: (data: PatientDetails) => {
        return [];
      },
    },
    {
      rule: (data: PatientDetails) => {
        const isValid =
          ['Y'].includes(data['EmploymentConflict'] || '') || false;
        return isValid
          ? ['Pasienten opplever konflikt på arbeidsplassen']
          : [];
      },
      hoverText: (data: PatientDetails) => {
        return [
          'Pasienten melder om konflikt på arbeidsplassen, det anbefales at pasienten går i dialog med arbeidsgiver snarlig, dersom sykmelding skal benyttes anbefales avventende sykmelding som fordrer dialog mellom arbeidsgive og arbeidstaker.',
        ];
      },
    },
  ],
  variables: [
    {
      title: 'Sykemelding',
      phenotype: (data: PatientInfoResponse) => {
        const {
          Employment_pwork,
          Employment_sleav,
          Employment_stud,
          EmploymentConflict,
          OREBQ8_Q,
        } = data.patientDetails;
        const value = Number(OREBQ8_Q);
        let color = 'yellow';

        if (
          [Employment_pwork, Employment_sleav].filter(
            (e) => e === 'Y',
          ).length >= 1
        ) {
          if (
            value >= 5 &&
            value <= 10 &&
            EmploymentConflict === 'N'
          ) {
            color = 'red';
          }
        }

        if (
          [Employment_pwork, Employment_sleav].filter((e) =>
            ['n'].includes((e || '').toLocaleLowerCase()),
          ).length >= 1
        ) {
          color = 'gray';
        }

        if (
          [Employment_stud].filter((e) =>
            ['y'].includes((e || '').toLocaleLowerCase()),
          ).length >= 1
        ) {
          color = 'gray';
        }

        return [
          { value: 1, color },
          { value: 2, color },
          { value: 3, color },
          { value: 4, color },
          { value: 5, color },
        ];
      },
      hoverText: [],
      data: [],
      indentValues: true,
      values: [
        {
          id: 'TreatSickleaveGrad',
          title: 'Avventende',
          phenotype: (data: PatientInfoResponse) => {
            const {
              Employment_pwork,
              Employment_sleav,
              Employment_stud,
              Employment_retir,
              Employment_disab,
              Employment_wasse,
              Employment_unpwo,
              Employment_unemp,
              Employment_parle,
              OREBQ8_Q,
              EmploymentConflict,
            } = data.patientDetails;
            let color = 'red';
            const value = Number(OREBQ8_Q);

            if (
              [Employment_pwork, Employment_sleav].filter(
                (e) => e === 'Y',
              ).length >= 1
            ) {
              if (value <= 2) {
                if (EmploymentConflict === 'Y') {
                  color = 'green';
                } else {
                  color = 'yellow';
                }
              } else if (value >= 3 && value <= 4) {
                color = 'green';
              } else if (value >= 5 && value <= 10) {
                if (EmploymentConflict === 'Y') {
                  color = 'yellow';
                } else {
                  color = 'red';
                }
              }
            }

            if (
              [
                Employment_stud,
                Employment_retir,
                Employment_disab,
                Employment_wasse,
                Employment_unpwo,
                Employment_unemp,
                Employment_parle,
              ].filter((e) => e === 'Y').length >= 1
            ) {
              color = 'gray';
            }

            return [
              { value: 1, color },
              { value: 2, color },
              { value: 3, color },
              { value: 4, color },
              { value: 5, color },
            ];
          },
          hoverText: [
            'Avventende sykmelding kan fremme tilrettelegging og benyttes som første tiltak, alternativt til vanling sykmelding.',
          ],
        },
        {
          id: 'TreatSickleavePen',
          title: 'Gradert',
          phenotype: (data: PatientInfoResponse) => {
            const {
              Employment_pwork,
              Employment_sleav,
              Employment_stud,
              Employment_retir,
              Employment_disab,
              Employment_wasse,
              Employment_unpwo,
              Employment_unemp,
              Employment_parle,
              OREBQ8_Q,
              EmploymentConflict,
            } = data.patientDetails;
            let color = 'red';
            const value = Number(OREBQ8_Q);

            if (
              [Employment_pwork, Employment_sleav].filter(
                (e) => e === 'Y',
              ).length >= 1
            ) {
              if (value <= 2) {
                if (EmploymentConflict === 'Y') {
                  color = 'red';
                } else {
                  color = 'yellow';
                }
              } else if (value >= 3 && value <= 4) {
                if (EmploymentConflict === 'Y') {
                  color = 'red';
                } else {
                  color = 'green';
                }
              } else if (value >= 5 && value <= 10) {
                color = 'red';
              }
            }

            if (
              [
                Employment_stud,
                Employment_retir,
                Employment_disab,
                Employment_wasse,
                Employment_unpwo,
                Employment_unemp,
                Employment_parle,
              ].filter((e) => e === 'Y').length >= 1
            ) {
              color = 'gray';
            }

            return [
              { value: 1, color },
              { value: 2, color },
              { value: 3, color },
              { value: 4, color },
              { value: 5, color },
            ];
          },
          hoverText: [
            'Velg gradert sykmelding om mulig, selv 10% arbeidsdeltagelse kan lette tilbakeføring til jobb',
          ],
        },
        {
          id: 'TreatSickleaveFull',
          title: 'Full',
          phenotype: (data: PatientInfoResponse) => {
            const {
              Employment_pwork,
              Employment_sleav,
              Employment_stud,
              Employment_retir,
              Employment_disab,
              Employment_wasse,
              Employment_unpwo,
              Employment_unemp,
              Employment_parle,
              OREBQ8_Q,
              EmploymentConflict,
            } = data.patientDetails;
            let color = 'red';
            const value = Number(OREBQ8_Q);
            if (
              [Employment_pwork, Employment_sleav].filter(
                (e) => e === 'Y',
              ).length >= 1
            ) {
              if (value <= 2) {
                if (EmploymentConflict === 'Y') {
                  color = 'red';
                } else {
                  color = 'yellow';
                }
              } else if (value >= 3 && value <= 4) {
                if (EmploymentConflict === 'Y') {
                  color = 'red';
                } else {
                  color = 'yellow';
                }
              } else if (value >= 5 && value <= 10) {
                color = 'red';
              }
            }

            if (
              [
                Employment_stud,
                Employment_retir,
                Employment_disab,
                Employment_wasse,
                Employment_unpwo,
                Employment_unemp,
                Employment_parle,
              ].filter((e) => e === 'Y').length >= 1
            ) {
              color = 'gray';
            }

            return [
              { value: 1, color },
              { value: 2, color },
              { value: 3, color },
              { value: 4, color },
              { value: 5, color },
            ];
          },
          hoverText: [
            'Kan det tilrettelegges på arbeidsplassen for å unngå full sykmelding? Avventende sykmelding kan fremme tilrettelegging og benyttes som første tiltak, alternativt til vanlig sykmelding.',
            'Ved sykemelding lengre enn 4 uker, vurder samarbeid med arbeidsgiver, andre behandlere, bedriftshelsetjenesten eller NAV.',
          ],
        },
      ],
    },
    {
      id: 'TreatDialEmp',
      title: 'Dialog med arbeidsgiver',
      phenotype: (data: PatientInfoResponse) => {
        const {
          Employment_pwork,
          Employment_sleav,
          Employment_stud,
          Employment_retir,
          Employment_disab,
          Employment_wasse,
          Employment_unpwo,
          Employment_unemp,
          Employment_parle,
          OREBQ8_Q,
          EmploymentConflict,
        } = data.patientDetails;
        let color = 'red';
        const value = Number(OREBQ8_Q);
        if (
          [Employment_pwork, Employment_sleav].filter(
            (e) => e === 'Y',
          ).length >= 1
        ) {
          if (value <= 2) {
            color = 'green';
          } else if (value >= 3 && value <= 4) {
            if (EmploymentConflict === 'Y') {
              color = 'green';
            } else {
              color = 'yellow';
            }
          } else if (value >= 5 && value <= 10) {
            if (EmploymentConflict === 'Y') {
              color = 'green';
            } else {
              color = 'red';
            }
          }
        }

        if (
          [
            Employment_stud,
            Employment_retir,
            Employment_disab,
            Employment_wasse,
            Employment_unpwo,
            Employment_unemp,
            Employment_parle,
          ].filter((e) => e === 'Y').length >= 1
        ) {
          color = 'gray';
        }

        return [
          { value: 1, color },
          { value: 2, color },
          { value: 3, color },
          { value: 4, color },
          { value: 5, color },
        ];
      },
      hoverText: [
        'Dialog med arbeidsgiver kan fasiliteres tidlig i sykmeldingsforløpet, feks ved bruk av avventende sykmelding i arbeidsgiverperioden med beskjed til arbeidsgiver om dialog og tilrettelegging. Dialog med arbeidsgiver kan også fasiliteres fra NAV dersom det feks er konflikt eller behov for dette - dette kan man skrive under "beskjed til NAV" i sykmeldingsblanketten',
      ],
      data: [],
      values: [],
    },
    {
      id: 'TreatRevWork',
      title: 'Plan for tilbakeføring til arbeid',
      phenotype: (data: PatientInfoResponse) => {
        const {
          Employment_pwork,
          Employment_sleav,
          Employment_stud,
          Employment_retir,
          Employment_disab,
          Employment_wasse,
          Employment_unpwo,
          Employment_unemp,
          Employment_parle,
          OREBQ8_Q,
          EmploymentConflict,
        } = data.patientDetails;
        let color = 'red';
        const value = Number(OREBQ8_Q);
        if (
          [Employment_pwork, Employment_sleav].filter(
            (e) => e === 'Y',
          ).length >= 1
        ) {
          if (value <= 2) {
            color = 'green';
          } else if (value >= 3 && value <= 4) {
            if (EmploymentConflict === 'Y') {
              color = 'green';
            } else {
              color = 'yellow';
            }
          } else if (value >= 5 && value <= 10) {
            if (EmploymentConflict === 'Y') {
              color = 'yellow';
            } else {
              color = 'red';
            }
          }
        }

        if (
          [
            Employment_stud,
            Employment_retir,
            Employment_disab,
            Employment_wasse,
            Employment_unpwo,
            Employment_unemp,
            Employment_parle,
          ].filter((e) => e === 'Y').length >= 1
        ) {
          color = 'gray';
        }

        return [
          { value: 1, color },
          { value: 2, color },
          { value: 3, color },
          { value: 4, color },
          { value: 5, color },
        ];
      },
      hoverText: [
        'Hva skal pasienten gjøre til neste time? Utarbeid en plan sammen med pasienten for å fremme aktivitet, og gi pasienten oppgaver mellom konsultasjonene. Skal pasienten til medisinsk behandling eller utredning? Er det andre som skal involveres-arbeidsgiver, bedriftshelsetjenesten eller NAV)',
      ],
      data: [],
      values: [
        // {
        //   id: 'TreatRevWork',
        //   title: 'Behov for koordinering',
        //   phenotype: (data: PatientInfoResponse) => {
        //     let color = 'red';
        //     const {
        //       Employment_pwork,
        //       Employment_sleav,
        //       Employment_stud,
        //       Employment_retir,
        //       Employment_disab,
        //       Employment_wasse,
        //       Employment_unpwo,
        //       Employment_unemp,
        //       Employment_parle,
        //       OREBQ8_Q,
        //       EmploymentConflict,
        //     } = data.patientDetails;
        //     if (
        //       [Employment_pwork, Employment_sleav].filter(
        //         (e) => e === 'Y',
        //       ).length >= 1
        //     ) {
        //       if (value <= 2) {
        //         color = 'green';
        //       }
        //       if (value >= 3 && value <= 4) {
        //         if (EmploymentConflict === 'Y') {
        //           color = 'green';
        //         } else {
        //           color = 'yellow';
        //         }
        //       }
        //       if (value >= 5 && value <= 10) {
        //         if (EmploymentConflict === 'Y') {
        //           color = 'yellow';
        //         } else {
        //           color = 'red';
        //         }
        //       }
        //     }
        //     if (
        //       [
        //         Employment_stud,
        //         Employment_retir,
        //         Employment_disab,
        //         Employment_wasse,
        //         Employment_unpwo,
        //         Employment_unemp,
        //         Employment_parle,
        //       ].filter((e) => e === 'Y').length >= 1
        //     ) {
        //       color = 'gray';
        //     }
        //     return [
        //       { value: 1, color },
        //       { value: 2, color },
        //       { value: 3, color },
        //       { value: 4, color },
        //       { value: 5, color },
        //     ];
        //   },
        //   hoverText: [
        //     'Trenger pasienten hjelp til koordinering? Evt hvem har ansvaret?',
        //   ],
        // },
        // {
        //   id: 'TreatRevWorkNeedCoord',
        //   title: 'Oppfølging over tid',
        //   phenotype: (data: PatientInfoResponse) => {
        //     let color = 'red';
        //     const {
        //       Employment_pwork,
        //       Employment_sleav,
        //       Employment_stud,
        //       Employment_retir,
        //       Employment_disab,
        //       Employment_wasse,
        //       Employment_unpwo,
        //       Employment_unemp,
        //       Employment_parle,
        //       OREBQ8_Q,
        //       EmploymentConflict,
        //     } = data.patientDetails;
        //     if (
        //       [Employment_pwork, Employment_sleav].filter(
        //         (e) => e === 'Y',
        //       ).length >= 1
        //     ) {
        //       if (value <= 2) {
        //         color = 'green';
        //       }
        //       if (value >= 3 && value <= 4) {
        //         if (EmploymentConflict === 'Y') {
        //           color = 'green';
        //         } else {
        //           color = 'yellow';
        //         }
        //       }
        //       if (value >= 5 && value <= 10) {
        //         if (EmploymentConflict === 'Y') {
        //           color = 'yellow';
        //         } else {
        //           color = 'red';
        //         }
        //       }
        //     }
        //     if (
        //       [
        //         Employment_stud,
        //         Employment_retir,
        //         Employment_disab,
        //         Employment_wasse,
        //         Employment_unpwo,
        //         Employment_unemp,
        //         Employment_parle,
        //       ].filter((e) => e === 'Y').length >= 1
        //     ) {
        //       color = 'gray';
        //     }
        //     return [
        //       { value: 1, color },
        //       { value: 2, color },
        //       { value: 3, color },
        //       { value: 4, color },
        //       { value: 5, color },
        //     ];
        //   },
        //   hoverText: [
        //     'Trenger pasienten en plan for oppfølging over tid?',
        //   ],
        // },
      ],
    },
    {
      id: 'TreatFunctAssess',
      title: 'Funksjonsvurdering',
      phenotype: (data: PatientInfoResponse) => {
        const {
          Employment_pwork,
          Employment_sleav,
          Employment_stud,
          Employment_retir,
          Employment_disab,
          Employment_wasse,
          Employment_unpwo,
          Employment_unemp,
          Employment_parle,
          OREBQ8_Q,
        } = data.patientDetails;
        let color = 'red';
        const value = Number(OREBQ8_Q);
        if (
          [Employment_pwork, Employment_sleav].filter(
            (e) => e === 'Y',
          ).length >= 1
        ) {
          if (value <= 2) {
            color = 'yellow';
          } else if (value >= 3 && value <= 4) {
            color = 'red';
          } else if (value >= 5 && value <= 10) {
            color = 'red';
          }
        }

        if (
          [
            Employment_stud,
            Employment_retir,
            Employment_disab,
            Employment_wasse,
            Employment_unpwo,
            Employment_unemp,
            Employment_parle,
          ].filter((e) => e === 'Y').length >= 1
        ) {
          color = 'gray';
        }

        return [
          { value: 1, color },
          { value: 2, color },
          { value: 3, color },
          { value: 4, color },
          { value: 5, color },
        ];
      },
      linkTitle: 'Se sjekkliste',
      link: [
        '<div class="font-bold">NAVs 9 punkts sjekkliste for funksjonsvurdering til bruk i allmennpraksis (sammendrag)</div>',
        '1. <span class="font-bold">BIOPSYKOSOSIAL</span>: Vurder fysisk, psykisk (kognitiv og emosjonell) og sosial fungering i forhold til vanlige situasjoner i dagligliv og arbeidsliv. Bruk pasientprofilen i SupportPrim dashboardet!',
        '2.	<span class="font-bold">PLUSS og MINUS</span>: Beskriv ressurser og begrensninger. Innta et rehabiliteringsperspektiv og beskriv hva pasienten klarer og ikke klarer.',
        '3.	<span class="font-bold">TIDSPROFIL</span>: Få frem svingninger i funksjon med topp, bunn og gjennomsnittsfungering. Få frem trend over tid (er funksjonen økende, stabil eller fallende)?',
        '4.	<span class="font-bold">TEMPO</span>: Er det behov for mer tid enn før til å gjennomføre vante arbeidsoppgaver (fysiske og/eller kognitive)?',
        '5.	<span class="font-bold">TRIGGERE</span>: Beskrives det situasjonsbestemte utfordringer (for eksempel forskjell i fungering på jobb, hjemme eller på fritiden)? Foreligger det utløsende faktorer (for eksempel angstanfall som utløses på bussen på vei til jobb eller astmaanfall som utløses av parfyme). Dette kan være viktige punkt å rette behandling og tiltak mot. Det er også viktig å identifisere faktorer som trigger bedring i funksjonsevne.',
        '6.	<span class="font-bold">OBSERVASJONSGRUNNLAGET</span> til den som beskriver funksjonsevnen bør anføres. Hva bygger vurderingen på (konsultasjon/klinisk undersøkelse, kjennskap til pasienten og observasjoner over tid som pasientens fastlege?)?',
        '7.	<span class="font-bold">PASIENTENS VURDERING</span> av eget funksjonsnivået hører med. Presiser i erklæringen at dette er pasientens selvrapport. Hjelp pasienten til å frem opplysninger om de øvrige punktene.',
        '8.	<span class="font-bold">Din selvstendige VURDERING</span> som helsepersonell er viktig. Bruk din fagkompetanse, bygg på dine undersøkelser og kjennskap til pasienten og gi begrunnede konklusjoner. SKILL dette fra individets EGENRAPPORT.',
        '9.	Si til slutt noe om <span class="font-bold">sammenhengen mellom sykdom, funksjon og arbeidsevne</span>. Er det årsakssammenheng?  Hva består uførheten i?',
      ],
      hoverText: ['9 punkts liste, se vedlegg'],
      data: [],
      values: [],
    },
  ],
};

export const TREATMENT_REFERRAL: TreatmentCard = {
  title: 'Henvisninger',
  prognoses: [
    {
      rule: (data: PatientDetails) => {
        return ['Oversikt behandling og effekt siste år'];
      },
      hoverText: (data: PatientDetails) => {
        let text = [''];
        const value = data['Treat12m1'];

        if (value === 'Y') {
          // text = [
          //   'Har ikke vært hos andre behandlere for nåværende plager',
          // ];
          text = treat12Text.values
            .map((t) => {
              const key = t.value as keyof PatientDetails;
              const isValid = ['bette', 'notbe', 'unsur'].includes(
                String(data[key]),
              );

              const conclusion = [
                { value: 'bette', text: 'Ført til bedring' },
                { value: 'notbe', text: 'Ikke ført til bedring' },
                {
                  value: 'unsur',
                  text: 'Vet ikke om det har ført til bedring',
                },
              ].find((c) => c.value === data[key]);
              return isValid ? `${t.text} - ${conclusion?.text}` : '';
            })
            .filter((t) => t);
        }

        if (value === 'N') {
          text = [
            'Har ikke vært hos andre behandlere for nåværende plager',
          ];
        }
        return text;
      },
    },
  ],
  variables: [
    {
      title: 'Bildediagnostikk',
      hoverText: [
        'Unngå bildediagnostikk ved ikke-traumatisk muskel- og skjelettsmerter uten mistanke om underliggende sykdom. Eventuell henvisning bør ha direkte behandlingsmessig konsekvens',
      ],
      data: [],
      values: [
        {
          id: 'TreatRefImagXray',
          title: 'Røntgen',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'red' },
            { value: 5, color: 'red' },
          ],
          hoverText: [],
        },
        {
          id: 'TreatRefImagMrCt',
          title: 'MR/CT',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'red' },
            { value: 5, color: 'red' },
          ],
          hoverText: [],
        },
        {
          id: 'TreatRefImagOth',
          title: 'Andre',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'red' },
            { value: 5, color: 'red' },
          ],
          hoverText: [],
        },
      ],
    },
    {
      title: 'Spesialisthelsetjenesten',
      data: [],
      values: [
        {
          id: 'TreatRefSpeciSpeci',
          title: 'Spesialist',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'green' },
          ],
          hoverText: [
            'Ikke henvis pasienter til vurdering for invasiv behandling hvor konservative tiltak er vist like nyttige (f.eks subakromielle plager og degenerative menisklidelser',
            'Unngå å henvise til undersøkelser og behandling som dekkes av private helseforsikringer uten god faglig indikasjon',
            'Unngå å henvise pasienter med lavere symptomtrykk (fenotype 1-3) som ikke har prøvd ut tilstrekkelige konservative tiltak i primærhelsetjenesten',
          ],
        },
        {
          id: 'TreatRefSpeciIntdis',
          title: 'Tverrfaglig poliklinikk/smerteklinikk',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'green' },
          ],
          hoverText: [],
        },
        {
          id: 'TreatRefSpeciRehab',
          title: 'Rehabilitering',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'green' },
          ],
          hoverText: [],
        },
      ],
    },
    {
      title: 'Andre',
      data: [],
      values: [
        {
          id: 'TreatRefPsych',
          title: 'Psykolog',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'red' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'green' },
          ],
          hoverText: [],
        },
        {
          id: 'TreatRefWellCenter',
          title: 'Frisklivssentral',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [],
        },
        {
          id: 'TreatRefOthPrimCar',
          title: 'Andre i primærhelsetjenesten',
          phenotype: () => [
            { value: 1, color: 'red' },
            { value: 2, color: 'red' },
            { value: 3, color: 'yellow' },
            { value: 4, color: 'yellow' },
            { value: 5, color: 'yellow' },
          ],
          hoverText: [
            'Frisklivssentralen i kommunen kan ha et godt tilbud til pasienter med muskel- og skjelettplager. Sørg for å ha god oversikt over hvilke tilbud de har.',
            'Psykiatrisk sykepleiere i kommunen kan være aktuell samarbeidspartnere for fenotype 4 og 5.',
          ],
        },
      ],
    },
  ],
};

// Treatment variables
export const TREATMENT_VARIABLES = [
  // Advice
  ...TREATMENT_ADVICE.variables.map((t) =>
    t?.values.map((v) => v.id),
  ),
  ...TREATMENT_ADVICE.variables.map((t) => t.data.map((v) => v.id)),
  ...TREATMENT_ADVICE.variables.map((t) => t.id),
  // Medication
  ...TREATMENT_MEDICATION.variables.map((t) =>
    t?.values.map((v) => v.id),
  ),
  ...TREATMENT_MEDICATION.variables.map((t) => t.id),
  // Work
  ...TREATMENT_WORK.variables.map((t) => t?.values.map((v) => v.id)),
  ...TREATMENT_WORK.variables.map((t) => t.id),
  // REFERRAL
  ...TREATMENT_REFERRAL.variables.map((t) =>
    t?.values.map((v) => v.id),
  ),
  ...TREATMENT_REFERRAL.variables.map((t) => t.id),
].flat();
