export const hipMovePassivePainText = {
  values: [
    { value: 'HipMovePassivePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'HipMovePassivePain_ext', text: 'Ekstensjon', score: 0 },
    { value: 'HipMovePassivePain_abd', text: 'Abduksjon', score: 0 },
    { value: 'HipMovePassivePain_add', text: 'Adduksjon', score: 0 },
    {
      value: 'HipMovePassivePain_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePassivePain_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePassivePain_not ',
      text: 'Ingen smerter ved passive bevegelser',
      score: 0,
    },
  ],
};
