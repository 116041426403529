import { PatientInfoResponse } from 'interfaces';

export const patientConvert = (data: PatientInfoResponse) => {
  if (!['dummypatient', 'dummypatient2'].includes(data.id)) {
    return data;
  }

  const { Age, DemBMI, ...rest } = data.patientDetails;

  return {
    ...data,
    patientDetails: {
      ...rest,
      Age: String(Number(Age).toFixed(0)),
      DemBMI: String(Number(DemBMI).toFixed(0)),
    },
  };
};
