import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';

import { FormButton, FormError } from 'components/Form';
import {
  ErrorMessage,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { getMappedValue } from 'helpers';
import { bodyMainText } from 'helpers/bodyMain';
import { diagnosisText } from 'helpers/diagnosis';
import { useRouter } from 'hooks';
import {
  Baseline as IBaseline,
  PatientInfoResponse,
} from 'interfaces';
import React, { useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { hasTestPermission } from 'utils';
import {
  Back,
  Diagnose,
  Hip,
  ICPC,
  Knee,
  Neck,
  Shoulder,
  Widespread,
} from '.';

interface BaselineProps extends RouteProps {
  data: PatientInfoResponse;
}

const baselineSchema = Yup.object().shape({
  Icpc_Letter: Yup.string()
    .min(1, 'For kort!')
    .max(1, 'For langt!')
    .typeError('Bare en bokstav er tillatt i dette feltet')
    .required('Obligatorisk')
    .matches(
      /^[aA-zZ\s]+$/,
      'Bare en bokstav er tillatt i dette feltet',
    ),
  Icpc_Number: Yup.number()
    .positive()
    .integer()
    .typeError('Bare tall er tillatt i dette feltet')
    .required('Obligatorisk')
    .min(1, 'For kort!')
    .max(99, 'For langt!'),
});

export const Baseline: React.FC<BaselineProps> = (props) => {
  const { data } = props;
  const [bodyPartIndex, setBodyPartIndex] = useState<number>(
    getMappedValue(
      diagnosisText.values,
      data.patientDetails.Diagnosis,
    ).score - 1,
  );

  const Component = [Neck, Shoulder, Back, Hip, Knee, Widespread][
    bodyPartIndex
  ];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { patientDetails } = data;
  const {
    Icpc_Letter,
    Icpc_Number,
    Icps_Diagnosis,
    Diagnosis,
    BodyMain,
    BodyMainPreviuos,
    BodyMainSick,
    BodyMainTrauma,
    BackMove,
    BackMoveRom_flex,
    BackMoveRom_ext,
    BackMoveRom_rot,
    BackMoveRom_lat,
    BackMoveRom_not,
    BackMovePain_flex,
    BackMovePain_ext,
    BackMovePain_rot,
    BackMovePain_lat,
    BackMovePain_not,
    BackSlr,
    BackNeuro_nota,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
    BackComment,
    NeckComment,
    NeckConclusion,
    NeckNevro_nota,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckSpurling,
    NeckMovePain_flex,
    NeckMovePain_ext,
    NeckMovePain_rot,
    NeckMovePain_lat,
    NeckMovePain_not,
    NeckMoveRom_flex,
    NeckMoveRom_ext,
    NeckMoveRom_rot,
    NeckMoveRom_lat,
    NeckMoveRom_not,
    NeckMove,
    ShoulderComment,
    ShoulderPassiveExtrot,
    ShoulderPainfularc,
    ShoulderHawkins,
    ShoulderIsoAbdPain,
    ShoulderIsoExtrotPain,
    ShoulderIsoExtrotPow,
    ShoulderIsoAbdPow,
    ShoulderMovePain_flex,
    ShoulderMovePain_ext,
    ShoulderMovePain_abd,
    ShoulderMovePain_add,
    ShoulderMovePain_extrot,
    ShoulderMovePain_introt,
    ShoulderMovePain_not,
    ShoulderMoveRom_flex,
    ShoulderMoveRom_ext,
    ShoulderMoveRom_abd,
    ShoulderMoveRom_add,
    ShoulderMoveRom_extrot,
    ShoulderMoveRom_introt,
    ShoulderMoveRom_not,
    ShoulderMove,
    KneeMove,
    KneeMovePassive,
    KneeHydrops,
    KneeTraumatic,
    KneeComment,
    KneeMoveRom_flex,
    KneeMoveRom_ext,
    KneeMoveRom_not,
    KneeMovePain_flex,
    KneeMovePain_ext,
    KneeMovePain_not,
    KneeMovePassiveRom_flex,
    KneeMovePassiveRom_ext,
    KneeMovePassiveRom_not,
    KneeMovePassivePain_flex,
    KneeMovePassivePain_ext,
    KneeMovePassivePain_not,
    HipMove,
    HipMovePassive,
    HipComment,
    HipMoveRom_flex,
    HipMoveRom_ext,
    HipMoveRom_abd,
    HipMoveRom_add,
    HipMoveRom_extrot,
    HipMoveRom_introt,
    HipMoveRom_not,
    HipMovePain_flex,
    HipMovePain_ext,
    HipMovePain_abd,
    HipMovePain_add,
    HipMovePain_extrot,
    HipMovePain_introt,
    HipMovePassiveRom_flex,
    HipMovePassiveRom_ext,
    HipMovePassiveRom_abd,
    HipMovePassiveRom_add,
    HipMovePassiveRom_extrot,
    HipMovePassiveRom_introt,
    HipMovePassiveRom_not,
    HipMovePassivePain_flex,
    HipMovePassivePain_ext,
    HipMovePassivePain_abd,
    HipMovePassivePain_add,
    HipMovePassivePain_extrot,
    HipMovePassivePain_introt,
    HipMovePassivePain_not,
    HipPain_nota,
    HipPain_lat,
    HipPain_med,
    HipPain_post,
    HipPain_ant,
    HipMovePain_not,
  } = patientDetails;

  const initial = {
    Icpc_Letter,
    Icpc_Number,
    Icps_Diagnosis,
    Diagnosis,
    BodyMain,
    BodyMainPreviuos,
    BodyMainSick,
    BodyMainTrauma,
    BackMove,
    BackMoveRom_flex,
    BackMoveRom_ext,
    BackMoveRom_rot,
    BackMoveRom_lat,
    BackMoveRom_not,
    BackMovePain_flex,
    BackMovePain_ext,
    BackMovePain_rot,
    BackMovePain_lat,
    BackMovePain_not,
    BackSlr,
    BackNeuro_nota,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
    BackComment,
    NeckComment,
    NeckConclusion,
    NeckNevro_nota,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckSpurling,
    NeckMovePain_flex,
    NeckMovePain_ext,
    NeckMovePain_rot,
    NeckMovePain_lat,
    NeckMovePain_not,
    NeckMoveRom_flex,
    NeckMoveRom_ext,
    NeckMoveRom_rot,
    NeckMoveRom_lat,
    NeckMoveRom_not,
    NeckMove,
    ShoulderComment,
    ShoulderPassiveExtrot,
    ShoulderPainfularc,
    ShoulderHawkins,
    ShoulderIsoAbdPain,
    ShoulderIsoExtrotPain,
    ShoulderIsoExtrotPow,
    ShoulderIsoAbdPow,
    ShoulderMovePain_flex,
    ShoulderMovePain_ext,
    ShoulderMovePain_abd,
    ShoulderMovePain_add,
    ShoulderMovePain_extrot,
    ShoulderMovePain_introt,
    ShoulderMovePain_not,
    ShoulderMoveRom_flex,
    ShoulderMoveRom_ext,
    ShoulderMoveRom_abd,
    ShoulderMoveRom_add,
    ShoulderMoveRom_extrot,
    ShoulderMoveRom_introt,
    ShoulderMoveRom_not,
    ShoulderMove,
    KneeMove,
    KneeMovePassive,
    KneeHydrops,
    KneeTraumatic,
    KneeComment,
    KneeMoveRom_flex,
    KneeMoveRom_ext,
    KneeMoveRom_not,
    KneeMovePain_flex,
    KneeMovePain_ext,
    KneeMovePain_not,
    KneeMovePassiveRom_flex,
    KneeMovePassiveRom_ext,
    KneeMovePassiveRom_not,
    KneeMovePassivePain_flex,
    KneeMovePassivePain_ext,
    KneeMovePassivePain_not,
    HipMove,
    HipMovePassive,
    HipComment,
    HipMoveRom_flex,
    HipMoveRom_ext,
    HipMoveRom_abd,
    HipMoveRom_add,
    HipMoveRom_extrot,
    HipMoveRom_introt,
    HipMoveRom_not,
    HipMovePain_flex,
    HipMovePain_ext,
    HipMovePain_abd,
    HipMovePain_add,
    HipMovePain_extrot,
    HipMovePain_introt,
    HipMovePassiveRom_flex,
    HipMovePassiveRom_ext,
    HipMovePassiveRom_abd,
    HipMovePassiveRom_add,
    HipMovePassiveRom_extrot,
    HipMovePassiveRom_introt,
    HipMovePassiveRom_not,
    HipMovePassivePain_flex,
    HipMovePassivePain_ext,
    HipMovePassivePain_abd,
    HipMovePassivePain_add,
    HipMovePassivePain_extrot,
    HipMovePassivePain_introt,
    HipMovePassivePain_not,
    HipPain_nota,
    HipPain_lat,
    HipPain_med,
    HipPain_post,
    HipPain_ant,
    HipMovePain_not,
  } as const;

  const [values, setValues] = useState<typeof initial | null>(null);

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Undersøkelse`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  useEffect(() => {
    if (patientDetails) {
      const {
        Icpc_Letter,
        Icpc_Number,
        Icps_Diagnosis,
        Diagnosis,
        BodyMain,
        BodyMainPreviuos,
        BodyMainSick,
        BodyMainTrauma,
        BackMove,
        BackMoveRom_flex,
        BackMoveRom_ext,
        BackMoveRom_rot,
        BackMoveRom_lat,
        BackMoveRom_not,
        BackMovePain_flex,
        BackMovePain_ext,
        BackMovePain_rot,
        BackMovePain_lat,
        BackMovePain_not,
        BackSlr,
        BackNeuro_nota,
        BackNeuro_pow,
        BackNeuro_sens,
        BackNeuro_reflex,
        BackComment,
        NeckComment,
        NeckConclusion,
        NeckNevro_nota,
        NeckNevro_pow,
        NeckNevro_sens,
        NeckNevro_reflex,
        NeckSpurling,
        NeckMovePain_flex,
        NeckMovePain_ext,
        NeckMovePain_rot,
        NeckMovePain_lat,
        NeckMovePain_not,
        NeckMoveRom_flex,
        NeckMoveRom_ext,
        NeckMoveRom_rot,
        NeckMoveRom_lat,
        NeckMoveRom_not,
        NeckMove,
        ShoulderComment,
        ShoulderPassiveExtrot,
        ShoulderPainfularc,
        ShoulderHawkins,
        ShoulderIsoAbdPain,
        ShoulderIsoExtrotPain,
        ShoulderIsoExtrotPow,
        ShoulderIsoAbdPow,
        ShoulderMovePain_flex,
        ShoulderMovePain_ext,
        ShoulderMovePain_abd,
        ShoulderMovePain_add,
        ShoulderMovePain_extrot,
        ShoulderMovePain_introt,
        ShoulderMovePain_not,
        ShoulderMoveRom_flex,
        ShoulderMoveRom_ext,
        ShoulderMoveRom_abd,
        ShoulderMoveRom_add,
        ShoulderMoveRom_extrot,
        ShoulderMoveRom_introt,
        ShoulderMoveRom_not,
        ShoulderMove,
        KneeMove,
        KneeMovePassive,
        KneeHydrops,
        KneeTraumatic,
        KneeComment,
        KneeMoveRom_flex,
        KneeMoveRom_ext,
        KneeMoveRom_not,
        KneeMovePain_flex,
        KneeMovePain_ext,
        KneeMovePain_not,
        KneeMovePassiveRom_flex,
        KneeMovePassiveRom_ext,
        KneeMovePassiveRom_not,
        KneeMovePassivePain_flex,
        KneeMovePassivePain_ext,
        KneeMovePassivePain_not,
        HipMove,
        HipMovePassive,
        HipComment,
        HipMoveRom_flex,
        HipMoveRom_ext,
        HipMoveRom_abd,
        HipMoveRom_add,
        HipMoveRom_extrot,
        HipMoveRom_introt,
        HipMoveRom_not,
        HipMovePain_flex,
        HipMovePain_ext,
        HipMovePain_abd,
        HipMovePain_add,
        HipMovePain_extrot,
        HipMovePain_introt,
        HipMovePassiveRom_flex,
        HipMovePassiveRom_ext,
        HipMovePassiveRom_abd,
        HipMovePassiveRom_add,
        HipMovePassiveRom_extrot,
        HipMovePassiveRom_introt,
        HipMovePassiveRom_not,
        HipMovePassivePain_flex,
        HipMovePassivePain_ext,
        HipMovePassivePain_abd,
        HipMovePassivePain_add,
        HipMovePassivePain_extrot,
        HipMovePassivePain_introt,
        HipMovePassivePain_not,
        HipPain_nota,
        HipPain_lat,
        HipPain_med,
        HipPain_post,
        HipPain_ant,
        HipMovePain_not,
      } = patientDetails;

      const mapped_diagnosis = getMappedValue(
        diagnosisText.values,
        patientDetails.Diagnosis,
      );
      setValues({
        Icpc_Letter,
        Icpc_Number,
        Icps_Diagnosis,
        Diagnosis,
        BodyMain,
        BodyMainPreviuos,
        BodyMainSick,
        BodyMainTrauma,
        BackMove,
        BackMoveRom_flex,
        BackMoveRom_ext,
        BackMoveRom_rot,
        BackMoveRom_lat,
        BackMoveRom_not,
        BackMovePain_flex,
        BackMovePain_ext,
        BackMovePain_rot,
        BackMovePain_lat,
        BackMovePain_not,
        BackSlr,
        BackNeuro_nota,
        BackNeuro_pow,
        BackNeuro_sens,
        BackNeuro_reflex,
        BackComment,
        NeckComment,
        NeckConclusion,
        NeckNevro_nota,
        NeckNevro_pow,
        NeckNevro_sens,
        NeckNevro_reflex,
        NeckSpurling,
        NeckMovePain_flex,
        NeckMovePain_ext,
        NeckMovePain_rot,
        NeckMovePain_lat,
        NeckMovePain_not,
        NeckMoveRom_flex,
        NeckMoveRom_ext,
        NeckMoveRom_rot,
        NeckMoveRom_lat,
        NeckMoveRom_not,
        NeckMove,
        ShoulderComment,
        ShoulderPassiveExtrot,
        ShoulderPainfularc,
        ShoulderHawkins,
        ShoulderIsoAbdPain,
        ShoulderIsoExtrotPain,
        ShoulderIsoExtrotPow,
        ShoulderIsoAbdPow,
        ShoulderMovePain_flex,
        ShoulderMovePain_ext,
        ShoulderMovePain_abd,
        ShoulderMovePain_add,
        ShoulderMovePain_extrot,
        ShoulderMovePain_introt,
        ShoulderMovePain_not,
        ShoulderMoveRom_flex,
        ShoulderMoveRom_ext,
        ShoulderMoveRom_abd,
        ShoulderMoveRom_add,
        ShoulderMoveRom_extrot,
        ShoulderMoveRom_introt,
        ShoulderMoveRom_not,
        ShoulderMove,
        KneeMove,
        KneeMovePassive,
        KneeHydrops,
        KneeTraumatic,
        KneeComment,
        KneeMoveRom_flex,
        KneeMoveRom_ext,
        KneeMoveRom_not,
        KneeMovePain_flex,
        KneeMovePain_ext,
        KneeMovePain_not,
        KneeMovePassiveRom_flex,
        KneeMovePassiveRom_ext,
        KneeMovePassiveRom_not,
        KneeMovePassivePain_flex,
        KneeMovePassivePain_ext,
        KneeMovePassivePain_not,
        HipMove,
        HipMovePassive,
        HipComment,
        HipMoveRom_flex,
        HipMoveRom_ext,
        HipMoveRom_abd,
        HipMoveRom_add,
        HipMoveRom_extrot,
        HipMoveRom_introt,
        HipMoveRom_not,
        HipMovePain_flex,
        HipMovePain_ext,
        HipMovePain_abd,
        HipMovePain_add,
        HipMovePain_extrot,
        HipMovePain_introt,
        HipMovePassiveRom_flex,
        HipMovePassiveRom_ext,
        HipMovePassiveRom_abd,
        HipMovePassiveRom_add,
        HipMovePassiveRom_extrot,
        HipMovePassiveRom_introt,
        HipMovePassiveRom_not,
        HipMovePassivePain_flex,
        HipMovePassivePain_ext,
        HipMovePassivePain_abd,
        HipMovePassivePain_add,
        HipMovePassivePain_extrot,
        HipMovePassivePain_introt,
        HipMovePassivePain_not,
        HipPain_nota,
        HipPain_lat,
        HipPain_med,
        HipPain_post,
        HipPain_ant,
        HipMovePain_not,
      });
      setBodyPartIndex(mapped_diagnosis.score - 1);
    }
  }, [props.location, patientDetails]);

  const handleSubmit = async (
    values: IBaseline,
    actions: FormikHelpers<IBaseline>,
  ) => {
    try {
      // Mandatory fields
      const mandatoryValues = Object.values({
        Diagnosis: values.Diagnosis,
        Icpc_Letter: values.Icpc_Letter,
        Icpc_Number: values.Icpc_Number,
      }).filter((v) => v);

      if (mandatoryValues.length < 3 && !hasTestPermission) {
        return alert('You must fill out ICPC and Diagnosis');
      }

      setLoading(true);
      const { setSubmitting } = actions;
      const token = localStorage.getItem('token') || '';
      if (
        window.confirm(
          'Er du sikker på at du vil lagre? Valgt diagnose kan ikke endres.',
        )
      ) {
        const formValues: { [key: string]: any } = values;

        for (const key in formValues) {
          const value = formValues[key];
          if (Array.isArray(value)) {
            formValues[key] = value.pop();
          }
        }

        const updatedValues = {
          ...data,
          status: 'BASELINE',
          clinicianId: data.patientDetails.clinicianId,
          patientDetails: {
            ...data.patientDetails,
            ...formValues,
            Phenotype: String(data.patientDetails.Phenotype),
            ExaDate: new Date(),
            consultationDate: new Date(),
          },
          consultationDate: new Date(),
        };

        // Get patient
        const body = JSON.stringify(updatedValues);
        // Save new info to patient
        await fetch(
          `${process.env.REACT_APP_URL_CBR}/patient/savePatient`,
          {
            method: 'POST',
            body,
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          },
        );

        // Post consultation date
        await fetch(
          `${process.env.REACT_APP_URL_ONBOARDING}/consultation/consultationDate`,
          {
            method: 'POST',
            body: JSON.stringify({
              patientId: updatedValues.patientId,
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          },
        );

        setLoading(false);
        setSubmitting(false);
        await window.location.reload();
      }
      await setLoading(false);
      await setSubmitting(false);
    } catch (err: any) {
      setLoading(false);
      setError(err);
      console.log('BaselineError:', err.message);
    }
  };

  const canSave = () => {
    const mandatoryValues = Object.values({
      Diagnosis,
      Icpc_Letter,
      Icpc_Number,
    }).filter((v) => v);
    return mandatoryValues.length >= 3;
  };

  const handleDiagnoseChange = async (
    index: number,
    props: FormikProps<IBaseline>,
  ) => {
    try {
      const previousValues = props.values;
      setBodyPartIndex(index);
      props.resetForm();
      // Keep track of icp values
      props.setFieldValue(
        'Diagnosis',
        bodyMainText.values[index].value,
      );
      props.setFieldValue('Icpc_Letter', previousValues.Icpc_Letter);
      props.setFieldValue('Icpc_Number', previousValues.Icpc_Number);
      props.setFieldValue(
        'Icps_Diagnosis',
        previousValues.Icps_Diagnosis,
      );
    } catch (err: any) {
      console.log('handleDiagnoseChange', err);
    }
  };

  if (!values) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`SupportPrim - Undersøkelse`}</title>
      </Helmet>

      <Formik
        initialValues={values}
        onSubmit={(
          values: IBaseline,
          actions: FormikHelpers<IBaseline>,
        ) => {
          handleSubmit(values, actions);
        }}
        validationSchema={baselineSchema}
        enableReinitialize={true}
      >
        {(props: FormikProps<IBaseline>) => {
          return (
            <Form className="space-y-6">
              <div className="flex flex-col max-w-screen-md">
                <Diagnose
                  index={bodyPartIndex}
                  onChange={(index) =>
                    handleDiagnoseChange(index, props)
                  }
                />

                {Component && (
                  <Component
                    values={{
                      ...props.values,
                    }}
                  />
                )}

                <ICPC data={data} />
                <div className="flex">
                  <div className="text-red-500 text-sm">
                    <ErrorMessage
                      name="Diagnosis"
                      render={(msg) => <div>* {msg}</div>}
                    />
                  </div>
                  {canSave() ? (
                    hasTestPermission() && (
                      <FormButton
                        className="ml-auto w-32"
                        loading={loading}
                        title="Lagre"
                      />
                    )
                  ) : (
                    <FormButton
                      className="ml-auto w-32"
                      loading={loading}
                      title="Lagre"
                    />
                  )}
                </div>
              </div>

              <FormError error={error} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
