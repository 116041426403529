export const orebq2Text = {
  label: {
    en: 'Pain last week',
    no: 'Smerter siste uke',
  },
  hover: {
    en: '',
    no: 'Hvor mye smerter har du hatt den SISTE UKEN? 0 = Ingen smerte, 10= Verst tenkelig smerte. Pasientsvar: "actual score"',
  },
  values: [],
};
