export const redFlagsText = {
  values: [
    {
      value: 'RedFlag_nigsw',
      text: 'Nattesvette',
      score: 0,
      // value: 'night pain',
    },
    {
      value: 'RedFlag_cance',
      text: 'Tidligere kreftdiagnose',
      score: 0,
      // value: 'former cancer',
    },
    {
      value: 'RedFlag_wloss',
      text: 'Uforklarlig vekttap / feber',
      score: 0,
      // value: 'weight loss or poor appetite',
    },
    {
      value: 'RedFlag_urin',
      text: 'Urin- og avføringsproblemer',
      score: 0,
      // value: 'problem with urination or sexual function',
    },
  ],
};
