import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { jsPDF } from 'jspdf';
import clsx from 'clsx';

import { useRouter } from 'hooks';
import { Modal } from 'components/Modal';
import { CopyText } from 'components/CopyText';
import {
  PatientInfoResponse,
  TreatmentJournalText,
} from 'interfaces';
import { treatmentJournalLong } from 'helpers/treatmentJournalLong';
import { treatmentJournalShort } from 'helpers/treatmentJournalShort';
import { TreatmentJournalContent } from './TreatmentJournalContent';
import { FormButton } from 'components/Form';

interface TreatmentJournalProps {
  data: PatientInfoResponse;
}

export const TreatmentJournal: React.FC<TreatmentJournalProps> = ({
  data,
}) => {
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  // SPA Link Tracking
  const { trackPageView, enableLinkTracking, trackEvent } =
    useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Journal`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  const printDocument = async () => {
    try {
      const input = document.getElementById('print-journal-export');
      if (input) {
        setLoading(true);
        const pdf = new jsPDF('p', 'pt', 'a4', true);
        if (pdf) {
          // const imgData = await domtoimage.toPng(input);
          // const imgData = await toPng(input);
          // await pdf.addImage(imgData, 'PNG', 15, 20, 180, 160);
          // pdf.save('plan.pdf');
          pdf.html(input, {
            margin: 32,
            html2canvas: {
              scale: 0.65,
              svgRendering: true,
            },
            callback: (doc) => {
              doc.save(`journal-${new Date().getTime()}.pdf`);
            },
          });
        }

        trackEvent({
          name: 'Download treatment journal',
          category: 'export-page',
          action: 'click-event',
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const texts = [
    ...(index === 0 ? treatmentJournalLong(data) : []),
    ...(index === 1 ? treatmentJournalShort(data) : []),
  ] as TreatmentJournalText[];

  const text = texts.map((t) => {
    let section = '';
    t.mappings.forEach((m) => {
      section =
        section.length <= 0
          ? t.text.replace(m.id, m.value())
          : section.replace(m.id, m.value());
    });

    return {
      title: t.title,
      text: section.trim(),
    };
  });

  const handleClick = () => {
    navigator.clipboard.writeText(
      text
        .map((t) => {
          const output = [
            t.title ? `${t.title}: ` : '',
            t.text,
            '\n\n',
          ]
            .filter((t) => (t || '').length >= 1)
            .join('');

          return output;
        })
        .join(''),
    );
  };

  return (
    <div>
      <div
        className="inline-flex items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        Se journalnotat
      </div>
      <Modal
        open={isOpen}
        onClose={(s) => setIsOpen(s)}
        header={
          <div className="flex space-x-4">
            <div onClick={printDocument}>
              <FormButton
                loading={loading}
                className="inline-flex  items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center w-48 mb-8"
                title="Download PDF"
              />
            </div>
            <button
              className="w-48 items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center"
              onClick={handleClick}
            >
              Kopier
            </button>
          </div>
        }
      >
        <div className="flex">
          <div className="flex space-x-1 ml-auto">
            <div
              onClick={() => setIndex(0)}
              className={clsx(
                'text-center p-2 cursor-pointer rounded-md w-24',
                {
                  'bg-indigo-500 text-white': index === 0,
                  'hover:bg-indigo-500 hover:text-white': index !== 0,
                },
              )}
            >
              Lang
            </div>
            <div
              onClick={() => setIndex(1)}
              className={clsx(
                'text-center p-2 cursor-pointer rounded-md w-24',
                {
                  'bg-indigo-500 text-white': index === 1,
                  'hover:bg-indigo-500 hover:text-white': index !== 1,
                },
              )}
            >
              Kort
            </div>
          </div>
        </div>
        <div id="print-journal-export" className="p-8 max-w-4xl">
          <TreatmentJournalContent data={text} />
        </div>
      </Modal>
    </div>
  );
};
