export const orebq10qText = {
  label: {
    en: 'Fear avoidance',
    no: 'Bevegelsesfrykt',
  },
  hover: {
    en: '',
    no: 'Jeg bør ikke utføre mine normale aktiviteter eller arbeid med den smerten jeg har nå. 0= ikke enig, 10= helt enig. Pasientsvar: "actual score"',
  },
};
