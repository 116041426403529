import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
  CardAnswers,
} from 'components/Card';
import { replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import {
  activityText,
  getMappedValue,
  mskhqq15Text,
  mskhqq7Text,
} from 'helpers';

interface FunctionAndActivityProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const FunctionAndActivity: React.FC<
  FunctionAndActivityProps
> = ({ className, cardClassName, data }) => {
  const { patientDetails } = data;
  const { Activity, MSKHQQ7_Q, MSKHQQ15 } = patientDetails;

  // Mapped values
  const mappedActivity = getMappedValue(
    activityText.values,
    Activity,
  );
  const mappedMskhqq7 = getMappedValue(mskhqq7Text.values, MSKHQQ7_Q);
  const mappedMskhqq15 = getMappedValue(
    mskhqq15Text.values,
    MSKHQQ15,
  );

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text="Funksjon og aktivitet"
          size={CardLabelType.xl}
        />
        <HoverContent
          content={replaceScore(
            activityText.hover.no,
            mappedActivity.text,
          )}
        >
          <CopyText
            content={replaceScore(
              activityText.hover.no,
              mappedActivity.text,
            )}
          >
            <CardProgressBar
              text={activityText.label.no}
              progress={mappedActivity.score}
              parseProgressValue={(value) => (value - 1) * (100 / 3)}
              min={1}
              max={4}
              indicators={[2, 2]}
              disabled={variableChecker(Activity)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            mskhqq7Text.hover.no,
            mappedMskhqq7.text,
          )}
        >
          <CopyText
            content={replaceScore(
              mskhqq7Text.hover.no,
              mappedMskhqq7.text,
            )}
          >
            <CardProgressBar
              text={mskhqq7Text.label.no}
              progress={4 - mappedMskhqq7.score}
              parseProgressValue={(value) => value * (100 / 4)}
              min={0}
              max={4}
              indicators={[1, 2]}
              disabled={variableChecker(MSKHQQ7_Q)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(mskhqq15Text.hover.no, MSKHQQ15)}
          className="inline-block w-full"
        >
          <CopyText
            content={replaceScore(mskhqq15Text.hover.no, MSKHQQ15)}
          >
            <CardAnswers
              text={mskhqq15Text.label.no}
              noSpacing={true}
            >
              {mappedMskhqq15.text}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
