import React, { useContext, useEffect, useState } from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { useRouter } from 'hooks';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import { PatientResponseInfo } from 'types';
import { ComparisonPatients } from 'components/Comparison';
import { patientMapping, patientPrescription } from 'utils';
import { UserContext } from 'context/userContext';

interface SummaryProps {
  data: PatientResponseInfo;
}

export const Summary: React.FC<SummaryProps> = ({ data }) => {
  const { patientIndex, setPatientIndex } = useContext(UserContext);
  const [similarCases, setSimilarCases] =
    useState<PatientResponseInfo[]>();
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Eksport`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  // Fetch similar cases
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';
        const { similarPatients } = data;
        const similarCasesWithContent = Object.entries(
          similarPatients,
        )
          .map(([key, value]) => ({
            patient_id: key,
            similar: value,
          }))
          .sort((a, b) => (a.similar < b.similar ? 1 : -1));

        const response: PatientResponseInfo[] = await Promise.all(
          similarCasesWithContent.map(
            ({ patient_id }: { patient_id: string }) => {
              return fetch(
                `${
                  process.env.REACT_APP_URL_CBR
                }/patient/getPatientById?${new URLSearchParams({
                  patientId: patient_id,
                })}`,
                {
                  headers: {
                    Authorization: token,
                  },
                },
              )
                .then((response) => response.json())
                .catch((err) => console.log(err));
            },
          ),
        );

        // Make sure they are in ascending order
        setSimilarCases(response);
      } catch (error) {
        console.log('PatientsError', error);
      }
    };
    fetchData();

    // Auto select first patient
    if (!patientIndex) {
      setPatientIndex(1);
    }
  }, []);

  if (!data || !similarCases) return null;
  const patients = patientMapping(data, similarCases);

  const handleClickPatient = (index: number) => {
    setPatientIndex(index + 1);
  };

  const generateDownload = (data: string[], title: string) => {
    const blob = new Blob(data, {
      type: 'text/plain;charset=utf-8',
    });

    saveAs(blob, `${title}.txt`);
  };

  const downloadFile = (type: string) => {
    let patient = patients[patientIndex];
    const prescription = patientPrescription(patient);
    generateDownload(
      prescription.map(
        ({ title, value }) => `${title}: ${value}\r\n\r\n`,
      ),
      'beskrivelse-av-tiltak',
    );
  };

  return (
    <div>
      <Helmet>
        <title>{`SupportPrim - Eksport`}</title>
      </Helmet>
      {patientIndex === 0 ? null : (
        <>
          <ComparisonPatients
            patients={patients.slice(1, 4)}
            patientIndex={patientIndex - 1}
            onClick={handleClickPatient}
          />
          {[
            // {
            //   title:
            //     '1.	Overview (Baseline Pasient), export entire screen to pdf',
            //   type: 'OVERVIEW',
            // },
            // {
            //   title:
            //     '2.	From Baseline Common (Baseline Felles), export PSFS activity (text) and score, and Hovedmålet (text) as pdf or text file',
            //   type: 'BASELINE',
            // },
            // {
            //   title:
            //     '3.	From Comparison (Pasientmatching): Export selected patient (depending on selected patient in Comparison window) with info in top row, radar graph and timeplot graphs (sms data) pdf or text file',
            //   type: 'COMPARISON',
            // },
            // {
            //   title:
            //     '4.	From Comparison (Pasientmatching): Export Tiltak list for mest vektlagt (if 2 of 3 of the patients) and mest vektlagt (filled circle) and noe vektlagt (open circle) for the selected patient. Use same symbols in both columns. See table next page.',
            //   type: 'ACTION',
            // },
            {
              title: 'Beskrivelse av tiltak for valgt ut pasient',
              type: 'PRESCRIPTION',
            },
          ].map(({ title, type }) => (
            <Card className="flex w-full" key={type}>
              <CardLabel
                size={CardLabelType.sm}
                text={title}
                className="pr-4"
              />
              <button
                className="ml-auto"
                onClick={() => downloadFile(type)}
              >
                Eksport
              </button>
            </Card>
          ))}

          {/* <div className="flex justify-between -mx-3">
            <Card className="w-1/2 mx-3">
              <CardLabel
                text="Summary"
                size={CardLabelType.lg}
                className="mb-1"
              />
              <CardLabel
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare in egestas pretium odio sed quis proin sed. Quis vulputate volutpat venenatis non pellentesque tincidunt vitae. Suscipit scelerisque vulputate arcu enim habitant felis, et, interdum. Vestibulum imperdiet dis adipiscing ac risus ullamcorper purus. Euismod purus consequat purus dolor nunc vitae sed id"
                size={CardLabelType.sm}
                className="mb-4"
              />
              <textarea
                className="form-textarea w-full rounded"
                rows={5}
                placeholder="Enter some long form content."
              />
            </Card>
            <Card className="w-1/2 mx-3">
              <CardLabel
                text="Question"
                size={CardLabelType.lg}
                className="mb-1"
              />
              <BaselineCommon />
            </Card>
          </div> */}
        </>
      )}
    </div>
  );
};
