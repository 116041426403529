import { TreatmentPlanText } from 'interfaces';

export const TREATMENT_PLAN: TreatmentPlanText[] = [
  {
    title: 'Råd og veiledning:',
    sections: [
      {
        id: 'TreatConsFollowup',
        title: '',
        data: [
          {
            rule: (data) => data.patientDetails.TreatConsFollowup,
            text: ['Det er avtalt oppfølgingstime hos din fastlege'],
            links: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Fysioterapi:',
    sections: [
      {
        id: 'TreatFysGfMt',
        title: '',
        data: [
          {
            rule: (data) => data.patientDetails.TreatFysGfMt,
            text: [
              'Legen har anbefalt at du begynner i behandling hos en fysioterapeut',
            ],
            links: [],
          },
        ],
      },
      {
        id: 'TreatFysPm',
        title: '',
        data: [
          {
            rule: (data) => data.patientDetails.TreatFysPm,
            text: [
              'Legen har anbefalt at du begynner i behandling hos en psykomotorisk fysioterapeut',
            ],
            links: [],
          },
        ],
      },
    ],
  },
  {
    title:
      'Oversikt over råd og informasjon som din fastlege ønsker at du ser mer på:',
    sections: [
      {
        id: 'TreatPsycSociFact',
        title: 'Psykososiale faktorer:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatPsycSociFact,
            text: [
              'Hvordan leve med en kronisk sykdom (Norsk revmatikerforbund):',
            ],
            links: [
              'https://www.revmatiker.no/lev-bedre-med-usynlig-sykdom/kronikere-i-arbeidslivet',
              'https://www.arbeidstilsynet.no/tema/psykososialt-arbeidsmiljo/',
            ],
          },
        ],
      },
      {
        id: 'TreatPhysActivity',
        title: 'Generelt aktivitetsnivå og fysisk aktivitet:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatPhysActivity,
            text: [
              'Informasjon om hvordan fysisk aktivitet påvirker fysisk og psykisk helse (Helsenorge):',
              'Tips til å komme i gang med et treningsprogram (Helsenorge):',
              'Tips til generelle øvelser (Norsk Fysioterapautforbund):',
            ],
            links: [
              'https://www.helsenorge.no/trening-og-fysisk-aktivitet/hva-fysisk-aktivitet-gjor-med-kroppen/',
              'https://www.helsenorge.no/trening-og-fysisk-aktivitet/',
              'https://fysio.no/Pasientinfo/OEvelser',
            ],
          },
        ],
      },
      {
        id: 'TreatSociActivity',
        title: 'Sosial aktivitet:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSociActivity,
            text: [
              'Fem råd for økt livskvalitet og sterkere psykisk helse (Helsenorge):',
            ],
            links: [
              'https://www.helsenorge.no/psykisk-helse/fem-rad-for-sterkere-psykisk-helse',
            ],
          },
        ],
      },
      {
        id: 'TreatSleep',
        title: 'Søvn:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSleep,
            text: [
              'Tips og råd om søvnhygiene (Helsebiblioteket):',
              'WEB: Videoer til selvhjelp (Norsk helseinformatikk):',
            ],
            links: [
              'https://www.helsebiblioteket.no/243597.cms',
              'https://nhi.no/psykisk-helse/kognitiv-terapi/langvarige-sovnvansker/langvarige-sovnvansker-selvhjelpskurs/',
            ],
          },
        ],
      },
      {
        id: 'TreatFatig',
        title: 'Utmattet:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatFatig,
            text: [
              'Informasjon om utmattelse (Norsk forening for kognitiv terapi):',
            ],
            links: [
              ' https://www.kognitiv.no/psykisk-helse/ulike-lidelser/utmattelse/',
            ],
          },
        ],
      },
      {
        id: 'TreatDepr',
        title: 'Engstelig eller deprimert:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatDepr,
            text: [
              'Info om depresjon (Helsebiblioteket):',
              'Web: Videoer til selvhjelp ved depresjon (Norsk forening for kognitiv terapi):',
              'Info om angst (Helsebiblioteket):',
              'Videoer til selvhjelp ved ulike angstlidelser (Norsk forening for kognitiv terapi):',
            ],
            links: [
              'https://www.helsebiblioteket.no/243269.cms',
              'https://www.kognitiv.no/psykisk-helse/ulike-lidelser/depresjon/videobasert-selvhjelp-ved-depresjon/',
              'https://www.helsebiblioteket.no/pasientinformasjon/psykisk-helse/generalisert-angstlidelse',
              'https://nhi.no/psykisk-helse/kognitiv-terapi/',
            ],
          },
        ],
      },
      {
        id: 'TreatMentDis',
        title: 'Psykisk belastning:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatMentDis,
            text: [
              'Samlesiden med informasjon om psykisk helse (Helsebiblioteket):',
              'Videoer, Samleside med ulike videoer til selvhjelp (Norsk forening for kognitiv terapi):',
              'Informasjon om Rask Psykisk Helsehjelp (Norsk Forening for Kognitiv terapi):',
            ],
            links: [
              'https://www.helsebiblioteket.no/pasientinformasjon/psykisk-helse/?index=0',
              'https://nhi.no/psykisk-helse/kognitiv-terapi/',
              'https://www.kognitiv.no/hjelp-til-deg/behandlingstilbud/rask-psykisk-helsehjelp/',
            ],
          },
        ],
      },
      {
        id: 'TreatFearMov',
        title: 'Bevegelsesfrykt:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatFearMov,
            text: [
              'Har du frykt for å gjøre en spesiell bevegelse/aktivitet som egentlig er ufarlig for din tilstand, er det anbefalt at man gradvis eksponerer seg for denne bevegelsen inntil man får til å gjennomføre den normalt. Det kan hende at du trenger hjelp til dette:',
              'Har du generelt frykt for å bevege deg og være aktiv, da er det fornuftig å få tilpasset generell trening og aktivitet for å lære hva kroppen tolererer.',
            ],
            links: [
              'https://www.helsenorge.no/psykisk-helse/fem-rad-for-sterkere-psykisk-helse',
            ],
          },
        ],
      },
      {
        id: 'TreatPain',
        title: 'Smerter:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatPain,
            text: [
              'Video- Forstå smerter på 5 minutter (Universtitssykehuset i Nord-Norge): ',
              'Korte videoer som forklarer smertemekanismer (Retrainpain):',
              'Informasjon om smerte (Smertenettverket):',
            ],
            links: [
              'https://www.youtube.com/watch?v=E9tVWoRhPKU',
              'https://www.retrainpain.org/norsk',
              'https://www.smertenettverk.no/pasienter ',
            ],
          },
        ],
      },
      {
        id: 'TreatPainMan',
        title: 'Smertemestring:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatPainMan,
            text: [
              'Hvordan mestre smerte? (Informasjon fra Norsk psykologiforening):',
              'Video- smertemestring (Ullevål sykehus):',
            ],
            links: [
              'https://www.psykologforeningen.no/publikum/informasjonsvideoer/videoer-om-livsutfordringer/hvordan-mestre-smerter',
              'https://www.youtube.com/watch?v=DUSpxGUTJEY&list=TLGGGWAuumdxtXowMTExMjAyMQ',
            ],
          },
        ],
      },

      {
        id: 'TreatWork',
        title: 'Arbeidsdeltakelse:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatWork,
            text: [
              'Hvordan mestre stress i arbeidslivet (Norsk psykologiforening):',
              'Informasjon video om arbeidsmiljø og muskel/skjelettplager (Arbeidstilsynet):',
            ],
            links: [
              'https://www.psykologforeningen.no/publikum/informasjonsvideoer/videoer-om-arbeidsliv/hvordan-mestre-stress-i-arbeidslivet',
              'https://fysio.no/Hva-mener-NFF/Fag-helse-og-arbeidslivspolitikk/Arbeidsmiljoe-og-muskel-og-skjelettplager',
            ],
          },
        ],
      },
      {
        id: 'TreatEmploymentConflict',
        title: 'Arbeidskonflikt:',
        data: [
          {
            rule: (data) =>
              data.patientDetails.TreatEmploymentConflict,
            text: [
              'Dialog med arbeidsgiver/tillitsvalgt/bedriftshelsetjeneste er viktig, siden en sykmelding ikke er en løsning på problemet',
            ],
            links: [''],
          },
        ],
      },
      {
        id: 'TreatWeightRed',
        title: 'Vektreduksjon:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatWeightRed,
            text: [
              'Informasjon om kosthold, unngå overvekt og vektreduksjon (Helse Norge):',
            ],
            links: [
              'https://www.helsenorge.no/kosthold-og-ernaring/overvekt/unnga-overvekt/',
            ],
          },
        ],
      },
      {
        id: 'TreatOa',
        title: 'Artrose:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatOa,
            text: ['AktivA Pasientinformasjon'],
            links: ['https://aktivmedartrose.no/for-pasienter/'],
          },
        ],
      },
      {
        id: 'TreatSmokQuit',
        title: 'Røykestopp:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSmokQuit,
            text: [
              'Råd og tips om snus- og røykeslutt (Helsenorge):',
            ],
            links: ['https://www.helsenorge.no/snus-og-roykeslutt/'],
          },
          {
            rule: (data) => data.patientDetails.TreatSmokQuit,
            text: [
              'Slutta-appen. Slutta-appen hjelper deg på veien til et tobakksfritt liv (Helsedirektoratet):',
            ],
            links: [
              'https://play.google.com/store/apps/details?id=no.helsedir.slutta2020',
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Arbeidsrettede tiltak:',
    sections: [
      {
        id: 'TreatSickleaveGrad',
        title: 'Avventende sykemelding:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSickleaveGrad,
            text: [
              'Din fastlege har gitt deg avventende sykemelding. Her finner du mer informasjon om avventende sykmelding:',
            ],
            links: [
              'https://www.helsedirektoratet.no/veiledere/sykmelderveileder/pasientinformasjon/avventende-sykmelding-pasientinformasjon',
            ],
          },
        ],
      },
      {
        id: 'TreatSickleavePen',
        title: 'Gradert sykemelding:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSickleavePen,
            text: [
              'Din fastlege har gitt deg gradert sykemelding. Her finner du mer informasjon om gradert sykemelding:',
            ],
            links: [
              'https://www.helsedirektoratet.no/veiledere/sykmelderveileder/pasientinformasjon/gradert-sykmelding-pasientinformasjon',
            ],
          },
        ],
      },
      {
        id: 'TreatSickleaveFull',
        title: 'Sykemeldt:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatSickleaveFull,
            text: [
              'Din fastlege har gitt deg full sykemelding. Her finner du mer informasjon om full sykemelding:',
            ],
            links: [
              'https://www.helsedirektoratet.no/veiledere/sykmelderveileder/pasientinformasjon/full-sykmelding-pasientinformasjon',
            ],
          },
        ],
      },
      {
        id: 'TreatDialEmp',
        title: 'Dialog med arbeidsgiver:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatDialEmp,
            text: [
              'Legen har anbefalt at du går i dialog med arbeidsgiver, dette er ofte nyttig ift tilrettelegging og endrede arbeidsoppgaver i en periode med sykdom. NAV har også apparat som kan bistå med å gjøre dialogen med arbeidsgiver lettere hvis man feks står i konflikt, dette kan det isåfall gis direkte beskjed til NAV om via sykmeldingsblanketten',
            ],
            links: [''],
          },
        ],
      },
    ],
  },
  {
    title: '',
    sections: [
      {
        id: 'TreatRevWork',
        title: 'Plan for tilbakeføring til arbeid:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatRevWork,
            text: [
              'Du har i samarbeid med din fastlege utarbeidet en plan til tilbakeføring til arbeid',
            ],
            links: [],
          },
        ],
      },
      {
        id: 'TreatRevWorkNeedCoord',
        title: 'Behov for koordinering:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatRevWorkNeedCoord,
            text: ['Fastlegen har diskutert behov for koordinering'],
            links: [],
          },
        ],
      },
      {
        id: 'TreatRevWorkTime',
        title: 'Oppfølging over tid:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatRevWorkTime,
            text: [
              'Fastlegen har lagt en plan for oppfølging over tid',
            ],
            links: [],
          },
        ],
      },
      {
        id: 'TreatFunctAssess',
        title: 'Funksjonsvurdering:',
        data: [
          {
            rule: (data) => data.patientDetails.TreatFunctAssess,
            text: ['Din fastlege har utført en funksjonsvurdering'],
            links: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Medisinering:',
    sections: [
      {
        id: 'TreatMedic',
        title: '',
        data: [
          {
            rule: (data) =>
              [
                data.patientDetails.TreatMedicPara,
                data.patientDetails.TreatMedicNsaid,
                data.patientDetails.TreatMedicGel,
                data.patientDetails.TreatMedicNeuri,
                data.patientDetails.TreatMedicCoAng,
                data.patientDetails.TreatMedicOpiod,
              ].filter((m) => m).length >= 1,
            text: [
              'Legen har anbefalt bruk av smertestillende medisiner',
            ],
            links: [''],
          },
        ],
      },
      // {
      //   id: 'TreatMedic',
      //   title: '',
      //   data: [
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicPara,
      //       text: ['Paracetamol'],
      //       links: [],
      //     },
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicNsaid,
      //       text: ['NSAIDS tabletter'],
      //       links: [],
      //     },
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicGel,
      //       text: ['NSAIDS gel'],
      //       links: [],
      //     },
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicNeuri,
      //       text: ['Neurontin/Lyrica'],
      //       links: [],
      //     },
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicCoAng,
      //       text: ['Co-analgetika'],
      //       links: [],
      //     },
      //     {
      //       rule: (data) => data.patientDetails.TreatMedicOpiod,
      //       text: ['Milde opiater'],
      //       links: [],
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Din fastlege har henvist deg til:',
    sections: [
      {
        id: 'Treat12m1',
        title: '',
        data: [
          {
            text: ['Røntgen'],
            rule: (data) => data.patientDetails.TreatRefImagXray,
            links: [],
          },
          {
            text: ['MR/CT'],
            rule: (data) => data.patientDetails.TreatRefImagMrCt,
            links: [],
          },
          {
            text: ['Andre'],
            rule: (data) => data.patientDetails.TreatRefImagOth,
            links: [],
          },
          {
            text: ['Spesialist'],
            rule: (data) => data.patientDetails.TreatRefSpeciSpeci,
            links: [],
          },
          {
            text: ['Tverrfaglig poliklinikk/smerteklinikk'],
            rule: (data) => data.patientDetails.TreatRefSpeciIntdis,
            links: [],
          },
          {
            text: ['Rehabilitering'],
            rule: (data) => data.patientDetails.TreatRefSpeciRehab,
            links: [],
          },
          {
            text: ['Psykolog'],
            rule: (data) => data.patientDetails.TreatRefPsych,
            links: [],
          },
          {
            text: ['Frisklivssentral'],
            rule: (data) => data.patientDetails.TreatRefWellCenter,
            links: [],
          },
          {
            text: ['Andre i primærhelsetjenesten'],
            rule: (data) => data.patientDetails.TreatRefOthPrimCar,
            links: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Annen anbefalt behandling:',
    sections: [
      {
        id: 'TreatFreeText',
        title: '',
        data: [
          {
            text: [],
            rule: (data) => data.patientDetails.TreatFreeText,
            links: [],
          },
        ],
      },
    ],
  },
];
