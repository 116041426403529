export const qol15dVitalText = {
  label: {
    en: 'Fatigue',
    no: 'Utmattet',
  },
  hover: {
    en: '',
    no: 'Quality of life 15D vitalitet. Fem-delt skala fra ikke til ekstremt. Pasientsvar: "actual score"',
  },
  values: [
    {
      value: 'healt',
      text: 'Jeg føler meg frisk og energisk.',
      score: 1,
    },
    {
      value: 'sligw',
      text: 'Jeg føler meg litt sliten, trett eller svak.',
      score: 2,
    },
    {
      value: 'modw',
      text: 'Jeg føler meg middels sliten, trett eller svak.',
      score: 3,
    },
    {
      value: 'veryw',
      text: 'Jeg føler meg svært sliten, trett eller svak, nesten utslitt.',
      score: 4,
    },
    {
      value: 'extrw',
      text: 'Jeg føler meg ekstremt sliten, trett eller svak, totalt utslitt.',
      score: 5,
    },
  ],
};
