import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { BoxFields } from 'components/BoxFields';
import { FormInput } from 'components/Form';
import { Baseline } from 'interfaces';
import { kneeMoveRomText } from 'helpers/kneeMoveRom';
import { kneeMoveText } from 'helpers/kneeMove';
import { kneeMovePainText } from 'helpers/kneeMovePain';
import { KneeMovePassiveText } from 'helpers/kneeMovePassive';
import { kneeMovePassiveRomText } from 'helpers/kneeMovePassiveRom';
import { KneeTraumaticText } from 'helpers/kneeTraumatic';
import { KneeHydropsText } from 'helpers/kneeHydrops';
import { kneeMovePassivePainText } from 'helpers/kneeMovePassivePain';

interface KneeProps {
  values: Baseline;
}

export const Knee: React.FC<KneeProps> = ({ values }) => {
  const {
    KneeMove,
    KneeHydrops,
    KneeMovePassive,
    KneeMoveRom_flex,
    KneeMoveRom_ext,
    KneeMoveRom_not,
    KneeMovePassiveRom_flex,
    KneeMovePassiveRom_ext,
    KneeMovePassiveRom_not,
    KneeMovePassivePain_flex,
    KneeMovePassivePain_ext,
    KneeMovePassivePain_not,
    KneeMovePain_flex,
    KneeMovePain_ext,
    KneeMovePain_not,
    KneeTraumatic,
  } = values;
  const isKneeActive = KneeMove === 'Y';
  const isKneePassive = KneeMovePassive === 'Y';

  return (
    <Card>
      <CardFormText text="Kneundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text={kneeMoveText.label.no}
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ KneeMove }}
          keys={kneeMoveText.values}
        />
      </div>
      {isKneeActive && (
        <>
          <CardFormText text={kneeMoveRomText.label.no} />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                KneeMoveRom_flex,
                KneeMoveRom_ext,
                KneeMoveRom_not,
              }}
              keys={kneeMoveRomText.values}
            />
          </div>
          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                KneeMovePain_flex,
                KneeMovePain_ext,
                KneeMovePain_not,
              }}
              keys={kneeMovePainText.values}
            />
          </div>
        </>
      )}
      <CardFormText text="Reduserte og/eller smertefulle passive bevegelsesutslag" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            KneeMovePassive,
          }}
          keys={KneeMovePassiveText.values}
        />
      </div>
      {isKneePassive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                KneeMovePassiveRom_flex,
                KneeMovePassiveRom_ext,
                KneeMovePassiveRom_not,
              }}
              keys={kneeMovePassiveRomText.values}
            />
          </div>
          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                KneeMovePassivePain_flex,
                KneeMovePassivePain_ext,
                KneeMovePassivePain_not,
              }}
              keys={kneeMovePassivePainText.values}
            />
          </div>
        </>
      )}

      <div>
        <CardFormText text="Er det hydrops i kneet" />
        <div className="mb-8">
          <BoxFields
            values={values}
            variables={{
              KneeHydrops,
            }}
            keys={KneeHydropsText.values}
          />
        </div>
      </div>

      <CardFormText text="Har pasienten vært utsatt for et traume" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            KneeTraumatic,
          }}
          keys={KneeTraumaticText.values}
        />
      </div>
      <div className="mb-6">
        <CardLabel
          text="Klinisk undersøkelse fritekst:"
          size={CardLabelType.lg}
        />
      </div>
      <div className="mb-6">
        <FormInput
          id="KneeComment"
          name="KneeComment"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
