import React, { useEffect, useRef, useState } from 'react';

import ROUTES, { RenderRoutes } from 'config/routes';
import { Sidebar } from 'components/Sidebar';
import { useRouter } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import UserProvider from '../../context/userContext';
import { ContactLink } from 'components/ContactLink';
import { PatientInfoResponse } from 'interfaces';
import { patientConvert } from 'helpers/patientConvert';

function App() {
  const [patient, setPatient] = useState<PatientInfoResponse>();
  const { pathname } = useLocation();
  const { pushInstruction } = useMatomo();
  const main = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const token = localStorage.getItem('token') || '';
  const user = localStorage.getItem('user');
  // Scroll to top on path change
  useEffect(() => {
    if (main.current) {
      main.current.scrollTop = 0;
    }
  }, [pathname]);

  // Fetch user patient info
  useEffect(() => {
    if (pathname !== '/patients' && token) {
      const query = user
        ? new URLSearchParams({
            patientId: user,
          })
        : '';
      // Set matomo user
      pushInstruction('setUserId', localStorage.getItem('username'));

      const fetchData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_URL_CBR}/patient/getPatientById?${query}`,
            {
              headers: {
                Authorization: token,
              },
            },
          );
          const json = await response.json();

          // Something went wrong. Log out
          if (!json.patientDetails.Age) {
            throw new Error();
          }

          setPatient(patientConvert(json));
        } catch (error) {
          console.log('Error', error);
          localStorage.removeItem('username');
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          router.replace('/login');
        }
      };

      fetchData();
    }
  }, [pathname]);

  const authenticated = token && pathname !== '/patients';

  return authenticated ? (
    <>
      <UserProvider>
        <div className="flex h-screen">
          <ContactLink />
          {patient && <Sidebar data={patient} />}
          <div
            ref={main}
            className="w-full relative p-6 overflow-y-auto"
          >
            {patient && (
              <RenderRoutes routes={ROUTES} data={patient} />
            )}
          </div>
        </div>
      </UserProvider>
    </>
  ) : (
    <div className="min-h-screen">
      <ContactLink />
      <RenderRoutes routes={ROUTES} />
    </div>
  );
}

export default App;
