export const mskhqq7Text = {
  label: {
    en: 'Social activities and hobbies',
    no: 'Sosial aktivitet',
  },
  hover: {
    en: '',
    no: 'Hvor mye har dine ledd-/muskelsymptomer hindret deg i å være sosialt aktiv og å holde på med hobbyer de siste to ukene? Fem-delt skala fra ikke i det hele tatt til ekstremt. Pasientsvar: "actual score"',
  },
  values: [
    { value: 'extre', text: 'Ekstremt', score: 0 },
    { value: 'moder', text: 'Moderat', score: 2 },
    { value: 'very', text: 'Veldig', score: 1 },
    { value: 'sligh', text: 'Litt', score: 3 },
    { value: 'not', text: 'Ikke i det hele tatt', score: 4 },
  ],
};
