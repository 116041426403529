export const educationText = {
  label: {
    en: '',
    no: '',
  },
  hover: {
    en: '',
    no: '',
  },
  values: [
    { value: 'prims', text: 'Grunnskole eller lavere', score: 0 },
    { value: 'highs', text: 'Videregående skole', score: 0 },
    { value: '4yed', text: 'Inntil 4 år høyere utdanning', score: 0 },
    {
      value: 'more4',
      text: 'Mer enn 4 år høyere utdanning',
      score: 0,
    },
    { value: 'other', text: 'Annet', score: 0 },
  ],
};
