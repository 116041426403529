export const orebq7qText = {
  label: {
    en: 'Expectations of recovery',
    no: 'Forventning om langvarige plager',
  },
  hover: {
    en: '',
    no: 'Hvor stor risiko mener du det er for at dine nåværende plager kan bli langvarige? 0 = Ingen risiko, 10 = Svært stor risiko. Pasientsvar: "actual score"',
  },
};
