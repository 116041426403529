import clsx from 'clsx';
import React from 'react';

interface CardProps {
  className?: string;
  noSpacing?: boolean;
}

export const Card: React.FC<CardProps> = ({
  children,
  className,
  noSpacing,
}) => {
  return (
    <div
      className={clsx(
        'bg-white relative overflow-hidden rounded',
        {
          'p-6 mb-6': !noSpacing,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
