import { GenderType } from 'interfaces';

export const genderText = {
  values: [
    { value: 'Gender_F', text: 'Kvinne', score: 0 },
    { value: 'Gender_M', text: 'Mann', score: 0 },
  ],
};

export const getGender = (gender: GenderType | undefined) => {
  if (!gender) {
    return '';
  }

  const genders = {
    F: 'Kvinne',
    M: 'Mann',
  };

  return genders[gender];
};
