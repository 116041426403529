import React from 'react';
import { Field } from 'formik';

interface BoxFieldProps {
  name?: string;
  text?: string;
  square?: boolean | false;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  type: string;
}

export const BoxField: React.FC<BoxFieldProps> = ({
  name,
  text,
  square,
  value,
  checked,
  disabled,
  type,
}) => {
  return (
    <div className="flex items-center mb-3">
      <Field
        className={`w-8 h-8 border-2 mb-1 border-gray-500 ${
          square ? 'form-checkbox' : 'form-radio'
        } ${square ? '' : 'rounded-full'}
        ${
          disabled
            ? 'cursor-not-allowed bg-gray-100'
            : 'hover:bg-indigo-500 hover:border-transparent cursor-pointer'
        }
        `}
        type={type}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <div className="ml-2 text-sm font-medium">{text}</div>
    </div>
  );
};
