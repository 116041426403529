export const bodyMainTraumaText = {
  values: [
    {
      value: 'traum',
      text: 'Akutt på grunn av fall/skade/ulykke',
      score: 1,
    },
    {
      value: 'acute',
      text: 'Oppstod plutselig uten kjent årsak',
      score: 2,
    },
    { value: 'gradu', text: 'Gradvis over tid', score: 3 },
    { value: 'oth', text: 'Annet', score: 4 },
  ],
};
