import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';

import { BoxFields } from 'components/BoxFields';
import { FormInput } from 'components/Form';
import { Baseline } from 'interfaces';
import { neckMoveRomText } from 'helpers/neckMoveRom';
import { neckMovePainText } from 'helpers/neckMovePain';
import { neckSpurlingText } from 'helpers/neckSpurling';
import { neckNevroText } from 'helpers/neckNevro';
import { neckConclusionText } from 'helpers/neckConclusion';
import { neckMoveText } from 'helpers/neckMove';

interface NeckProps {
  values: Baseline;
}

export const Neck: React.FC<NeckProps> = ({ values }) => {
  const {
    NeckConclusion,
    NeckNevro_nota,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckSpurling,
    NeckMovePain_flex,
    NeckMovePain_ext,
    NeckMovePain_rot,
    NeckMovePain_lat,
    NeckMovePain_not,
    NeckMoveRom_flex,
    NeckMoveRom_ext,
    NeckMoveRom_rot,
    NeckMoveRom_lat,
    NeckMoveRom_not,
    NeckMove,
  } = values;

  // Conditional display fields
  const isNeck = NeckMove === 'Y';

  return (
    <>
      <Card>
        <CardFormText text="Nakkeundersøkelse">
          <div className="mt-2 mb-4">
            <CardLabel
              text="Reduserte og/eller smertefulle aktive bevegelsesutslag i vektbærende stilling"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={neckMoveText.values}
            variables={{
              NeckMove,
            }}
          />
        </div>

        {isNeck && (
          <>
            <div className="mb-5">
              <CardLabel
                text="Reduserte bevegelsesutslag i"
                size={CardLabelType.lg}
              />
            </div>
            <div className="mb-8">
              <BoxFields
                values={values}
                square={true}
                keys={neckMoveRomText.values}
                variables={{
                  NeckMoveRom_flex,
                  NeckMoveRom_ext,
                  NeckMoveRom_rot,
                  NeckMoveRom_lat,
                  NeckMoveRom_not,
                }}
              />
            </div>
            <CardLabel
              className="mb-8"
              text="Smerter ved"
              size={CardLabelType.lg}
            />
            <BoxFields
              values={values}
              square={true}
              keys={neckMovePainText.values}
              variables={{
                NeckMovePain_flex,
                NeckMovePain_ext,
                NeckMovePain_rot,
                NeckMovePain_lat,
                NeckMovePain_not,
              }}
            />
          </>
        )}
        <CardFormText text="Spurling test positiv">
          <div className="mt-2 mb-4">
            <CardLabel
              text="(Positiv ved smerteutstråling til arm)"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <BoxFields
          values={values}
          keys={neckSpurlingText.values}
          variables={{
            NeckSpurling,
          }}
        />
        <div className="mb-5">
          <CardLabel
            text="Angi nevrologiske funn"
            size={CardLabelType.lg}
          />
        </div>
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={neckNevroText.values}
            variables={{
              NeckNevro_nota,
              NeckNevro_pow,
              NeckNevro_sens,
              NeckNevro_reflex,
            }}
            square={true}
          />
        </div>

        <CardLabel
          className="mb-8"
          text="Konklusjon av nakkevurdering"
          size={CardLabelType.lg}
        />
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={neckConclusionText.values}
            variables={{
              NeckConclusion,
            }}
          />
        </div>
        <div className="mb-6">
          <CardLabel
            text="Klinisk undersøkelse fritekst"
            size={CardLabelType.lg}
          />
        </div>
        <div className="mb-6">
          <FormInput
            id="NeckComment"
            name="NeckComment"
            type="text"
            className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
          />
        </div>
      </Card>
    </>
  );
};
