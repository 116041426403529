export const neckConclusionText = {
  values: [
    {
      value: 'notpato',
      text: 'Ingen tegn til alvorlige patologiske forandringer. Liten eller ingen innvirkning på dagliglivets funksjon på grunn av nakkeproblemene',
      score: 0,
    },
    {
      value: 'notpatofunk',
      text: 'Ingen tegn på alvorlige patologiske forandringer, men med sikker nedsettelse av dagliglivets funksjon',
      score: 0,
    },
    {
      value: 'nevrotaff',
      text: 'Tegn på nerverotsaffeksjon',
      score: 0,
    },
    {
      value: 'sevnevrotaff',
      text: 'Tegn på alvorlig patologisk forandring: brudd, myelopati, neoplasme, infeksjoner eller systemisk sykdom',
      score: 0,
    },
  ],
};
