export const mskqhqScoreText = {
  label: {
    en: 'MSK-HQ sum score',
    no: 'Muskel- og skjeletthelse',
  },
  hover: {
    en: '',
    no: 'Generell muskel- og skjeletthelse målt med ved MSK-HQ. 0 = Verst helse, 56 = Best helse. Pasientsvar: "actual score"',
  },
};
