import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/dist/border.css';

interface HoverContentProps {
  content?: string | React.ReactNode;
  className?: string;
}

export const HoverContent: React.FC<HoverContentProps> = ({
  content,
  children,
  className,
}) => {
  let isContent = true;
  if (typeof content === 'string') {
    isContent = content.trim().length >= 1;
  }

  if (typeof content === 'object') {
    isContent = Object.keys(content || {}).length >= 1;
  }

  return isContent ? (
    <>
      <Tippy
        content={
          <div className="p-4">
            {typeof content === 'string' ? (
              <div
                className="leading-relaxed"
                dangerouslySetInnerHTML={{
                  __html:
                    content !== null && content !== undefined
                      ? content
                      : '',
                }}
              />
            ) : (
              <div className="leading-relaxed">{content}</div>
            )}
          </div>
        }
        theme="light"
      >
        <div className={`${className ? className : ''}`}>
          {children}
        </div>
      </Tippy>
    </>
  ) : (
    <>{children}</>
  );
};
