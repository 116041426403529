import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { BoxFields } from 'components/BoxFields';
import { Baseline } from 'interfaces';
import { hipMoveText } from 'helpers/HipMove';
import { hipMoveRomText } from 'helpers/HipMoveRom';
import { hipMovePainText } from 'helpers/HipMovePain';
import { hipMovePassiveText } from 'helpers/HipMovePassive';
import { hipMovePassiveRomText } from 'helpers/HipMovePassiveRom';
import { hipMovePassivePainText } from 'helpers/HipMovePassivePain';
import { hipPainText } from 'helpers/HipPain';
import { FormInput } from 'components/Form';

interface HipProps {
  values: Baseline;
}

export const Hip: React.FC<HipProps> = ({ values }) => {
  const {
    HipMove,
    HipMovePassive,
    HipMoveRom_flex,
    HipMoveRom_ext,
    HipMoveRom_abd,
    HipMoveRom_add,
    HipMoveRom_extrot,
    HipMoveRom_introt,
    HipMoveRom_not,
    HipMovePain_flex,
    HipMovePain_ext,
    HipMovePain_abd,
    HipMovePain_add,
    HipMovePain_extrot,
    HipMovePain_introt,
    HipMovePain_not,
    HipMovePassiveRom_flex,
    HipMovePassiveRom_ext,
    HipMovePassiveRom_abd,
    HipMovePassiveRom_add,
    HipMovePassiveRom_extrot,
    HipMovePassiveRom_introt,
    HipMovePassiveRom_not,
    HipMovePassivePain_flex,
    HipMovePassivePain_ext,
    HipMovePassivePain_abd,
    HipMovePassivePain_add,
    HipMovePassivePain_extrot,
    HipMovePassivePain_introt,
    HipMovePassivePain_not,
    HipPain_nota,
    HipPain_lat,
    HipPain_med,
    HipPain_post,
    HipPain_ant,
  } = values;

  // Conditional display fields
  const isHipActive = HipMove === 'Y';
  const isHipPassive = HipMovePassive === 'Y';

  return (
    <Card>
      <CardFormText text="Hofteundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/eller smertefulle aktive bevegelsesutslag"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ HipMove }}
          keys={hipMoveText.values}
        />
      </div>
      {isHipActive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                HipMoveRom_flex,
                HipMoveRom_ext,
                HipMoveRom_abd,
                HipMoveRom_add,
                HipMoveRom_extrot,
                HipMoveRom_introt,
                HipMoveRom_not,
              }}
              keys={hipMoveRomText.values}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                HipMovePain_flex,
                HipMovePain_ext,
                HipMovePain_abd,
                HipMovePain_add,
                HipMovePain_extrot,
                HipMovePain_introt,
                HipMovePain_not,
              }}
              keys={hipMovePainText.values}
            />
          </div>
        </>
      )}

      <CardFormText text="Reduserte og/eller smertefulle passive bevegelsesutslag" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            HipMovePassive,
          }}
          keys={hipMovePassiveText.values}
        />
      </div>

      {isHipPassive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                HipMovePassiveRom_flex,
                HipMovePassiveRom_ext,
                HipMovePassiveRom_abd,
                HipMovePassiveRom_add,
                HipMovePassiveRom_extrot,
                HipMovePassiveRom_introt,
                HipMovePassiveRom_not,
              }}
              keys={hipMovePassiveRomText.values}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                HipMovePassivePain_flex,
                HipMovePassivePain_ext,
                HipMovePassivePain_abd,
                HipMovePassivePain_add,
                HipMovePassivePain_extrot,
                HipMovePassivePain_introt,
                HipMovePassivePain_not,
              }}
              keys={hipMovePassivePainText.values}
            />
          </div>
        </>
      )}

      <CardFormText text="Smertelokalisasjon hofte (pasientens vurdering)" />
      <div className="mb-8">
        <BoxFields
          values={values}
          square={true}
          variables={{
            HipPain_nota,
            HipPain_lat,
            HipPain_med,
            HipPain_post,
            HipPain_ant,
          }}
          keys={hipPainText.values}
        />
      </div>
      <CardFormText text="Klinisk undersøkelse fritekst:">
        <div className="mt-2 mb-4">
          <CardLabel text="" size={CardLabelType.sm} />
        </div>
      </CardFormText>
      <div className="mb-6">
        <FormInput
          id="HipComment"
          name="HipComment"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
