// 15. Fysisk aktivitetsnivå
export const mskhqq15Text = {
  label: {
    en: 'Physical activity',
    no: 'Fysisk aktivitet siste uke',
  },
  hover: {
    en: '',
    no: 'Hvor mange dager i løpet av den siste uken har du vært fysisk aktiv i 30 minutter eller mer? Aktiviteten kan være idrett, trening, rask gange eller sykling. Husarbeid eller fysisk aktivitet som er en del av jobben din, skal ikke regnes med. Score fra 0 til 7 dager.',
  },
  values: [
    { value: 'none', text: 'Ingen dager', score: 0 },
    { value: '1d', text: '1 dag', score: 1 },
    { value: '2d', text: '2 dager', score: 2 },
    { value: '3d', text: '3 dager', score: 3 },
    { value: '4d', text: '4 dager', score: 4 },
    { value: '5d', text: '5 dager', score: 5 },
    { value: '6d', text: '6 dager', score: 6 },
    { value: '7d', text: '7 dager', score: 7 },
  ],
};
