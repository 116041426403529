export const phenotypeText = {
  hover: {
    en: '',
    no: 'Fenotype 1-5 basert på pasientsvar på 11 biopsykososiale prognostiske faktorer, der fenotype 1 har god prognose og fenotype 5 har dårlig prognose. Pasientsvar: Fenotype "actual score"',
  },
  values: [
    {
      value: 'phenotype_1',
      text: 'Fenotype 1 - En fenotype som er kjennetegnet med lavt symptomtrykk og god prognose, det er derfor viktig med minst mulig behandling i helsetjenesten.',
      score: 1,
    },
    {
      value: 'phenotype_2',
      text: 'Fenotype 2 - En fenotype som har noe forhøyet symptomtrykk, men som har god prognose. Kan vurdere kortvarig oppfølging i primærhelsetjenesten, men kun for å sikre egenmestring',
      score: 2,
    },
    {
      value: 'phenotype_3',
      text: 'Fenotype 3 - En fenotype som har moderat forhøyet symptomtrykk og middels økt risiko for nedsatt funksjon og redusert arbeidsevne.  Bør vurdere henvisning til fysioterapi og tettere oppfølging, med fokus på aktiv tilnærming og egenmestring.',
      score: 3,
    },
    {
      value: 'phenotype_4',
      text: 'Fenotype 4 - En fenotype som har moderat forhøyet symptomtrykk, sammensatte plager og en middels/høy risiko for nedsatt funksjon og langvarige plager. Bør vurdere henvisning til tverrfaglig oppfølging/psykologisk informert behandling i primærhelsetjenesten med fokus på psykososiale faktorer.',
      score: 4,
    },
    {
      value: 'phenotype_5',
      text: 'Fenotype 5 - En fenotype som har høyt symptomtrykk og betydelig nedsatt funksjon og arbeidsevne. Stor risiko for langvarige plager og funksjonsnedsettelse. Viktig med tett og hovedsakelig tverrfaglig og psykologisk informert oppfølging med fokus på psykososiale faktorer. Kan vurdere henvisning til tverrfaglig oppfølging i spesialisthelsetjenesten (smerteklinikk/poliklinikk fysikalsk medisin).',
      score: 5,
    },
  ],
};

export const radarPhenotypes = [
  {
    value: 1,
    name: 'Fenotype 1',
    data: [32, 68, 20, 50, 0, 0, 10, 20, 15, 18],
  },
  {
    value: 2,
    name: 'Fenotype 2',
    data: [40, 62, 20, 50, 8, 20, 17, 23, 24, 25],
  },
  {
    value: 3,
    name: 'Fenotype 3',
    data: [59, 64, 30, 70, 42, 70, 27, 38, 50, 50],
  },
  {
    value: 4,
    name: 'Fenotype 4',
    data: [54, 90, 50, 80, 33, 20, 37, 40, 46, 45],
  },
  {
    value: 5,
    name: 'Fenotype 5',
    data: [73, 86, 60, 90, 67, 80, 47, 55, 81, 68],
  },
];
