import { Modal } from 'components/Modal';
import React, { useState } from 'react';

interface ContactLinkProps {}

export const ContactLink: React.FC<ContactLinkProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed right-0 bottom-0 mr-4 bg-white border hover:bg-gray-100 z-10">
      <div
        onClick={() => setIsOpen(true)}
        className="cursor-pointer py-2 px-4 leading-none inline-block"
        title="Informasjon til fysioterapeuter som deltar i SupportPrim"
      >
        Kontakt oss
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div
          className="fixed right-0 top-0 mr-4 bg-white border hover:bg-gray-100 z-10 py-2 px-4 leading-none inline-block cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(
              localStorage.getItem('user') || '',
            );
          }}
        >
          {localStorage.getItem('user')}
        </div>
      )}
      <Modal open={isOpen} onClose={(s) => setIsOpen(s)}>
        <div className="text-xl font-bol">
          Trenger du hjelp, eller har du spørsmål?
        </div>
        <div>
          epost:{' '}
          <a
            className="underline text-blue-500"
            href="mailto:support@supportprim.no"
          >
            support@supportprim.no
          </a>
        </div>
        <div>
          Telefon: <a href="tel:48480211">484 80 211</a>
        </div>
      </Modal>
    </div>
  );
};
