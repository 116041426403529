import clsx from 'clsx';
import React from 'react';

interface TreatmentCardTitleProps {
  color?: string;
}

export const TreatmentCardTitle: React.FC<
  TreatmentCardTitleProps
> = ({ color = '', children }) => {
  return (
    <div
      className={clsx('relative rounded-full h-4 w-4 mr-2 border', {
        'border-green-500 bg-green-500': color === 'green',
        'border-yellow-500 bg-yellow-500': color === 'yellow',
        'border-red-500 bg-red-500': color === 'red',
        'border-gray-300 bg-gray-300': color === 'gray',
      })}
    >
      {['green'].includes(color) && (
        <div className="absolute left-[6px] top-[2px] w-[2px] h-[10px] bg-white rounded-full" />
      )}
      {['green', 'red'].includes(color) && (
        <div className="absolute left-[2px] top-[6px] w-[10px] h-[2px] bg-white rounded-full" />
      )}
      {children}
    </div>
  );
};
