export const shoulderMovePainText = {
  values: [
    { value: 'ShoulderMovePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'ShoulderMovePain_ext', text: 'Ekstensjon', score: 0 },
    { value: 'ShoulderMovePain_abd', text: 'Abduksjon', score: 0 },
    { value: 'ShoulderMovePain_add', text: 'Adduksjon', score: 0 },
    {
      value: 'ShoulderMovePain_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'ShoulderMovePain_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    { value: 'ShoulderMovePain_not', text: 'Ikke smerter', score: 0 },
  ],
};
