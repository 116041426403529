import React from 'react';
import { PatientInfoResponse } from 'interfaces';
import { getGender } from 'helpers/gender';
import { educationText, getMappedValue } from 'helpers';

const Label = ({ title }: { title: string }) => {
  return (
    <div className="mb-4">
      <span>{title}</span>
    </div>
  );
};

interface PatientInfoProps {
  patient: PatientInfoResponse;
}

export const PatientInfo: React.FC<PatientInfoProps> = ({
  patient,
}) => {
  if (!patient)
    return (
      <div
        className="flex mx-12 text-sm"
        style={{ minHeight: '185px' }}
      />
    );
  const {
    patientId,
    patientDetails: { Age, Education, Gender, Smoking, DemBMI },
  } = patient;

  return (
    <div className="flex mx-6 text-sm">
      <div className="mr-3 text-gray-500">
        <Label title="Pasient:" />
        <Label title="Alder:" />
        <Label title="Kjønn:" />
        <Label title="Røyker:" />
        <Label title="BMI:" />
        <Label title="Utdanning:" />
      </div>

      <div>
        <Label title={patientId} />
        <Label title={`${Number(Age).toFixed(0)} år`} />
        <Label title={getGender(Gender)} />
        <Label title={Smoking === 'Y' ? 'Ja' : 'Nei'} />
        <Label title={Number(DemBMI).toFixed(1) || ''} />
        <Label
          title={getMappedValue(educationText.values, Education).text}
        />
      </div>
    </div>
  );
};
