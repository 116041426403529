import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { jsPDF } from 'jspdf';

import { useRouter } from 'hooks';
import { Modal } from 'components/Modal';
import { PatientInfoResponse } from 'interfaces';
import { TreatmentPlanContent } from './TreatmentPlanContent';
import { FormButton } from 'components/Form';

interface TreatmentPlanProps {
  data: PatientInfoResponse;
}

export const TreatmentPlan: React.FC<TreatmentPlanProps> = ({
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  // SPA Link Tracking
  const { trackPageView, enableLinkTracking, trackEvent } =
    useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Plan`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  const printDocument = async () => {
    try {
      const input = document.getElementById('print-plan-export');
      if (input) {
        setLoading(true);
        const pdf = new jsPDF('p', 'pt', 'a4', true);
        if (pdf) {
          // const imgData = await domtoimage.toPng(input);
          // const imgData = await toPng(input);
          // await pdf.addImage(imgData, 'PNG', 15, 20, 180, 160);
          // pdf.save('plan.pdf');
          pdf.html(input, {
            margin: 32,
            html2canvas: {
              scale: 0.65,
              svgRendering: true,
            },
            callback: (doc) => {
              doc.save(`plan-${new Date().getTime()}.pdf`);
            },
          });
        }

        trackEvent({
          name: 'Download treatment plan',
          category: 'export-page',
          action: 'click-event',
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log({ isOpen });
  return (
    <div>
      <div
        className="inline-flex items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        Se behandlingsplan
      </div>
      <Modal
        open={isOpen}
        onClose={(s) => setIsOpen(s)}
        header={
          <div onClick={printDocument}>
            <FormButton
              loading={loading}
              className="inline-flex  items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center w-48 mb-8"
              title="PDF"
            />
          </div>
        }
      >
        <div id="print-plan-export" className="p-8 max-w-4xl">
          <TreatmentPlanContent data={data} />
        </div>
      </Modal>
    </div>
  );
};
