import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';

import { FormInput } from 'components/Form';
import { PatientInfoResponse } from 'interfaces';

interface ICPCProps {
  data: PatientInfoResponse;
}

export const ICPC: React.FC<ICPCProps> = ({ data }) => {
  const { patientDetails } = data;
  const { Icpc_Letter, Icpc_Number, Icps_Diagnosis } = patientDetails;

  const FIELDS = [
    { title: 'Bokstav', value: Icpc_Letter, name: 'Icpc_Letter' },
    { title: 'Nummerkode', value: Icpc_Number, name: 'Icpc_Number' },
    {
      title: 'Fritekst',
      value: Icps_Diagnosis,
      name: 'Icps_Diagnosis',
    },
  ];

  return (
    <Card>
      <CardFormText text="Skriv inn ICPC-2 kode, legg til fritekst om ønskelig" />
      {FIELDS.map(({ title, value, name }) => (
        <div key={title}>
          <div className="mb-6">
            <CardLabel text={title} size={CardLabelType.lg} />
          </div>
          <div className="mb-6">
            <FormInput
              id={`${name}`}
              name={`${name}`}
              type="text"
              className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
            />
          </div>
        </div>
      ))}
    </Card>
  );
};
