export const kneeMoveRomText = {
  label: {
    en: '',
    no: 'Reduserte bevegelsesutslag i',
  },
  values: [
    { value: 'KneeMoveRom_flex', text: 'Fleksjon', score: 0 },
    { value: 'KneeMoveRom_ext', text: 'Ekstensjon', score: 0 },
    {
      value: 'KneeMoveRom_not',
      text: 'Ikke reduserte bevegelsesutslag',
      score: 0,
    },
  ],
};
