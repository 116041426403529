export const medicTypeText = {
  values: [
    {
      value: 'paink',
      text: 'Paracetamol, Pinex, Panodil',
      score: 0,
    },
    {
      value: 'nsaid',
      text: 'Ibuprofen, Ibux, Ibumetin, nurofen, naproksen, proxan, voltarol',
      score: 0,
    },
    {
      value: 'gel',
      text: 'Voltarol, Voltarol forte gel, ibux gel, orudis gel',
      score: 0,
    },
    {
      value: 'forte',
      text: 'Pinex forte, paralgin forte, Tramadol',
      score: 0,
    },
    {
      value: 'coana',
      text: 'Sarotex, Noritren, Cymbalta',
      score: 0,
    },
    { value: 'other', text: 'Andre', score: 0 },
  ],
};
