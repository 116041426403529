export const activityText = {
  label: {
    en: 'Reduction in daily activity level',
    no: 'Generelt aktivitetsnivå',
  },
  hover: {
    en: '',
    no: 'Hvor redusert er ditt daglige aktivitetsnivå på grunn av smerter eller plager? Fire-delt skala fra ikke redusert til svært redusert. Pasientsvar: "actual score"',
  },
  values: [
    { value: 'vred', text: 'Svært redusert', score: 4 },
    { value: 'qred', text: 'Ganske redusert', score: 3 },
    { value: 'sred', text: 'Litt redusert', score: 2 },
    { value: 'nred', text: 'Ikke redusert', score: 1 },
  ],
};
