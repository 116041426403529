import { Card, CardLabel, CardLabelType } from 'components/Card';
import { FormInput } from 'components/Form';
import React from 'react';

interface TreatmentFreeTextProps {}

export const TreatmentFreeText: React.FC<
  TreatmentFreeTextProps
> = () => {
  return (
    <div className="w-1/2 ml-3 mr-3">
      <Card className="w-full">
        <div className="mb-6">
          <CardLabel
            text="Annen anbefalt behandling"
            size={CardLabelType.xl}
          />
          <div className="text-sm">
            blir synlig i pasientens behandlingsplan og del av
            journalnotat
          </div>
        </div>
        <div>
          <FormInput
            id="TreatFreeText"
            name="TreatFreeText"
            type="text"
            className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
            as="textarea"
            rows={3}
            placeholder="Fritekst behandlingsskjerm"
          />
        </div>
      </Card>
    </div>
  );
};
