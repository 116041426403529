import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardAnswers,
  CardProgressBar,
} from 'components/Card';
import { GROUP } from 'data';
import { HoverContent } from 'components/HoverContent';
import { replaceScore, variableChecker } from 'utils';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import { workAbilityText } from 'helpers/workAbility';
import { orebq8Text } from 'helpers/orebq8';
import { employmentConflictText } from 'helpers/employmentConflict';

interface WorkProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const Work: React.FC<WorkProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const { WorkAbility_work, EmploymentConflict, OREBQ8_Q } =
    patientDetails;

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          text={GROUP.work.title.no}
          size={CardLabelType.xl}
          className="mb-8"
        />
        <HoverContent
          content={replaceScore(
            workAbilityText.hover.no,
            10 - Number(WorkAbility_work),
          )}
        >
          <CopyText
            content={replaceScore(
              workAbilityText.hover.no,
              10 - Number(WorkAbility_work),
            )}
          >
            <CardProgressBar
              text={workAbilityText.label.no}
              progress={10 - Number(WorkAbility_work)}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 4]}
              disabled={variableChecker(WorkAbility_work)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            orebq8Text.hover.no,
            10 - Number(OREBQ8_Q),
          )}
        >
          <CopyText
            content={replaceScore(
              orebq8Text.hover.no,
              10 - Number(OREBQ8_Q),
            )}
          >
            <CardProgressBar
              text={orebq8Text.label.no}
              progress={10 - Number(OREBQ8_Q)}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[5, 7]}
              disabled={variableChecker(OREBQ8_Q)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            employmentConflictText.hover.no,
            EmploymentConflict === 'Y' ? 'Ja' : 'Nei',
          )}
        >
          <CopyText
            content={replaceScore(
              employmentConflictText.hover.no,
              EmploymentConflict,
            )}
          >
            <CardAnswers text={employmentConflictText.label.no}>
              {EmploymentConflict === 'Y' && (
                <div className="mb-1">
                  Opplever konflikt på arbeidsplass
                </div>
              )}
              {EmploymentConflict === 'N' && (
                <div className="mb-1">Ingen konflikt</div>
              )}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
