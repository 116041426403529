export const hipMoveRomText = {
  values: [
    { value: 'HipMoveRom_flex', text: 'Fleksjon', score: 0 },
    { value: 'HipMoveRom_ext', text: 'Ekstensjon', score: 0 },
    { value: 'HipMoveRom_abd', text: 'Abduksjon', score: 0 },
    { value: 'HipMoveRom_add', text: 'Adduksjon', score: 0 },
    {
      value: 'HipMoveRom_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'HipMoveRom_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    {
      value: 'HipMoveRom_not ',
      text: 'Ikke reduserte aktive bevegelsesutslag',
      score: 0,
    },
  ],
};
