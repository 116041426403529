import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { BoxFields } from 'components/BoxFields';
import { FormInput } from 'components/Form';
import { Baseline } from 'interfaces';
import { backMoveRomText } from 'helpers/BackMoveRom';
import { backMoveText } from 'helpers/BackMove';
import { backMovePainText } from 'helpers/BackMovePain';
import { backSlrText } from 'helpers/BackSlr';
import { backNeuroText } from 'helpers/BackNeuro';

interface BackProps {
  values: Baseline;
}

export const Back: React.FC<BackProps> = ({ values }) => {
  const {
    BackMove,
    BackMoveRom_flex,
    BackMoveRom_ext,
    BackMoveRom_rot,
    BackMoveRom_lat,
    BackMoveRom_not,
    BackMovePain_flex,
    BackMovePain_ext,
    BackMovePain_rot,
    BackMovePain_lat,
    BackMovePain_not,
    BackSlr,
    BackNeuro_nota,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
  } = values;

  // Conditional display fields
  const isBack = BackMove === 'Y';

  return (
    <Card>
      <CardFormText text="Ryggundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/ eller smertefulle bevegelser i stående stilling"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ BackMove }}
          keys={backMoveText.values}
        />
      </div>

      {isBack && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                BackMoveRom_flex,
                BackMoveRom_ext,
                BackMoveRom_rot,
                BackMoveRom_lat,
                BackMoveRom_not,
              }}
              keys={backMoveRomText.values}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              variables={{
                BackMovePain_flex,
                BackMovePain_ext,
                BackMovePain_rot,
                BackMovePain_lat,
                BackMovePain_not,
              }}
              keys={backMovePainText.values}
              square={true}
            />
          </div>
        </>
      )}

      <CardFormText text="Positiv Straight leg raise (Lasegues prøve)">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Prøven er positiv hvis de antatte nerverotsmertene reproduseres ved mindre enn 60º elevasjon, med utstråling nedenfor kneet"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            BackSlr,
          }}
          keys={backSlrText.values}
        />
      </div>

      <CardFormText text="Nevrologiske funn" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            BackNeuro_nota,
            BackNeuro_pow,
            BackNeuro_sens,
            BackNeuro_reflex,
          }}
          keys={backNeuroText.values}
          square={true}
        />
      </div>

      <CardFormText text="Klinisk undersøkelse fritekst:">
        <div className="mt-2 mb-4">
          <CardLabel text="" size={CardLabelType.sm} />
        </div>
      </CardFormText>
      <div className="mb-6">
        <FormInput
          id="BackComment"
          name="BackComment"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
