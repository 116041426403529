export const kneeMovePassivePainText = {
  values: [
    { value: 'KneeMovePassivePain_flex', text: 'Fleksjon', score: 0 },
    {
      value: 'KneeMovePassivePain_ext',
      text: 'Ekstensjon',
      score: 0,
    },
    {
      value: 'KneeMovePassivePain_not',
      text: 'Ingen smerter ved passive bevegelser',
      score: 0,
    },
  ],
};
