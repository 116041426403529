export const hipMovePassiveRomText = {
  values: [
    { value: 'HipMovePassiveRom_flex', text: 'Fleksjon', score: 0 },
    { value: 'HipMovePassiveRom_ext', text: 'Ekstensjon', score: 0 },
    { value: 'HipMovePassiveRom_abd', text: 'Abduksjon', score: 0 },
    { value: 'HipMovePassiveRom_add', text: 'Adduksjon', score: 0 },
    {
      value: 'HipMovePassiveRom_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePassiveRom_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePassiveRom_not ',
      text: 'Ingen smerter ved passive bevegelser',
      score: 0,
    },
  ],
};
