import React, { useContext, useEffect, useState } from 'react';
import * as JsSearch from 'js-search';

import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { useRouter } from 'hooks';
import { PATIENTS_TEMP } from 'components/Patients';
import { Helmet } from 'react-helmet';
import { UserContext } from 'context/userContext';
import { getAllPatients } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { PatientDetails, PatientInfoResponse } from 'interfaces';
import { patientConvert } from 'helpers';

const stemmer = require('porter-stemmer').stemmer;

interface ChangePatientProps {}

export const ChangePatient: React.FC<ChangePatientProps> = () => {
  const { setPatientIndex } = useContext(UserContext);
  const [data, setData] = useState<PatientInfoResponse[]>();
  const router = useRouter();
  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const username = localStorage.getItem('username');

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Velg patient`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  // Fetch users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';
        const query = username
          ? new URLSearchParams({
              clinicianId: username,
            })
          : '';
        const response = await fetch(
          `${process.env.REACT_APP_URL_CBR}/patient/getPatientByClinicianId?${query}`,
          {
            headers: {
              Authorization: token,
            },
          },
        );
        const json = await response.json();
        if (username === 'ui') {
          const patients = (await getAllPatients()) || [];
          setData(
            [...json, ...patients]
              .map((p: PatientInfoResponse) => patientConvert(p))
              .filter(
                (p: PatientInfoResponse) => p.patientDetails?.Age,
              )
              .sort((a, b) => {
                return (
                  Number(new Date(b.dateUpdated)) -
                  Number(new Date(a.dateUpdated))
                );
              }),
          );
        } else if (
          ['support200', 'support300'].includes(username || '')
        ) {
          setData(
            [...json]
              .filter((p) => {
                return !PATIENTS_TEMP.map((c) => c.id).includes(
                  p.patientId,
                );
              })
              .sort((a, b) => {
                return (
                  Number(new Date(a.dateUpdated)) -
                  Number(new Date(b.dateUpdated))
                );
              }),
          );
        } else {
          setData(
            [...json]
              .map((p: PatientInfoResponse) => patientConvert(p))
              .filter((p) => {
                return !PATIENTS_TEMP.map((c) => c.id).includes(
                  p.patientId,
                );
              }),
          );
        }
      } catch (error) {
        console.log('PatientsError', error);
      }
    };
    fetchData();
  }, []);

  if (!data) return null;

  const search = new JsSearch.Search('patientId');
  search.tokenizer = new JsSearch.StemmingTokenizer(
    stemmer, // Function should accept a string param and return a string
    new JsSearch.SimpleTokenizer(),
  );
  search.addIndex('patientId');
  search.addIndex('patientDetails.Age');

  search.addDocuments(data!);

  const handleInputSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    const result: any = search.search(value);
    setKeyword(value);
    setSearchResult(result);
  };

  const handleClickPatient = ({
    id,
    patientDetails,
  }: {
    id: string;
    patientDetails: PatientDetails;
  }) => {
    localStorage.setItem('user', `${id}`);
    if (patientDetails.clinicianGroup === 'control') {
      return router.history.push('/app/baseline-common');
    }
    router.history.push('/app');
  };

  return (
    <div>
      <Helmet>
        <title>{`SupportPrim - Velg pasient`}</title>
      </Helmet>
      <Card>
        <Input
          placeholder="Søk pasient"
          onChange={handleInputSearch}
        />
      </Card>
      <Card noSpacing={true} className="py-6 mb-6">
        <div className="flex px-6 mb-4">
          <div className="w-1/3 text-sm font-medium">Id</div>
          <div className="w-1/3 text-sm font-medium">Fødselsår</div>
          <div className="w-1/3 text-sm font-medium">
            Undersøkelse
          </div>
          <div className="w-[28px] text-sm font-medium"></div>
        </div>

        {(keyword ? searchResult : data).map(
          (
            {
              patientId,
              patientDetails,
              dateUpdated,
              similarPatients,
            },
            index,
          ) => {
            // consent?

            const consent_1 = 'jeg samtykker';
            const { Age, consultationDate, ExaDate } = patientDetails;
            if (!Age) return null;

            const date =
              consultationDate || ExaDate
                ? new Date(
                    String(consultationDate || ExaDate),
                  ).toLocaleDateString('no-No', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : '';

            return (
              <div
                className={`flex text-gray-800 hover:bg-gray-200 opacity hover:bg-opacity-25 py-3 px-6 cursor-pointer ${
                  localStorage.getItem('user') === patientId
                    ? 'font-bold text-indigo-600'
                    : ''
                }`}
                key={`${patientId}+${index}`}
                onClick={() => {
                  if (
                    consent_1?.toLowerCase() === 'jeg samtykker ikke'
                  ) {
                    alert('Pasient samtykker ikke');
                  } else {
                    handleClickPatient({
                      id: patientId,
                      patientDetails,
                    });
                  }
                }}
              >
                <div className="w-1/3 text-sm">
                  <div>
                    {patientId}
                    {/* <div className="text-gray-400 text-xs">
                      {patientDetails.Phenotype
                        ? `Phenotype: ${patientDetails.Phenotype}`
                        : ''}
                      {patientDetails.Diagnosis
                        ? ` - Diagnosis: ${patientDetails.Diagnosis}`
                        : ''}
                    </div> */}
                  </div>
                  {username === 'ui' &&
                  dateUpdated &&
                  Object.keys(similarPatients || {}).length >= 3 ? (
                    <div className="text-gray-600 font-normal">
                      {new Date(dateUpdated).toLocaleDateString(
                        'da-DK',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        },
                      )}
                    </div>
                  ) : null}

                  {(username === 'support300' ||
                    username === 'support200') && (
                    // Display created at date for a specific user
                    <div>
                      <div className="text-gray-600 font-normal">
                        {new Date(
                          data[index].dateUpdated,
                        ).toLocaleDateString('da-DK', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </div>
                      <div className="text-red-600 font-normal">
                        {Object.keys(data[index].similarPatients)
                          .length <= 0
                          ? 'Missing Similar Patients'
                          : ''}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-1/3 text-sm">
                  {Number(Age).toFixed(0)}
                </div>
                <div className="w-1/3 text-sm">{date}</div>
                <div className="w-[28px] text-sm text-right cursor-pointer hover:text-indigo-500">
                  {consent_1?.toLowerCase() === 'jeg samtykker ikke'
                    ? 'Paisenten har ikke samtykket til deltakelse.'
                    : 'Velg'}
                </div>
              </div>
            );
          },
        )}
      </Card>
    </div>
  );
};
