export const orebScoreText = {
  label: {
    en: '',
    no: 'Psykososiale faktorer',
  },
  hover: {
    en: '',
    no: 'Psykososiale risikofaktorer for utvikling av arbeidsnedsettelse eller uførhet på grunn av smerter målt med Örebro screeningskjema. 0 = Lav risiko, 100= Høy risiko, økt risiko over 50 poeng. Pasientsvar: "actual score"',
  },
};
