export const hipMovePainText = {
  values: [
    { value: 'HipMovePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'HipMovePain_ext', text: 'Ekstensjon', score: 0 },
    { value: 'HipMovePain_abd', text: 'Abduksjon', score: 0 },
    { value: 'HipMovePain_add', text: 'Adduksjon', score: 0 },
    {
      value: 'HipMovePain_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePain_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    {
      value: 'HipMovePain_not ',
      text: 'Ingen smerter ved aktive bevegelser',
      score: 0,
    },
  ],
};
