import React from 'react';
import { Field } from 'formik';
import clsx from 'clsx';

interface TreatmentBoxFieldProps {
  name?: string;
  disabled?: boolean;
}

export const TreatmentBoxField: React.FC<TreatmentBoxFieldProps> = ({
  name,
  disabled,
}) => {
  return (
    <Field
      className={clsx(
        'w-4 h-4 border-2 border-gray-500 form-checkbox',
        {
          'cursor-not-allowed bg-gray-100': disabled,
          'hover:bg-indigo-500 hover:border-transparent cursor-pointer':
            !disabled,
        },
      )}
      type="checkbox"
      name={name}
    />
  );
};
