import { comorbidityText } from './comorbidity';
import { genderText } from './gender';
import { lowercaseFirstLetter } from './lowercastFirstLetter';

export const journalText = {
  values: [
    // Smoking
    { value: 'Smoking_Y', text: 'Røyker', score: 0 },
    { value: 'Smoking_N', text: 'Røyker ikke', score: 0 },
    // Body
    { value: 'Bodymain_npain', text: 'Nakkesmerter', score: 0 },
    { value: 'Bodymain_spain', text: 'Skuldersmerter', score: 0 },
    { value: 'Bodymain_bpain', text: 'Ryggsmerter', score: 0 },
    { value: 'Bodymain_hpain', text: 'Hoftesmerter', score: 0 },
    { value: 'Bodymain_kpain', text: 'Knesmerter', score: 0 },
    {
      value: 'Bodymain_mpain',
      text: 'Utbredte smerteplager',
      score: 0,
    },
    ...comorbidityText.values.map((c) => ({
      ...c,
      value: `Comorbidity_${c.value}`,
    })),
    ...genderText.values,
  ],
};

export const journalYesNoText = (
  variables: any[],
  text = ['ja', 'nei'],
) => {
  if (variables.length <= 1) {
    variables.push(variables);
  }

  // Array answer
  if (
    Array.isArray(variables[0]) &&
    variables[0].filter((v) => v === 'Y').length >= 1
  ) {
    return text[0];
  }

  if (
    Array.isArray(variables[1]) &&
    variables[1].filter((v) => v === 'N').length >= 1
  ) {
    return text[1];
  }

  if (variables[0] === 'Y') {
    return text[0];
  }
  if (variables[1] === 'N' || variables[1] === 'nota') {
    return text[1];
  }
  return '';
};

export const journalComment = (variable = '') => {
  if (variable.trim().length >= 1) {
    return `<br/><br/> Klinisk undersøkelse fritekst: ${variable}`;
  }
  return '';
};

export const journalVariablesMapped = (
  values: {
    value: string;
    text: string;
    score: number;
  }[],
  variables: string[],
  text = '',
) => {
  const mapped = values
    .map((v) => v.text)
    .filter((_, i) => {
      return variables[i] === 'Y';
    })
    .map((t) => lowercaseFirstLetter(t))
    .join(', ');
  if (text === 'Pasienten angir at smertene sitter: ') {
    console.log(mapped);
  }
  return mapped.length >= 1 ? [text, mapped].join(' ') : '';
};

export const journalDescriptionText = (data: string[]) => {
  return data
    .map((t) => t.trim())
    .filter((t) => t)
    .join('. ');
};
