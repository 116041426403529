import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardFlag,
} from 'components/Card';
import { PatientInfoResponse } from 'interfaces';
import { redFlagsText } from 'helpers';

interface RedFlagsProps {
  className?: string;
  data?: PatientInfoResponse;
}

export const RedFlags: React.FC<RedFlagsProps> = ({
  className,
  data,
}) => {
  const {
    RedFlag_cance,
    RedFlag_nigsw,
    RedFlag_wloss,
    RedFlag_urin,
  } = data!.patientDetails;

  // Remember to check order in RED_FLAGS
  const flags: {
    [key: string]: string;
  } = {
    RedFlag_nigsw,
    RedFlag_cance,
    RedFlag_wloss,
    RedFlag_urin,
  };

  return (
    <div className={`${className ? className : ''}`}>
      <Card>
        <CardLabel
          className="mb-8"
          text="Røde Flagg"
          size={CardLabelType.xl}
        />
        {redFlagsText.values.map((flag, index) => (
          <CardFlag
            key={flag.text + index}
            flag={flags[flag.value] === 'Y'}
            text={flag.text}
            noSpacing={index === redFlagsText.values.length - 1}
          />
        ))}
      </Card>
    </div>
  );
};
