import { PatientInfoResponse } from 'interfaces';
import { PatientResponseInfo } from 'types';

export const getAllPatients = async (): Promise<
  PatientInfoResponse[] | undefined
> => {
  try {
    const token = localStorage.getItem('token') || '';
    return fetch(
      `${process.env.REACT_APP_URL_CBR}/patient/getAllPatients`,
      {
        headers: {
          Authorization: token,
        },
      },
    ).then((response) => {
      return response.json();
    });
    // return await Promise.all(
    //   Object.entries(PATIENTS_TEMP).map(async ([key, value]) => {
    //     const q = new URLSearchParams({
    //       patientId: value.id || value.patientId,
    //     });
    //     return fetch(
    //       `${process.env.REACT_APP_URL_CBR}/patient/getPatientById?${q}`,
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //       },
    //     )
    //       .then((response) => {
    //         return response.json();
    //       })
    //       .catch((err) => console.log(err));
    //   }),
    // );
  } catch (error) {}
};

export const getPatientArrayValues = async () => {
  try {
    const data = await getAllPatients();
    if (!data) return [];

    return data
      .map((p) => {
        if (!p) return null;
        const { patientId, patientDetails } = p;
        const entries = Object.entries(patientDetails)
          .map(([key, value]) => {
            if (
              Array.isArray(value) &&
              !['resources'].includes(key)
            ) {
              return `${key}: ${value}`;
            }

            return null;
          })
          .filter((b) => b);

        return {
          patientId,
          entries,
        };
      })
      .filter((p: any) => {
        return (p?.entries || []).length > 0;
      });
  } catch (error) {
    console.log('getPatientArrayValues', error);
  }
};
