export const qol15dSleepText = {
  label: {
    en: 'Sleep difficulties',
    no: 'Søvnvansker',
  },
  hover: {
    en: '',
    no: 'Quality of life 15D søvn. Fem-delt skala fra normal søvn til alvorlige søvnløshet. Pasientsvar: "actual score"',
  },
  values: [
    {
      value: 'norm',
      text: 'Jeg er i stand til å sove normalt, dvs. jeg har ingen problemer med å sove.',
      score: 1,
    },
    {
      value: 'sligh',
      text: 'Jeg har lette søvnproblemer, f.eks. vanskelig for å falle i søvn eller våkner av og til om natten.',
      score: 2,
    },
    {
      value: 'mod',
      text: 'Jeg har moderate søvnproblemer, f.eks. forstyrret søvn eller føler jeg ikke har sovet nok.',
      score: 3,
    },
    {
      value: 'great',
      text: 'Jeg har store søvnproblemer, f.eks.må bruke sovemedisiner ofte eller rutinemessig, eller våkner om natten og/ eller for tidlig om morgenen.',
      score: 4,
    },
    {
      value: 'sever',
      text: 'Jeg lider av alvorlig søvnløshet, f.eks. er søvn nesten umulig selv med bruk av sovemedisiner, eller jeg forblir våken det meste av natten.',
      score: 5,
    },
  ],
};
