export const kneeMovePainText = {
  values: [
    { value: 'KneeMovePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'KneeMovePain_ext ', text: 'Ekstensjon', score: 0 },
    {
      value: 'KneeMovePain_not',
      text: 'Ingen smerter ved aktive bevegelser',
      score: 0,
    },
  ],
};
