import React from 'react';
import {
  Card,
  CardAnswers,
  CardLabel,
  CardLabelType,
  CardProgressBar,
} from 'components/Card';
import { GROUP } from 'data';
import { replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import { orebq10qText } from 'helpers/orebq10q';
import { pseqScoreText } from 'helpers/pseqScore';
import { orebq7qText } from 'helpers/orebq7q';
import { expectationConsultationText } from 'helpers/expectationConsultation';

interface BeliefAndThoughtProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const BeliefAndThought: React.FC<BeliefAndThoughtProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    OREBQ10_Q,
    OREBQ7_Q,
    PSEQScore,
    ExpectationConsultat_advic,
    ExpectationConsultat_imag,
    ExpectationConsultat_medic,
    ExpectationConsultat_noexp,
    ExpectationConsultat_other,
    ExpectationConsultat_sleav,
  } = patientDetails;

  const expectationsConsultats = {
    ExpectationConsultat_advic,
    ExpectationConsultat_imag,
    ExpectationConsultat_medic,
    ExpectationConsultat_noexp,
    ExpectationConsultat_other,
    ExpectationConsultat_sleav,
  };

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text={GROUP.thoughts.title.no}
          size={CardLabelType.xl}
        />
        <HoverContent
          content={replaceScore(orebq10qText.hover.no, OREBQ10_Q)}
        >
          <CopyText
            content={replaceScore(orebq10qText.hover.no, OREBQ10_Q)}
          >
            <CardProgressBar
              text={orebq10qText.label.no}
              progress={Number(OREBQ10_Q)}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 5]}
              disabled={variableChecker(OREBQ10_Q)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            pseqScoreText.hover.no,
            12 - (Number(PSEQScore) || 0),
          )}
        >
          <CopyText
            content={replaceScore(
              pseqScoreText.hover.no,
              12 - (Number(PSEQScore) || 0),
            )}
          >
            <CardProgressBar
              text={pseqScoreText.label.no}
              progress={12 - (Number(PSEQScore) || 0)}
              parseProgressValue={(value) => (100 / 12) * value}
              min={0}
              max={12}
              indicators={[5, 7]}
              disabled={variableChecker(PSEQScore)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(orebq7qText.hover.no, OREBQ7_Q)}
        >
          <CopyText
            content={replaceScore(orebq7qText.hover.no, OREBQ7_Q)}
          >
            <CardProgressBar
              text={orebq7qText.label.no}
              progress={Number(OREBQ7_Q)}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 4]}
              disabled={variableChecker(OREBQ7_Q)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content="Forventninger til timen i dag"
          className="inline-block"
        >
          <CopyText
            content={`Forventninger til timen i dag: ${Object.entries(
              expectationsConsultats,
            )
              .map(([key, value]) => {
                return (
                  value &&
                  expectationConsultationText.values.find(
                    (v) => v.value === key.split('_')[1],
                  )?.text
                );
              })
              .filter((p) => p)}`}
          >
            <CardAnswers text="Forventninger til timen i dag">
              {Object.entries(expectationsConsultats).map(
                ([key, value]) => (
                  <div className="mb-1" key={key}>
                    {value &&
                      expectationConsultationText.values.find(
                        (v) => v.value === key.split('_')[1],
                      )?.text}
                  </div>
                ),
              )}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
