import React, { useState } from 'react';

import { Card, CardLabel, CardLabelType } from 'components/Card';

import { TreatmentBoxField } from './TreatmentBoxFields';
import {
  TREATMENT_ADVICE,
  TREATMENT_MEDICATION,
} from 'data/treatments';
import { PatientInfoResponse, TreatmentInfo } from 'interfaces';
import clsx from 'clsx';
import { HoverContent } from 'components/HoverContent';
import { TreatmentCardTitle } from './TreatmentCardTitle';
import { Modal } from 'components/Modal';

import { ReactComponent as ChrevronUp } from 'assets/svg/chrevron-up.svg';
import { ReactComponent as ChrevronDown } from 'assets/svg/chrevron-down.svg';

interface TreatmentCardProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
  values: TreatmentInfo;
  initialValues: TreatmentInfo;
  treatment: typeof TREATMENT_ADVICE | typeof TREATMENT_MEDICATION;
  toogleVisibility?: boolean;
}

export const TreatmentCard: React.FC<TreatmentCardProps> = ({
  className,
  cardClassName,
  data,
  treatment,
  values,
  initialValues,
  toogleVisibility,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<
    string[] | undefined
  >([]);
  const { Phenotype } = data.patientDetails;
  const title = treatment.title;
  const sections = treatment.variables;
  const prognoses = treatment.prognoses;
  return (
    <div className={`${className ? className : ''}`}>
      <Card className={clsx(cardClassName, 'p-6')} noSpacing={true}>
        <div className="flex flex-col space-y-4">
          <div className="flex">
            <CardLabel text={title} size={CardLabelType.xl} />
            {toogleVisibility && (
              <div
                className="ml-auto text-sm text-gray-500 cursor-pointer"
                onClick={() => setIsMinimized(!isMinimized)}
              >
                {isMinimized ? <ChrevronDown /> : <ChrevronUp />}
              </div>
            )}
          </div>
          <div className={clsx('space-y-4', { hidden: isMinimized })}>
            {prognoses.filter(
              (p) => p.rule(data.patientDetails).length >= 1,
            ).length >= 1 && (
              <div className="bg-alt p-3 rounded-md space-y-2">
                {prognoses.map((p) => {
                  const content = p.rule(data.patientDetails);
                  const hoverText = p.hoverText(data.patientDetails);
                  return content.length >= 1 ? (
                    <div>
                      <HoverContent
                        content={
                          hoverText.length >= 1 ? (
                            <>
                              <div className="mb-2 font-bold">
                                {title}
                              </div>
                              <ul className="list-disc">
                                {hoverText.map((t) => (
                                  <li className="mb-1 list-outside">
                                    {t}
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : null
                        }
                      >
                        <CardLabel
                          text={content.join('. ')}
                          size={CardLabelType.sm}
                        />
                      </HoverContent>
                      <input name={p.id} hidden />
                    </div>
                  ) : null;
                })}
              </div>
            )}

            <div className="flex flex-col space-y-2">
              {sections.map((s) => {
                const sortedData = s.data?.sort((a, b) => {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                });
                const phenoTypeColor = (
                  s.phenotype ? s.phenotype(data) : []
                ).find((p) => String(p.value) === Phenotype)?.color;

                return (
                  <>
                    {s.title && (
                      <div className="flex items-center">
                        {phenoTypeColor && (
                          <TreatmentCardTitle
                            color={phenoTypeColor}
                          />
                        )}
                        <div className="flex-1">
                          <HoverContent
                            content={
                              (s.hoverText || []).length >= 1 ? (
                                <>
                                  <div className="mb-2 font-bold">
                                    {s.title}
                                  </div>
                                  {(s.hoverText || []).map((t) => (
                                    <div className="mb-1">{t}</div>
                                  ))}
                                </>
                              ) : null
                            }
                          >
                            <div
                              className={clsx(
                                'flex justify-between items-center font-bold',
                              )}
                            >
                              <CardLabel
                                className="pr-4"
                                text={s.title}
                                size={CardLabelType.sm}
                              />
                              {s.id && (
                                <TreatmentBoxField
                                  name={s.id}
                                  key={s.id}
                                />
                              )}
                            </div>
                          </HoverContent>
                          {s.link && (
                            <div
                              className="italic text-sm cursor-pointer hover:text-indigo-500"
                              onClick={() => {
                                setModalContent(s.link);
                                setIsOpen(true);
                              }}
                            >
                              ({s.linkTitle})
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {s.values.length >= 1 ? (
                      <div
                        className={clsx('flex flex-col space-y-2', {
                          'ml-4': s.title,
                        })}
                      >
                        {s.values.map((t) => {
                          const phenoTypeColor = (
                            t.phenotype ? t.phenotype(data) : []
                          ).find(
                            (p) => String(p.value) === Phenotype,
                          )?.color;

                          return (
                            <div className="flex justify-between items-center">
                              <div className="flex items-center">
                                <TreatmentCardTitle
                                  color={phenoTypeColor}
                                />
                                <HoverContent
                                  content={
                                    t.hoverText.length >= 1 ? (
                                      <>
                                        <div className="mb-2 font-bold">
                                          {t.title}
                                        </div>
                                        {t.hoverText.map((t) => (
                                          <div className="mb-1">
                                            {t}
                                          </div>
                                        ))}
                                      </>
                                    ) : null
                                  }
                                >
                                  <div className="flex-1 text-sm">
                                    {t.title}
                                  </div>
                                </HoverContent>
                              </div>
                              <TreatmentBoxField
                                name={t.id}
                                key={t.id}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    <div>
                      {sortedData &&
                        sortedData.map((t) => {
                          let entries = [];

                          entries = Object.entries(
                            t.variables || {},
                          ).map(([key, f]) => {
                            // @ts-ignore
                            const v = data.patientDetails[key];
                            if (
                              typeof f.rule === 'function' &&
                              !Array.isArray(f)
                            ) {
                              return {
                                title: t.title,
                                key,
                                valid: ['Comorbidity_oa'].includes(
                                  key,
                                )
                                  ? f.rule(data.patientDetails)
                                  : f.rule(v),
                              };
                            }

                            return {
                              title: t.title,
                              key,
                              valid: false,
                            };
                          });

                          const isValid =
                            entries.filter((e) => e.valid).length >=
                            1;

                          return isValid ? (
                            <div className="flex justify-between items-center">
                              <HoverContent
                                content={
                                  t.hoverText.length >= 1 ? (
                                    <>
                                      <div className="mb-2 font-bold">
                                        {t.title}
                                      </div>
                                      <ul className="list-disc">
                                        {t.hoverText.map((t) => (
                                          <li className="mb-1 list-outside">
                                            {t}
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  ) : null
                                }
                              >
                                <div className="flex justify-between items-center">
                                  <TreatmentCardTitle color="green" />
                                  <div className="flex-1 text-sm">
                                    {t.title}
                                  </div>
                                </div>
                              </HoverContent>
                              <TreatmentBoxField
                                name={t.id}
                                key={t.id}
                              />
                            </div>
                          ) : null;
                        })}
                    </div>
                  </>
                );
              })}
            </div>

            <div>
              {false &&
                sections.map((s) => {
                  const sortedData = s.data?.sort((a, b) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  });
                  return (
                    <>
                      {sortedData &&
                        sortedData.map((t) => {
                          const entries = Object.entries(
                            t.variables || {},
                          ).map(([key, f]) => {
                            // @ts-ignore
                            const v = data.patientDetails[key];
                            if (typeof f.rule === 'function' && v) {
                              return {
                                title: t.title,
                                value: v,
                                type: t.ruleType,
                                rule: `${f.rule}`,
                                key,
                                valid: ['Comorbidity_oa'].includes(
                                  key,
                                )
                                  ? f.rule(data.patientDetails)
                                  : f.rule(v),
                              };
                            }

                            return {
                              title: t.title,
                              value: v,
                              type: t.ruleType,
                              rule: `${f.rule}`,
                              key,
                              valid: false,
                            };
                          });
                          const isValid =
                            entries.filter((e) => e.valid).length >=
                            1;

                          return (
                            <div
                              className={clsx('flex-1', {
                                'border border-green-500': isValid,
                                'border border-red-500': !isValid,
                              })}
                            >
                              <pre className="text-xs whitespace-pre-wrap">
                                {JSON.stringify(entries, null, 2)}
                              </pre>
                            </div>
                          );
                        })}
                      {/* <TreatmentBoxField
                           type="checkbox"
                           name={key}
                           key={key}
                           value={key}
                         /> */}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </Card>
      <Modal open={isOpen} onClose={(s) => setIsOpen(s)}>
        {(modalContent || []).map((content) => (
          <div
            className="leading-relaxed"
            dangerouslySetInnerHTML={{
              __html:
                content !== null && content !== undefined
                  ? content
                  : '',
            }}
          />
        ))}
      </Modal>
    </div>
  );
};
