import React from 'react';

import { TREATMENT_PLAN } from 'data/treatmentPlan';
import { PatientInfoResponse } from 'interfaces';

import comment from 'assets/images/comment.png';
import people from 'assets/images/people.png';
import pill from 'assets/images/pill.png';
import work from 'assets/images/work.png';

import clsx from 'clsx';

const icons = [
  { title: 'Råd og veiledning:', src: comment },
  { title: 'Arbeidsrettede tiltak:', src: work },
  { title: 'Medisinering:', src: pill },
  {
    title: 'Legen har anbefalt at du begynner i behandling hos:',
    src: people,
  },
];

interface TreatmentPlanContentProps {
  data: PatientInfoResponse;
}

export const TreatmentPlanContent: React.FC<
  TreatmentPlanContentProps
> = ({ data }) => {
  const clinicianId = data.patientDetails.clinicianId || '';
  return (
    <div className="space-y-8">
      <div className="text-sm">
        <div>Fastlege: {clinicianId}</div>
        <div>
          Dato:{' '}
          {new Date().toLocaleDateString('no-NO', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })}
        </div>
      </div>

      <div>
        <div className="font-bold text-4xl mb-2">Behandlingsplan</div>
        <div>
          Her er behandlingsplanen som du og din fastlege ble enige om
        </div>
      </div>
      {TREATMENT_PLAN.map((t) => {
        const isValid =
          (t.sections || [])
            .map((s) => s.data.filter((d) => d.rule(data)))
            .flat()
            .filter((t) => t).length >= 1;

        if (!isValid) {
          return null;
        }
        const icon = icons.find((i) => i.title === t.title)?.src;

        return (
          <div className="flex">
            <div className="w-8 mr-4">
              {icon && <img src={icon} alt="icon" />}
            </div>
            <div className="flex-1">
              <div
                className={clsx({
                  'mb-2': t.title || t.subtitle,
                })}
              >
                <div className="font-bold text-2xl">{t.title}</div>
                <div>{t.subtitle}</div>
              </div>
              <div className="space-y-4">
                {(t.sections || [])
                  .sort((a, b) => {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((s) => {
                    const isValidData =
                      s.data.filter((d) => d.rule(data)).length >= 1;

                    if (!isValidData) {
                      return null;
                    }

                    return (
                      <div>
                        <div className="font-bold text-gray-800">
                          {/* {s.title || s.id} */}
                          {s.title}
                        </div>
                        {s.id === 'TreatFreeText' &&
                        (data.patientDetails?.TreatFreeText || '')
                          .length >= 1
                          ? data.patientDetails.TreatFreeText
                          : ''}
                        <ul className="list-disc space-y-4">
                          {s.data.map((d) => {
                            const isValidRule = d.rule(data);
                            if (!isValidRule) {
                              return null;
                            }

                            return d.text.map((dt, i) => {
                              return (
                                <li className="ml-6 list-outside">
                                  {dt}
                                  <div>
                                    <a
                                      href={d.links[i]}
                                      target="_blank"
                                      className="inline text-blue-500 underline hover:text-black text-sm"
                                      rel="noreferrer"
                                    >
                                      {d.links[i]}
                                    </a>
                                  </div>
                                </li>
                              );
                            });
                          })}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
