import React from 'react';
import { CardLabel, CardLabelType, Card } from 'components/Card';
import { HoverContent } from 'components/HoverContent';
import { CircleProgressBar } from 'components/CircleProgressBar';
import { replaceScore, variableChecker } from 'utils';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import { orebScoreText } from 'helpers/orebScore';

interface OrebroProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
  animation?: boolean;
}

export const Orebro: React.FC<OrebroProps> = ({
  className,
  cardClassName,
  data,
  animation = true,
}) => {
  const { patientDetails } = data;
  const { OREBScore } = patientDetails;

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <div className="flex flex-col flex-1 items-center justify-center">
          <HoverContent
            content={replaceScore(orebScoreText.hover.no, OREBScore)}
          >
            <CopyText
              content={replaceScore(
                orebScoreText.hover.no,
                OREBScore,
              )}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <div className="inline-block mb-4">
                  <CircleProgressBar
                    value={Number(OREBScore)}
                    indicators={[45, 54]}
                    animation={animation}
                  />
                </div>
                <CardLabel
                  text={orebScoreText.label.no}
                  size={CardLabelType.lg}
                  className={`${
                    variableChecker(OREBScore)
                      ? 'opacity opacity-25'
                      : ''
                  }`}
                />
              </div>
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
