export const bodyMainText = {
  values: [
    { value: 'npain', text: 'Nakke', score: 1 },
    { value: 'spain', text: 'Skulder', score: 2 },
    { value: 'bpain', text: 'Rygg', score: 3 },
    { value: 'hpain', text: 'Hofte', score: 4 },
    { value: 'kpain', text: 'Kne', score: 5 },
    {
      value: 'mpain',
      text: 'Utbredte plager/ sammensatt',
      score: 6,
    },
  ],
};
