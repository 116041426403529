export const comorbidityText = {
  label: {
    en: 'Comorbidities',
    no: 'Komorbiditet',
  },
  hover: {
    en: '',
    no: 'Andre nåværende sykdommer eller plager',
  },
  values: [
    {
      value: 'not',
      text: 'Tidligere sykdommer: ingen',
      score: 0,
    },
    { value: 'ra', text: 'Revmatisk sykdom', score: 0 },
    { value: 'oa', text: 'Artrose/slitasjegikt', score: 0 },
    { value: 'heart', text: 'Hjertesykdom', score: 0 },
    { value: 'asthm', text: 'Astma/ lungesykdom', score: 0 },
    {
      value: 'neuro',
      text: 'Nevrologisk sykdom (f.eks tidligere hjerneslag eller MS)',
      score: 0,
    },
    { value: 'cance', text: 'Kreft/ tidligere kreft', score: 0 },
    { value: 'psych', text: 'Psykiske plager', score: 0 },
    { value: 'demen', text: 'Demens/ kognitiv svikt', score: 0 },
    { value: 'osteop', text: 'Osteoporose', score: 0 },
    { value: 'diab', text: 'Diabetes', score: 0 },
    { value: 'stopa', text: 'Magesmerter', score: 0 },
    { value: 'heada', text: 'Hodepine/svimmelhet', score: 0 },
    { value: 'fatig', text: 'Utmattelse', score: 0 },
  ],
};
