export const kneeMovePassiveRomText = {
  values: [
    { value: 'KneeMovePassiveRom_flex', text: 'Fleksjon', score: 0 },
    {
      value: 'KneeMovePassiveRom_ext',
      text: 'Ekstensjon ',
      score: 0,
    },
    {
      value: 'KneeMovePassiveRom_not',
      text: 'Ikke reduserte bevegelsesutslag',
      score: 0,
    },
  ],
};
