import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { ReactComponent as Close } from 'assets/svg/close.svg';

interface ModalProps {
  title?: string;
  description?: string;
  open?: boolean;
  onClose: (x: boolean) => void;
  header?: JSX.Element;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  description,
  open,
  onClose,
  header,
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        open={open}
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={(value) => onClose(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl space-y-4">
              <Dialog.Title>{title}</Dialog.Title>
              <Dialog.Description>{description}</Dialog.Description>
              <div className="flex">
                <div>{header}</div>
                <div
                  className="ml-auto cursor-pointer hover:opacity-50"
                  onClick={() => onClose(false)}
                >
                  <Close />
                </div>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
