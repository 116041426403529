export const employmentConflictText = {
  label: {
    en: 'Konflikt på arbeidsplass',
    no: 'Konflikt på arbeidsplass',
  },
  hover: {
    en: '',
    no: 'Opplever du å ha en konflikt på din arbeidsplass? Pasientsvar: "actual score"',
  },
  values: {},
};
