export const backMovePainText = {
  values: [
    { value: 'BackMovePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'BackMovePain_ext', text: 'Ekstensjon', score: 0 },
    { value: 'BackMovePain_rot', text: 'Rotasjon', score: 0 },
    { value: 'BackMovePain_lat', text: 'Lateralfleksjon', score: 0 },
    {
      value: 'BackMovePain_not',
      text: 'Ingen smerter ved aktive bevgelsesutslag',
      score: 0,
    },
  ],
};
