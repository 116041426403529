import React from 'react';
import { CardLabel, CardLabelType, Card } from 'components/Card';
import { HoverContent } from 'components/HoverContent';
import { replaceScore, variableChecker } from 'utils';
import { VerticalBar } from 'components/Charts';
import { CopyText } from 'components/CopyText';
import { PatientInfoResponse } from 'interfaces';
import { mskqhqScoreText } from 'helpers/mskqhqScore';

interface MSKHQProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const MSKHQ: React.FC<MSKHQProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const { MSKHQScore } = patientDetails;

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <div className="flex flex-col flex-1 items-center justify-center">
          <HoverContent
            content={replaceScore(
              mskqhqScoreText.hover.no,
              MSKHQScore,
            )}
          >
            <CopyText
              content={replaceScore(
                mskqhqScoreText.hover.no,
                MSKHQScore,
              )}
            >
              <div className="flex flex-col items-center justify-center h-full ">
                <div className="inline-block h-20 mb-4 relative">
                  <VerticalBar
                    progress={Number(MSKHQScore)}
                    max={56}
                    indicators={[29, 37]}
                  />
                  <span className="absolute top-0 ml-6 text-sm">
                    God
                  </span>
                  <span className="absolute bottom-0 ml-6 text-sm">
                    Dårlig
                  </span>
                </div>
                <CardLabel
                  text={mskqhqScoreText.label.no}
                  size={CardLabelType.lg}
                  className={`${
                    variableChecker(MSKHQScore)
                      ? 'opacity opacity-25'
                      : ''
                  }`}
                />
              </div>
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
