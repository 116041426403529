export const pseqScoreText = {
  label: {
    en: 'Pain self-efficacy',
    no: 'Smertemestring',
  },
  hover: {
    en: '',
    no: 'Pain self-efficacy sumscore av to spørsmål. 0= God smertemestring, 12= Lav smertemestring. Pasientsvar: "actual score".',
  },
  values: [],
};
