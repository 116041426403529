export const healthLitInfoText = {
  hover: {
    en: '',
    no: 'Har du vanskelig for å forstå helseinformasjon som du mottar? Pasientsvar: "actual score"',
  },
  values: [
    { value: 'neve', text: 'Aldri', score: 1 },
    { value: 'rare', text: 'Sjelden', score: 2 },
    { value: 'occas', text: 'Av og til', score: 3 },
    { value: 'often', text: 'Ofte', score: 4 },
    { value: 'alwa', text: 'Alltid', score: 5 },
  ],
};
