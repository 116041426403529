export const neckMovePainText = {
  values: [
    { value: 'NeckMovePain_flex', text: 'Fleksjon', score: 0 },
    { value: 'NeckMovePain_ext', text: 'Ekstensjon', score: 0 },
    { value: 'NeckMovePain_rot', text: 'Rotasjon', score: 0 },
    { value: 'NeckMovePain_lat', text: 'Lateralfleksjon', score: 0 },
    {
      value: 'NeckMovePain_not',
      text: 'Ingen smerter ved aktive bevegelsesutslag',
      score: 0,
    },
  ],
};
