import React from 'react';

import {
  Card,
  CardAnswers,
  CardLabel,
  CardLabelType,
} from 'components/Card';
import { PatientInfoResponse } from 'interfaces';
import { HoverContent } from 'components/HoverContent';
import { redFlagsText } from 'helpers';
import { TreatmentFactorField } from './TreatmentFactorField';
import { lowercaseFirstLetter } from 'helpers/lowercastFirstLetter';

type ClinicalFindingValue = {
  value: string;
  text: string;
};

interface ClinicalFindings {
  title: string;
  values: ClinicalFindingValue[];
}

interface TreatmentFactorsProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const TreatmentFactors: React.FC<TreatmentFactorsProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const {
    RedFlag_nigsw,
    RedFlag_cance,
    RedFlag_wloss,
    RedFlag_urin,
    NeckSpurling,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckConclusion,
    ShoulderPassiveExtrot,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
    BackSlr,
    KneeHydrops,
    BodyMainTrauma,
  } = data.patientDetails;
  const redFlags = [
    RedFlag_nigsw,
    RedFlag_cance,
    RedFlag_wloss,
    RedFlag_urin,
  ];
  const isRedFlag = redFlags.filter((r) => r === 'Y').length >= 1;
  // Remember to check order in RED_FLAGS
  const flags: {
    [key: string]: string;
  } = {
    RedFlag_nigsw,
    RedFlag_cance,
    RedFlag_wloss,
    RedFlag_urin,
  };

  const clinicalFindings = {
    NeckSpurling,
    NeckNevro_pow,
    NeckNevro_sens,
    NeckNevro_reflex,
    NeckConclusion,
    ShoulderPassiveExtrot,
    BackSlr,
    BackNeuro_pow,
    BackNeuro_sens,
    BackNeuro_reflex,
    KneeHydrops,
  };

  const isClinicalFindings =
    Object.values(clinicalFindings).filter((r) =>
      [
        'Y',
        'acute',
        'gradu',
        'oth',
        'nevrotaff',
        'sevnevrotaff',
      ].includes(r),
    ).length >= 1;

  const clinicalFindingsText: ClinicalFindings[] = [
    {
      title: 'Nakke',
      values: [
        { value: 'NeckSpurling', text: 'Positiv spurlingstest' },
        { value: 'NeckNevro_pow', text: 'Redusert kraft' },
        { value: 'NeckNevro_sens', text: 'Redusert sensibilitet' },
        { value: 'NeckNevro_reflex', text: 'Unormale reflekser' },
        {
          value: 'NeckConclusion_nevrotaff',
          text: 'Tegn på nerverot affeksjon',
        },
        {
          value: 'NeckConclusion_sevnevrotaff',
          text: 'Tegn på alvorlig patologisk forandring',
        },
      ].filter((v) => {
        let value = v.value as keyof typeof clinicalFindings;
        const keys = v.value.split('_');
        const key = keys[0];

        switch (key) {
          case 'NeckSpurling':
            return clinicalFindings[value] === 'Y';
          case 'NeckNevro':
            return clinicalFindings[value] === 'Y';
          case 'NeckConclusion':
            // nevrotaff & sevnevrotaff
            return clinicalFindings['NeckConclusion'] === keys[1];
        }

        return false;
      }),
    },
    {
      title: 'Skulder',
      values: [
        {
          value: 'ShoulderPassiveExtrot',
          text: 'Redusert passiv utadrotasjon mer enn 50% sammenlignet med motsatt side',
        },
      ].filter(
        (v) =>
          clinicalFindings[
            v.value as keyof typeof clinicalFindings
          ] === 'Y',
      ),
    },
    {
      title: 'Rygg',
      values: [
        { value: 'BackSlr', text: 'Positiv Straight Leg Raise' },
        { value: 'BackNeuro_pow', text: 'Redusert kraft' },
        { value: 'BackNeuro_sens', text: 'Redusert sensibilitet' },
        { value: 'BackNeuro_reflex', text: 'Unormale reflekser' },
      ].filter((v) => {
        let value = v.value as keyof typeof clinicalFindings;
        const keys = v.value.split('_');
        const key = keys[0];

        switch (key) {
          case 'BackSlr':
            return clinicalFindings[value] === 'Y';
          case 'BackNeuro':
            return clinicalFindings[value] === 'Y';
        }

        return false;
      }),
    },
    {
      title: 'Kne',
      values: [{ value: 'KneeHydrops', text: 'Kne hydrops' }].filter(
        (v) =>
          clinicalFindings[
            v.value as keyof typeof clinicalFindings
          ] === 'Y',
      ),
    },
  ].filter((v) => v.values.length >= 1);

  const isTrauma = BodyMainTrauma === 'traum';
  return (
    <div className={`${className ? className : ''}`}>
      <Card
        className={`${cardClassName ? cardClassName : ''} p-6`}
        noSpacing={true}
      >
        <div className="flex">
          <div className="">
            <CardLabel
              text="Spesifikke faktorer"
              size={CardLabelType.xl}
            />

            <CardAnswers text="Med betydning for anbefaling av behandling">
              {isRedFlag ? (
                <div className="flex">
                  <div className="mr-1">
                    <TreatmentFactorField title="Røde flagg - " />
                  </div>
                  <div>
                    {redFlagsText.values
                      .filter((flag) => {
                        return flags[flag.value] === 'Y';
                      })
                      .map((f) => lowercaseFirstLetter(f.text))
                      .join(', ')}
                  </div>
                </div>
              ) : (
                <TreatmentFactorField
                  title="Røde flagg"
                  disabled={true}
                />
              )}

              {isClinicalFindings ? (
                <div className="flex">
                  <div className="mr-1">
                    <TreatmentFactorField title="Kliniske funn - " />
                  </div>
                  <div>
                    {clinicalFindingsText.map((v) => (
                      <div>
                        <div>
                          {v.title}:{' '}
                          {v.values
                            .map((v) => lowercaseFirstLetter(v.text))
                            .join(', ')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <TreatmentFactorField
                  title="Kliniske funn"
                  disabled={true}
                />
              )}

              {isTrauma ? (
                <HoverContent content="Pasienten har vært utsatt for traume">
                  <TreatmentFactorField title="Traume" />
                </HoverContent>
              ) : (
                <TreatmentFactorField
                  title="Traume"
                  disabled={true}
                />
              )}
            </CardAnswers>
          </div>
        </div>
      </Card>
    </div>
  );
};
