import React from 'react';
import {
  Card,
  CardAnswers,
  CardLabel,
  CardLabelType,
  CardProgressBar,
} from 'components/Card';
import { GROUP } from 'data';
import { replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';

import { CopyText } from 'components/CopyText';
import { PainBody } from 'components/PainBody';
import { PatientInfoResponse } from 'interfaces';
import { getMappedValue } from 'helpers';
import { orebq1qText } from 'helpers/orebq1q';
import { orebq2Text } from 'helpers/orebq2';
import { painContinuousText } from 'helpers/painContinuous';
import { medicTypeText } from 'helpers/medicType';

interface PainProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const Pain: React.FC<PainProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    OREBQ1,
    OREBQ2_Q,
    PainSites_ancle,
    PainSites_elbo,
    PainSites_head,
    PainSites_hip,
    PainSites_knee,
    PainSites_lback,
    PainSites_neck,
    PainSites_shoul,
    PainSites_uback,
    PainSites_wrist,
    PainsiteNumber,
    PainContinuous,
    Medic,
    Medictype_paink,
    Medictype_nsaid,
    Medictype_gel,
    Medictype_forte,
    Medictype_coana,
    Medictype_other,
  } = patientDetails;

  const mapped_orebq1q = getMappedValue(orebq1qText.values, OREBQ1);
  const mapped_painContinuous = getMappedValue(
    painContinuousText.values,
    PainContinuous,
  );
  const painsites = {
    PainSites_ancle,
    PainSites_elbo,
    PainSites_head,
    PainSites_hip,
    PainSites_knee,
    PainSites_lback,
    PainSites_neck,
    PainSites_shoul,
    PainSites_uback,
    PainSites_wrist,
  };

  const medicTypes = [
    Medictype_paink,
    Medictype_nsaid,
    Medictype_gel,
    Medictype_forte,
    Medictype_coana,
    Medictype_other,
  ];

  let mapped_medicTypes = medicTypeText.values
    .map((v, i) => {
      return medicTypes[i] === 'Y' ? v.text : '';
    })
    .filter((a) => a);

  if (Medic === 'N') {
    mapped_medicTypes = ['Bruker ikke smertestillende'];
  }

  return (
    <div className={`${className ? className : ''}`}>
      <Card
        noSpacing={true}
        className={`${cardClassName ? cardClassName : ''}`}
      >
        <CardLabel
          className="mb-8"
          text={GROUP.pain.title.no}
          size={CardLabelType.xl}
        />
        <div>
          <HoverContent
            content={`${replaceScore(
              orebq2Text.hover.no,
              OREBQ2_Q,
            )} ${mapped_painContinuous.text || ''}`}
          >
            <CopyText
              content={`${replaceScore(
                orebq2Text.hover.no,
                OREBQ2_Q,
              )} ${mapped_painContinuous.text || ''}`}
            >
              <CardProgressBar
                text={orebq2Text.label.no}
                progress={Number(OREBQ2_Q)}
                parseProgressValue={(value) => (100 / 10) * value}
                min={0}
                max={10}
                indicators={[4, 7]}
                disabled={variableChecker(OREBQ2_Q)}
              />
            </CopyText>
          </HoverContent>

          <HoverContent
            content={`${replaceScore(
              orebq1qText.hover.no,
              mapped_orebq1q.text,
            )}`}
          >
            <CopyText
              content={replaceScore(
                orebq1qText.hover.no,
                mapped_orebq1q.text,
              )}
            >
              <CardProgressBar
                text={orebq1qText.label.no}
                progress={mapped_orebq1q.score}
                parseProgressValue={(value) => {
                  if (value <= 4) {
                    return 3;
                  }

                  if (value <= 6) {
                    return 25;
                  }

                  if (value === 7) {
                    return 50;
                  }

                  if (value <= 9) {
                    return 75;
                  }

                  return 100;
                }}
                min={1}
                median={7}
                max={10}
                indicators={[6, 7]}
                disabled={variableChecker(OREBQ1)}
              />
            </CopyText>
          </HoverContent>

          <HoverContent
            content={mapped_medicTypes.join(',')}
            className="inline-block"
          >
            <CopyText content={mapped_medicTypes.join(',')}>
              <CardAnswers text="Smertestillende medisiner i bruk">
                {mapped_medicTypes.map((value) => (
                  <div className="mb-1">{value}</div>
                ))}
              </CardAnswers>
            </CopyText>
          </HoverContent>

          <div>
            <HoverContent
              content={replaceScore(
                GROUP.pain.Painsite_1.hover.no,
                PainsiteNumber,
              )}
              className="inline-block"
            >
              <CopyText
                content={replaceScore(
                  GROUP.pain.Painsite_1.hover.no,
                  PainsiteNumber,
                )}
              >
                <CardLabel
                  text="Smertesteder"
                  className="mb-5 inline-block"
                  size={CardLabelType.lg}
                />
              </CopyText>
            </HoverContent>
          </div>
          <div className="flex items-center justify-center flex-1">
            <PainBody data={painsites} />
          </div>
        </div>
      </Card>
    </div>
  );
};
