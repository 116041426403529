import { PatientInfoResponse } from 'interfaces';
import { treatmentJournalLong } from './treatmentJournalLong';

export const treatmentJournalShort = (data: PatientInfoResponse) => {
  return [
    ...treatmentJournalLong(data).filter((t) =>
      [
        'problem-01',
        'subject-01',
        'subject-02',
        'subject-03',
        'subject-04',
        'subject-05',
        'subject-08',
        'subject-09',
        'objectiv-01',
        'analyze-01',
        'freetext-01',
      ].includes(t.id || ''),
    ),
  ];
};
