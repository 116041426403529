import React from 'react';

interface TreatmentJournalContentProps {
  data: {
    title: string | undefined;
    text: string;
  }[];
}

export const TreatmentJournalContent: React.FC<
  TreatmentJournalContentProps
> = ({ data }) => {
  return (
    <div className="space-y-4">
      {data.map((t) => {
        if (t.text.length <= 0) {
          return null;
        }
        return (
          <div>
            <div className="font-bold">{t.title}</div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  t.text !== null && t.text !== undefined
                    ? t.text
                    : '',
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
