export const shoulderMoveRomText = {
  values: [
    { value: 'ShoulderMoveRom_flex', text: 'Fleksjon', score: 0 },
    { value: 'ShoulderMoveRom_ext', text: 'Ekstensjon', score: 0 },
    { value: 'ShoulderMoveRom_abd', text: 'Abduksjon', score: 0 },
    { value: 'ShoulderMoveRom_add', text: 'Adduksjon', score: 0 },
    {
      value: 'ShoulderMoveRom_extrot',
      text: 'Utadrotasjon',
      score: 0,
    },
    {
      value: 'ShoulderMoveRom_introt',
      text: 'Innadrotasjon',
      score: 0,
    },
    {
      value: 'ShoulderMoveRom_not',
      text: 'Ikke reduserte bevegelsesutslag',
      score: 0,
    },
  ],
};
