import React, { useContext, useEffect, useState } from 'react';
import { CONTROL_GROUP, IBaselinePatient } from 'data';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { ComparisonPatients } from './ComparisonPatients';
import { ComparisonCommon } from './ComparisonCommon';
import { ComparisonPatient } from './ComparisonPatient';
import { Helmet } from 'react-helmet';
import { patientMapping } from 'utils';
import { UserContext } from 'context/userContext';

interface ComparisonProps {
  data: PatientResponseInfo;
}

interface CaseProps {
  patients: IBaselinePatient[];
}

const Case: React.FC<CaseProps> = ({ patients }) => {
  // const scatterLineRef = useRef<any[]>([]);
  // const [patientIndex, setPatientIndex] = useState<number>(0);
  const { patientIndex, setPatientIndex } = useContext(UserContext);
  const [legendIndex, setLegendIndex] = useState<boolean[]>(
    Array(4).fill(true),
  );
  const [restrict, setRestricted] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch users
  useEffect(() => {
    // Adapt UI in WP3 for stepwise role out in control group
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';
        const currentPatient = localStorage.getItem('user') || '';
        const username = currentPatient.split('_')[0];

        if (
          ['support200', 'support300', ...CONTROL_GROUP].includes(
            username,
          )
        ) {
          const query = username
            ? new URLSearchParams({
                clinicianId: username,
              })
            : '';
          const response = await fetch(
            `${process.env.REACT_APP_URL_CBR}/patient/getPatientByClinicId?${query}`,
            {
              headers: {
                Authorization: token,
              },
            },
          );
          const res: PatientResponseInfo[] = await response.json();
          // Sort by date created at
          const sortByUpdatedAt = (
            a: PatientResponseInfo,
            b: PatientResponseInfo,
          ) =>
            new Date(a.dateUpdated).getTime() -
            new Date(b.dateUpdated).getTime();

          // sort() mutates which are why we make a new copy with object.assign()
          const sortedRes = Object.assign(res, {}).sort(
            sortByUpdatedAt,
          );

          const firstSixSimilarPatientsSaved = sortedRes
            .filter((p) => Object.keys(p.similarPatients).length >= 3)
            .slice(0, 6);

          // Restrict dashboard for the first saved 6 patients
          if (
            firstSixSimilarPatientsSaved
              .map((p) => p.patientId)
              .includes(currentPatient)
          ) {
            setRestricted(true);
          }

          // Restrict all patients in the dashboard if 6 similar patients are not saved yet
          if (firstSixSimilarPatientsSaved.length < 6) {
            setRestricted(true);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log('PatientsError', error);
      }
    };
    fetchData();
  }, []);

  const { trackEvent } = useMatomo();

  const handleClickPatient = (index: number) => {
    // track patient
    trackEvent({
      name: `Tab Click - Change to Patient ${
        ['Current', 'A', 'B', 'C'][index]
      }`,
      category: 'patient-matching-page',
      action: 'click-event',
    });
    setPatientIndex(index);
  };

  const handleLegendClick = (i: number) => {
    // Set legend index
    let updatedLegendIndex = [...legendIndex];
    updatedLegendIndex[i] = !updatedLegendIndex[i];
    setLegendIndex(updatedLegendIndex);
  };

  const data = restrict ? [patients[0]] : patients;

  if (loading) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>{`SupportPrim - Pasientmatching`}</title>
      </Helmet>
      <ComparisonPatients
        patients={data}
        patientIndex={patientIndex}
        onClick={handleClickPatient}
      />
      {patientIndex >= 1 ? (
        <ComparisonPatient
          patientIndex={patientIndex}
          patients={patients}
        />
      ) : (
        <ComparisonCommon
          patients={data}
          legendIndex={legendIndex}
          handleLegendClick={handleLegendClick}
        />
      )}
    </div>
  );
};

export const Comparison: React.FC<ComparisonProps> = ({ data }) => {
  const [similarCases, setSimilarCases] =
    useState<PatientResponseInfo[]>();

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Pasientmatching`,
      href: `${process.env.REACT_APP_MATAMO_URL}${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  // Fetch similar cases
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';
        const { similarPatients } = data;
        const similarCasesWithContent = Object.entries(
          similarPatients,
        )
          .map(([key, value]) => ({
            patient_id: key,
            similar: value,
          }))
          .sort((a, b) => (a.similar < b.similar ? 1 : -1));
        // const query = new URLSearchParams({
        //   casebase: 'cb_all',
        //   concept: 'patient',
        //   k: '3', // desired number of similar cases
        //   // similarityFunction: 'ism_knowledge_based',
        //   similarityFunction: 'ism_knowledge_based',
        // });
        // // keys used for similarity matching.
        // const caseKeys = Object.keys(
        //   CASE_STRUCTURE.activeAttributesWithWeights,
        // );
        // const body = JSON.stringify(
        //   convertToCase(data.patientDetails, caseKeys),
        // );

        // // Get similar patients data
        // const { similarCasesWithContent } = await fetch(
        //   `${process.env.REACT_APP_URL_CBR}/cbr/retrieveSimilarCasesWithContent?${query}`,
        //   {
        //     method: 'POST',
        //     body,
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: token,
        //     },
        //   },
        // ).then((response) => response.json());

        // Fetch all the details for similar patients
        const response: PatientResponseInfo[] = await Promise.all(
          similarCasesWithContent.map(
            ({ patient_id }: { patient_id: string }) => {
              return fetch(
                `${
                  process.env.REACT_APP_URL_CBR
                }/patient/getPatientById?${new URLSearchParams({
                  patientId: patient_id,
                })}`,
                {
                  headers: {
                    Authorization: token,
                  },
                },
              )
                .then((response) => response.json())
                .catch((err) => console.log(err));
            },
          ),
        );

        // Make sure they are in ascending order
        setSimilarCases(response);
      } catch (error) {
        console.log('PatientsError', error);
      }
    };
    fetchData();
  }, []);

  if (!data || !similarCases) return null;
  const patients = patientMapping(data, similarCases);
  return <Case patients={patients} />;
};
