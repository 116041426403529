import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { savePatientBaseline } from 'services';

interface TestingSaveSimilarPatientProps {}

export const TestingSaveSimilarPatient: React.FC<
  TestingSaveSimilarPatientProps
> = () => {
  const router = useHistory();
  const user = localStorage.getItem('user');

  useEffect(() => {
    const save = async () => {
      try {
        await savePatientBaseline([user!]);
        router.push('/app');
      } catch (error) {
        console.log('TestingSaveSimilarPatient', error);
      }
    };
    save();
  });

  return <div>Saving Similar Patient...Please wait</div>;
};
