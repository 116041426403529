export const orebq8Text = {
  label: {
    en: '',
    no: 'Forventninger om arbeid om 3 mnd',
  },
  hover: {
    en: '',
    no: 'Ut fra din vurdering, hvor stor er sjansen for at du er i arbeid om TRE MÅNEDER? 0=Svært stor sjanse, 10= Ingen sjanse. Pasientsvar: "actual score"',
  },
  values: [],
};
