import { TextValueType } from 'interfaces';

// Match variable value with corresponding text, value, score
export const getMappedValue = (
  values: TextValueType[],
  value: any,
) => {
  return (
    values.find((v) => v.value === value) || {
      value: '',
      text: '',
      score: 0,
    }
  );
};
