export const hscleScoreText = {
  label: {
    en: 'Psychologic distress sum score',
    no: 'Psykisk belastning',
  },
  hover: {
    en: '',
    no: 'Symptomer på psykisk stress målt ved Hopkins Symptom Check-list. Skala fra 1.0 til 4.0. Over 1,85 er tegn på symptomer på psykisk stress. Pasientsvar: "actual score".',
  },
  values: {},
};
