import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { BoxFields } from 'components/BoxFields';
import { FormInput } from 'components/Form';
import { Baseline } from 'interfaces';
import { shoulderMoveText } from 'helpers/ShoulderMove';
import { shoulderMoveRomText } from 'helpers/ShoulderMoveRom';
import { shoulderMovePainText } from 'helpers/ShoulderMovePain';
import { shoulderPassiveExtrotText } from 'helpers/ShoulderPassiveExtrot';
import { shoulderPainfularcText } from 'helpers/ShoulderPainfularc';
import { shoulderHawkinsText } from 'helpers/ShoulderHawkins';
import { shoulderIsoAbdPainText } from 'helpers/ShoulderIsoAbdPain';
import { shoulderIsoExtrotPainText } from 'helpers/ShoulderIsoExtrotPain';
import { shoulderIsoAbdPowText } from 'helpers/ShoulderIsoAbdPow';
import { shoulderIsoExtrotPowText } from 'helpers/ShoulderIsowxtrotPow';

interface ShoulderProps {
  values: Baseline;
}

export const Shoulder: React.FC<ShoulderProps> = ({ values }) => {
  const {
    ShoulderPassiveExtrot,
    ShoulderPainfularc,
    ShoulderHawkins,
    ShoulderIsoAbdPain,
    ShoulderIsoExtrotPain,
    ShoulderIsoExtrotPow,
    ShoulderIsoAbdPow,
    ShoulderMovePain_flex,
    ShoulderMovePain_ext,
    ShoulderMovePain_abd,
    ShoulderMovePain_add,
    ShoulderMovePain_extrot,
    ShoulderMovePain_introt,
    ShoulderMovePain_not,
    ShoulderMoveRom_flex,
    ShoulderMoveRom_ext,
    ShoulderMoveRom_abd,
    ShoulderMoveRom_add,
    ShoulderMoveRom_extrot,
    ShoulderMoveRom_introt,
    ShoulderMoveRom_not,
    ShoulderMove,
  } = values;

  // Conditional display fields
  const isShoulder = ShoulderMove === 'Y';

  return (
    <Card>
      <CardFormText text="Skulderundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/eller smertefulle aktive bevegelsesutslag"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <BoxFields
        values={values}
        variables={{ ShoulderMove }}
        keys={shoulderMoveText.values}
      />
      {isShoulder && (
        <>
          <div className="mb-5">
            <CardLabel
              text="Reduserte bevegelsesutslag i"
              size={CardLabelType.lg}
            />
          </div>
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                ShoulderMoveRom_flex,
                ShoulderMoveRom_ext,
                ShoulderMoveRom_abd,
                ShoulderMoveRom_add,
                ShoulderMoveRom_extrot,
                ShoulderMoveRom_introt,
                ShoulderMoveRom_not,
              }}
              keys={shoulderMoveRomText.values}
            />
          </div>

          <div className="mb-5">
            <CardLabel text="Smerter ved" size={CardLabelType.lg} />
          </div>
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                ShoulderMovePain_flex,
                ShoulderMovePain_ext,
                ShoulderMovePain_abd,
                ShoulderMovePain_add,
                ShoulderMovePain_extrot,
                ShoulderMovePain_introt,
                ShoulderMovePain_not,
              }}
              keys={shoulderMovePainText.values}
            />
          </div>
        </>
      )}
      <CardFormText text="Redusert passiv utadrotasjon">
        <div className="mt-2 mb-4">
          <CardLabel
            text="mer enn 50% nedsatt sammenlignet med motsatt side"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ ShoulderPassiveExtrot }}
          keys={shoulderPassiveExtrotText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smertebue">
          <div className="mt-2 mb-4">
            <CardLabel
              text="smerter i området ca 60-120 grader"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <BoxFields
          values={values}
          variables={{ ShoulderPainfularc }}
          keys={shoulderPainfularcText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved Hawkins test" />
        <BoxFields
          values={values}
          variables={{ ShoulderHawkins }}
          keys={shoulderHawkinsText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved isometrisk abduksjon" />
        <BoxFields
          values={values}
          variables={{ ShoulderIsoAbdPain }}
          keys={shoulderIsoAbdPainText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved isometrisk utadrotasjon" />
        <BoxFields
          values={values}
          variables={{ ShoulderIsoExtrotPain }}
          keys={shoulderIsoExtrotPainText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Nedsatt kraft ved isometrisk abduksjon" />
        <BoxFields
          values={values}
          variables={{ ShoulderIsoAbdPow }}
          keys={shoulderIsoAbdPowText.values}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Nedsatt kraft ved isometrisk utadrotasjon" />
        <BoxFields
          values={values}
          variables={{ ShoulderIsoExtrotPow }}
          keys={shoulderIsoExtrotPowText.values}
        />
      </div>
      <div className="mb-6">
        <CardLabel
          text="Klinisk Undersøkelse fritekst:"
          size={CardLabelType.lg}
        />
      </div>
      <div className="mb-6">
        <FormInput
          id="ShoulderComment"
          name="ShoulderComment"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
