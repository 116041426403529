import React, { useState } from 'react';

import { Card, CardLabel, CardLabelType } from 'components/Card';
import { Meter } from 'components/Charts/Meter';
import { PatientInfoResponse } from 'interfaces';
import { phenotypeText, radarPhenotypes } from 'helpers/phenotype';
import { Modal } from 'components/Modal';
import { Radar } from 'components/Charts';
import { activityText, getMappedValue } from 'helpers';
import { orebq1qText } from 'helpers/orebq1q';
import { qol15dSleepText } from 'helpers/qol15dSleep';

interface TreatmentPrognoseProps {
  className?: string;
  cardClassName?: string;
  data: PatientInfoResponse;
}

export const TreatmentPrognose: React.FC<TreatmentPrognoseProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { patientId } = data;
  const {
    Phenotype,
    OREBQ2_Q,
    OREBQ1,
    PainsiteNumber,
    OREBQ7_Q,
    PSEQScore,
    OREBQ10_Q,
    HSCLScore,
    Qol15dSleep,
    WorkAbility_work,
    Activity,
  } = data.patientDetails;
  const text = phenotypeText.values.find(
    (v) => v.score === Number(Phenotype),
  )?.text;

  const phenotypeColors = [
    {
      value: 1,
      color: '#4dba7a',
    },
    {
      value: 2,
      color: '#adc139',
    },
    {
      value: 3,
      color: '#ffc600',
    },
    {
      value: 4,
      color: '#ff8d26',
    },
    {
      value: 5,
      color: '#ff0000',
    },
  ];

  const currentColor =
    phenotypeColors.find((v) => v.value === Number(Phenotype))
      ?.color || '#000';

  const normalization = {
    OREBQ1: {
      1: 0,
      2: 0,
      3: 0,
      4: 20,
      5: 20,
      6: 20,
      7: 40,
      8: 60,
      9: 80,
      10: 100,
    },
    PSEQScore: {
      0: 0,
      1: 8.3,
      2: 16.7,
      3: 25.0,
      4: 33.3,
      5: 41.7,
      6: 50.0,
      7: 58.3,
      8: 66.7,
      9: 75.0,
      10: 83.3,
      11: 91.7,
      12: 100.0,
    },
    Qol15dSleep: {
      1: 0,
      2: 25,
      3: 50,
      4: 75,
      5: 100,
    },
    Activity: {
      1: 0.0,
      2: 33.3,
      3: 66.7,
      4: 100.0,
    },
  };

  const values = [
    {
      title: 'Smerteintensitet',
      value: OREBQ2_Q,
      normalizaiton: Number(OREBQ2_Q) * 10,
    },
    {
      title: 'Smertevarighet',
      value: OREBQ1,
      normalizaiton: Number(
        Object.entries(normalization.OREBQ1).find(
          ([key]) =>
            Number(key) ===
            getMappedValue(orebq1qText.values, OREBQ1).score,
        )?.[1],
      ),
    },
    {
      title: 'Antall smertesteder',
      value: PainsiteNumber,
      normalizaiton: Number(PainsiteNumber) * 10,
    },
    {
      title: 'Forventninger om bedring',
      value: OREBQ7_Q,
      normalizaiton: Number(OREBQ7_Q) * 10,
    },
    {
      title: 'Smertemestring',
      value: PSEQScore,
      normalizaiton: Number(
        Object.entries(normalization.PSEQScore).find(
          ([key]) => Number(key) === 12 - Number(PSEQScore),
        )?.[1],
      ),
    },
    {
      title: 'Bevegelsesfrykt',
      value: OREBQ10_Q,
      normalizaiton: Number(OREBQ10_Q) * 10,
    },
    {
      title: 'Psykisk belastning',
      value: HSCLScore,
      normalizaiton: Number(
        (((Number(HSCLScore) - 1) / 3) * 100).toFixed(2),
      ),
    },
    {
      title: 'Søvnvansker',
      value: Qol15dSleep,
      normalizaiton: Number(
        Object.entries(normalization.Qol15dSleep).find(
          ([key]) =>
            Number(key) ===
            getMappedValue(qol15dSleepText.values, Qol15dSleep).score,
        )?.[1],
      ),
    },
    {
      title: 'Arbeidsevne',
      value: WorkAbility_work,
      normalizaiton: (10 - Number(WorkAbility_work)) * 10,
    },
    {
      title: 'Generelt aktivitetsnivå',
      value: Activity,
      normalizaiton: Number(
        Object.entries(normalization.Activity).find(
          ([key]) =>
            Number(key) ===
            getMappedValue(activityText.values, Activity).score,
        )?.[1],
      ),
    },
  ];

  return (
    <div className={`${className ? className : ''}`}>
      <Card
        className={`${cardClassName ? cardClassName : ''} p-6`}
        noSpacing={true}
      >
        <div className="flex">
          <div className="flex-1 mr-8">
            <CardLabel
              text={`Fenotype ${
                Phenotype ? Number(Phenotype) : '"Missing"'
              }`}
              size={CardLabelType.xl}
            />
            <CardLabel
              text={text?.split('-')[1].trim() || ''}
              size={CardLabelType.sm}
            />
          </div>
          <div className="flex h-full items-center">
            <div>
              <div className="mb-4">
                <div
                  onClick={() => setIsOpen(true)}
                  className="cursor-pointer"
                >
                  <Meter value={Number(Phenotype)} />
                </div>
              </div>
              <CardLabel
                text="(trykk på figuren for mer info)"
                size={CardLabelType.sm}
              />
            </div>
          </div>
        </div>
      </Card>
      <Modal open={isOpen} onClose={(s) => setIsOpen(s)}>
        <Radar
          height={200}
          data={{
            labels: [
              'Smerteintensitet',
              'Smertevarighet',
              'Antall smertesteder',
              'Forventninger om bedring',
              'Smertemestring',
              'Bevegelsesfrykt',
              'Psykisk belastning',
              'Søvnvansker',
              'Arbeidsevne',
              'Generelt aktivitetsnivå',
            ],
            datasets: [
              {
                data: values.map((v) => v.normalizaiton),
                label: `Pasient: ${patientId}`,
                pointRadius: 4,
                // borderDash: [4, 2],
                backgroundColor: 'rgba(255, 99, 132, 0)',
                borderColor: currentColor,
                pointBackgroundColor: currentColor,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: currentColor,
              },
              {
                data: radarPhenotypes.find(
                  (p) => p.value === Number(Phenotype),
                )?.data,
                label: `Fenotype: ${Phenotype}`,
                pointRadius: 4,
                borderDash: [4, 2],
                backgroundColor: 'rgba(255, 99, 132, 0)',
                borderColor: 'rgba(160, 160, 160, 0.3)',
                pointBackgroundColor: 'rgba(160, 160, 160, 0.3)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(160, 160, 160, 0.3)',
              },
            ],
          }}
          options={{
            scale: {
              pointLabels: {
                fontSize: 16,
                fontColor: '#000',
                lineHeight: 1,
              },
              ticks: {
                suggestedMin: -5,
                suggestedMax: 100,
                stepSize: 10,
                fontSize: 14,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0.15)',
              },
            },
            legend: {
              position: 'top',
              labels: {
                boxWidth: 8,
                usePointStyle: true,
                fontSize: 16,
                fontColor: '#000',
              },
            },
            tooltips: {
              enabled: false,
            },
          }}
        />
        <div className="text-gray-500 text-sm text-center w-2/3 mx-auto">
          Figuren viser pasientens score på 10 ulike faktorer. En skår
          som ligger inn mot midten av figuren betyr en god skår og
          dårligere skår desto lengre ut.
        </div>
        {/* <div className="py-2">
          <div className="flex justify-between border-b mb-1 text-sm font-bold">
            <div>Label</div>
            <div>value/normalized</div>
          </div>
          {values.map((o) => {
            return (
              <div className="flex justify-between border-b mb-1 text-sm pb-1">
                <div>{o.title}:</div>
                <div>
                  {o.value}/{o.normalizaiton}
                </div>
              </div>
            );
          })}
        </div> */}
        {/* {phenotypeText.values.map((v, i) => (
          <div>{v.text}</div>
        ))} */}
      </Modal>
    </div>
  );
};
