import React from 'react';
import clsx from 'clsx';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { bodyMainText } from 'helpers/bodyMain';

interface DiagnoseProps {
  index: number;
  onChange: (index: number) => void;
}

export const Diagnose: React.FC<DiagnoseProps> = ({
  index,
  onChange,
}) => {
  return (
    <Card>
      <CardFormText text="Hovedproblem – kroppsområde">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Definer hovedproblem (dette utløser spesifikke tester for gitt område)"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      {bodyMainText.values.map((body, i) => {
        return (
          <div
            key={i}
            className="flex items-center mb-3"
            onClick={() => onChange(i)}
          >
            <div
              className={clsx(
                'w-8 h-8 border-2 mb-1 form-radio rounded-full hover:bg-indigo-500 hover:border-transparent cursor-pointer',
                {
                  'bg-indigo-500 border-indigo-500': index === i,
                  'border-gray-500': index !== i,
                },
              )}
            />
            <div className="ml-2 text-sm font-medium">
              {body.text}
            </div>
          </div>
        );
      })}
    </Card>
  );
};
