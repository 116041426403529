export const treat12Text = {
  values: [
    {
      value: 'Treat12m_Treat12mGp',
      text: 'Fastlege',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mSpecialist',
      text: 'Sykehus/Spesialisthelsetjenesten',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mChiro',
      text: 'Kiropraktor',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mMt',
      text: 'Manuellterapeut',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mPsychologist',
      text: 'Psykolog',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mPhysio',
      text: 'Fysioterapeut',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mPsychoPt',
      text: 'Psykomotorisk fysioterapeut',
      score: 0,
    },
    {
      value: 'Treat12m_Treat12mAlternative',
      text: 'Alternative behandlere',
      score: 0,
    },
    { value: 'Treat12m_Treat12mOther', text: 'Andre', score: 0 },
  ],
};
